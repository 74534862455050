<template>
  <svg
    v-if="isVisible"
    :style="{
      position: 'fixed',
      width: `${svgWidth}px`,
      height: `${submenuHeight}px`,
      pointerEvents: 'none',
      zIndex: 9999,
      top: `${submenuY}px`,
      left: `${mousePosition.x - 2}px`,
    }"
    id="svg-safe-area"
  >
    <path
      pointer-events="none"
      fill="rgba(187,39,38,0)"
      :d="`M 0,0 L ${svgWidth},0 L ${svgWidth},${submenuHeight} L 0,${submenuHeight} z`"
    />
    <path
      pointer-events="auto"
      stroke="none"
      stroke-width="0.4"
      fill="rgba(114,140,89,0)"
      :d="`M 0, ${mousePosition.y - submenuY}
        L ${svgWidth},${submenuHeight}
        L ${svgWidth},0 z`"
    />
  </svg>
</template>

<script setup>
import { ref, watchEffect, onMounted, onUnmounted } from "vue";
import useMousePosition from "@/types/useMousePosition";

const props = defineProps({
  anchor: { type: Object, required: true },
  submenu: { type: Object, required: true },
});

const mousePosition = useMousePosition();

const isVisible = ref(true);

const submenuHeight = ref(0);
const submenuY = ref(0);
const svgWidth = ref(0);

watchEffect(() => {
  if (props.submenu) {
    const {
      height: submenuHeightVal,
      x: submenuXVal,
      y: submenuYVal,
    } = props.submenu.getBoundingClientRect();

    submenuHeight.value = submenuHeightVal;
    submenuY.value = submenuYVal;
    svgWidth.value = submenuXVal - mousePosition.value.x + 4;
  }
});

onMounted(() => {
  isVisible.value = true;
});

onUnmounted(() => {
  isVisible.value = false;
});
</script>
