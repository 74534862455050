import { TranslationSchema } from "./en";

const translations: TranslationSchema = {
  app_features: {
    planner: "Terminplan",
    processes: "Prozessdaten",
    site_activity: "Bauaktivität",
    project_overview: "Projektübersicht",
    project_participants: "Projektteilnehmer",
    live: "Live",
    period: "Zeitraum",
    gallery: "Bildarchiv",
    project_timelapse: "Gesamtzeitraffer",
    daily_timelapse: "Tageszeitraffer",
    customizable_timelapse: "Individuelle Zeitraffer",
    daily_report: "Bautagesbericht",
    compare: "Vorher / Nachher",
    timelapse: "Zeitraffer",
    settings: {
      user: "Nutzerübersicht",
      language: "Sprache",
    },
    languages: {
      de: "Deutsch",
      en: "Englisch",
    },
    project_settings: "Einstellungen",
    new_feature_badge: "Neu",
  },
  permissions: {
    project_admin: "Projekt Admin",
    all_camera_features_user: "Kamerafeatures",
    planner_user: "Terminplan",
    processes_user: "Prozessdaten",
    site_activity_user: "Bauaktivität",
    daily_report_user: "Bautagesbericht",
    live_camera_feature_user: "Nur Live-Bild",
  },
  project: {
    status: {
      active: "Aktiv",
      completed: "Abgeschlossen",
    },
    add: {
      title: "Projekt hinzufügen / Anfrage",
      subtitle:
        "Mit der Anfrage werden die Projektinformationen an das oculai-Team weitergeleitet, und wir nehmen Kontakt mit dir auf!",
      name: "Name",
      country_code: "Ländercode",
      street: "Straße",
      zip_code: "PLZ",
      estimated_duration: "Dauer in Monaten",
      estimated_cameras: "Anzahl Kameras",
      estimated_shell_volume: "Rohbauvolumen",
      estimated_start: "Voraussichtlicher Projektstart",
      project_goals: "Projektziele",
      goals: {
        cameras: "Livestream & Zeitraffer",
        shorten_construction_time: "Bauzeit verkürzen",
        target_actual_schedule: "Soll-Ist Terminplan",
        unit_values: "Aufwandswerte",
        claims: "Nachträge",
        site_management_assistant: "BL-Assistent",
      },
      layout_plans: "Baustelleneinrichtungsplan",
      optional: "optional",
      add_project: "Projekt anfragen",
      success_message: "Die Anfrage war erfolgreich, das oculai-Team meldet sich in Kürze!",
      validation: {
        at_least_one_enabled: "Es muss mindestens ein Projektziel ausgewählt werden",
        file_size_exceeded: "Die Dateigröße darf insgesamt 50 MB nicht überschreiten.",
      },
      file_input: {
        upload_plan: "Plan hochladen",
        drag_and_drop: "Drag & Drop Plan als PDF",
        wrong_file_format: "Falsches Dateiformat. Nur PDFs akzeptiert.",
      },
    },
  },
  partials: {
    sidebar: {
      projects: "Projekte",
      show_all_projects: "Alle Projekte anzeigen",
      log_out: "Abmelden",
      search_project_admin: "Suche nach Projekt, customer name oder site id...",
      search_project_user: "Suche nach Projekt...",
      search_not_found: "Keine Projekte gefunden",
      go_to_project: "Gehe zu Projekt",
      camera: "Kameras",
    },
    breadcrumb: {
      projects: "Projekte",
    },
  },
  dashboard: {
    home: {
      project_card: {
        schedule_progress: "Terminlicher Fortschritt",
        actual_progress: "Ist",
        planned_progress: "Soll",
        planner_delta: "Terminplan-delta",
      },
      all_projects: "Alle Projekte",
      add_project: "Projekt anfragen",
    },
    project: {
      planner_card: {
        current_procedures: "Aktuelle Vorgänge",
        procedure: "Vorgang",
        start_date: "Start Datum",
        progress: "Fortschritt",
        estimated_end: "Voraus. Abschluss",
        no_current_procedures: "Keine aktuellen Vorgänge",
      },
      weather_card: {
        header: "Wetter",
        current_weather: "Aktuelles Wetter",
        kmh: " km/h",
        no_data: "Keine Wetterdaten verfügbar",
      },
      stream_card: {
        detail_view: "Detailansicht",
        connection_lost: "Leider ist die Verbindung abgebrochen. Letztes Signal: {updatedAt}.",
      },
    },
    process_widget: {
      header: "Letzte Prozesse",
      no_processes: "Keine Prozesse in den letzten 7 Tagen",
      all_caught_up: "Du hast alle Prozesse der letzten 7 Tage gesehen!",
      to_overview_link: "Zur Prozessübersicht",
      next_button: "Nächster",
    },
    add_widget: "Widget hinzufügen",
    remove_widget: "Widget entfernen",
    no_widgets_to_add: "Keine Widgets verfügbar",
    loading_error: "Dashboard kann nicht geladen werden",
    reset_error: "Dashboard kann nicht zurückgesetzt werden",
    create_or_update_error: "Dashboard kann nicht aktualisiert werden",
    categories: {
      camera: "Kameras",
      report: "Berichte",
      other: "Sonstige",
    },
    reset_confirmation: {
      header: "Bestätigung",
      message: "Du verlierst deine Anpassungen, wenn du das Layout zurücksetzt.",
      confirm: "Dashboard zurücksetzen",
      cancel: "Abbrechen",
    },
    delete_confirmation: {
      header: "Bestätigung",
      message: 'Dashboard "{name}" wird gelöscht.',
      confirm: "Dashboard löschen",
      cancel: "Abbrechen",
    },
    default_dashboard: "Dashboard",
    menu_categories: {
      user: "Für Dich",
      project: "Für Alle",
    },
    create_dashboard_modal: {
      title: "Dein neues Dashboard",
      name: "Name",
      submit: "Erstellen",
    },
    add_to_dashboard: "Zu Dashboard hinzufügen",
    add_button: "Hinzufügen",
    stream: {
      archived_stream: "abgeschlossen",
    },
    drag_me: "Ziehen zum Verschieben",
    reset_dashboard: "Dashboard zurücksetzen",
  },
  authentication: {
    validation: {
      validation_field_required: "Feld erforderlich",
      validation_email_invalid: "Ungültige E-Mail Adresse",
      validation_password_not_equal: "Das neu vergebene Passwort stimmt nicht überein.",
      validation_password_characters: "Das neue Passwort muss mindestens 10 Zeichen enthalten.",
    },
    login: {
      email: "E-Mail Adresse",
      password: "Passwort",
      wrong_email_or_password: "Fehler! E-Mail Adresse oder Passwort ist falsch.",
      sign_in: "Sign In",
      forgot_password: "Passwort vergessen?",
      imprint: "Impressum",
      data_protection: "Datenschutz",
      or: "oder",
      social_error: "Keine Anmeldung möglich. Du musst eingeladen werden um oculai zu nutzen.",
      sign_in_with_microsoft: "Sign in mit Microsoft",
    },
    forgot_password: {
      forgot_password: "Passwort vergessen?",
      forgot_text: " Keine Sorge, wir senden dir Anweisungen zum Zurücksetzen deines Passworts zu.",
      email: "E-Mail Adresse",
      reset_password: "Passwort zurücksetzen",
      back_to_login: "Zurück zum Login",
      user_not_found: "Fehler! Nutzer wurde nicht gefunden",
      success_message: "Bitte überprüfe dein Mail Postfach",
    },
    reset_password: {
      new_password: "Neues Passwort",
      change_password: "Passwort ändern",
      back_to_login: "Zurück zum Login",
      error_invalid_code: "Fehler! Invalider Verifizierungscode.",
      error_password_length: "Fehler! Das neue Passwort muss mindestens 10 Zeichen enthalten.",
      error_user_not_existing: "Fehler! Der Nutzer existiert nicht.",
      error_limit_exceeded:
        "Das Limit an Versuchen wurde überschritten. Versuche es später erneut.",
      error_unknown: "Unbekannter Fehler! Bitte wende dich an das oculai-Team.",
    },
    sign_up: {
      email: "E-Mail Adresse",
      name: "Name",
      first_name: "Vorname",
      last_name: "Nachname",
      company: "Unternehmen",
      your_role: "Deine Rolle",
      role: "Rolle",
      temporary_password: "Temporäres Passwort (siehe Mail)",
      new_password: "Neues Passwort",
      repeat_new_password: "Wiederhole neues Passwort",
      sign_up: "Sign up",
      already_registered: "Du hast bereits ein oculai-Konto? Einloggen",
      error_mail_password: "Fehler! E-Mail Adresse oder temporäres Passwort ist falsch.",
      error_must_be_mail: "Fehler! Benutzername muss eine Mail-Adresse sein.",
      error_user_not_confirmed: "Fehler! Der Nutzer wurde noch nicht bestätigt.",
      error_user_not_existing: "Fehler! Der Nutzer existiert nicht.",
      error_unknown: "Unbekannter Fehler! Bitte wende dich an das oculai-Team.",
      error_modal_exists_text: "Der Benutzer existiert bereits",
      error_modal_login: "Bitte logge dich über die",
      error_modal_login_page: "Login-Seite",
      error_modal_in: "ein.",
      terms_conditions_1: "Mit der Erstellung eines oculai Accounts, stimmst du den",
      terms_conditions_2: "AGB",
      terms_conditions_3: "von oculai zu.",
      sign_up_with_microsoft: "Sign up mit Microsoft",
      social_error: "Keine Registrierung möglich. Du musst eingeladen werden um oculai zu nutzen.",
    },
    session_expired: {
      message: "Deine Sitzung ist abgelaufen. Bitte aktualisiere die Seite.",
      refresh_page: "Seite aktualisieren",
    },
  },
  admin: {
    settings: {
      user: {
        title: "Nutzerübersicht",
        sub_title: "Hier findest du Informationen zu deinem oculai-Account.",
        name: "Name",
        company: "Unternehmen",
        role: "Rolle",
      },
      language: {
        title: "Spracheinstellungen",
        language_choice: "Sprachauswahl",
      },
    },
    project_participants: {
      participants: "Projektteilnehmer",
      add_participants: "Nutzer zu Projekt hinzufügen",
      table_user: "Teilnehmer",
      table_project: "Projekt",
      table_permissions: "Berechtigungen",
      company_type: "Unternehmen",
      email: "E-Mail",
      pending_invite: "Ausstehende Einladung",
      company_types: {
        general_contractor: "Generalunternehmer",
        subcontractor: "Nachunternehmer",
        owner: "Bauherr/Bauträger",
        architect: "Architekt/Fachplaner",
        other: "Sonstige",
      },
      user_roles: {
        project_management: "Projektleitung",
        site_management: "Bauleitung",
        foreman: "Polier",
        management_board: "Vorstand / Geschäftsführung",
        division_management: "Bereichsleitung / Abteilungsleitung",
        bim_management: "BIM Management",
        lean_management: "Lean Management",
        calculation: "Kalkulation",
        controlling: "Controlling",
        procurement: "Einkauf",
        scheduling: "Terminplanung",
        business_development: "Geschäftsentwicklung",
        assistance: "Assistenz",
        other: "Sonstige",
      },
      modal_invite_user: {
        invite_users: "Nutzer einladen",
        add_user: "Nutzer hinzufügen",
        invite: "Einladen",
        error_mail_required: "E-Mail Adresse erforderlich",
        error_mail_invalid: "Ungültige E-Mail Adresse",
        error_company_type_required: "Unternehmen erforderlich",
      },
      modal_delete_user: {
        delete_from_project: "Nutzer aus Projekt entfernen",
        delete_permanently: "Nutzer Account permanent löschen",
      },
      modal_permission: {
        update: "Aktualisieren",
        cancel: "Abbruch",
      },
      error: {
        permission_update: "Fehler, Berechtigungen konnten nicht aktualisiert werden.",
        properties_update: "Fehler, Nutzereigenschaften konnten nicht aktualisert werden.",
        invite: "Fehler, Nutzer konnten nicht eingeladen werden.",
        delete: "Fehler, Nutzer konnte nicht gelöscht werden.",
      },
    },
    project_settings: {
      tab_general: "Allgemein",
      tab_project_participants: "Projektteilnehmer",
      tab_durations: "Arbeitszeiten und Feiertage",
      tab_dcr: "Bautagesbericht",
    },
    general_project_settings: {
      project_status: {
        active: "aktiv",
        completed: "abgeschlossen",
      },
      validation_name_required: "Name ist erforderlich",
      validation_street_required: "Adresse ist erforderlich",
      validation_max_characters: "maximal {maxCharacterCount} Zeichen",
      field_name_label: "Projektname",
      field_country_code_label: "Länder Code",
      field_zip_code_label: "PLZ",
      field_timezone_label: "Zeitzone",
      field_street_label: "Adresse",
      field_planned_start_label: "Start Datum",
      field_planned_end_label: "End Datum",
      field_status_label: "Projekt Status",
      save_error_message: "Aktualisierungen können nicht gespeichert werden",
      logo: "Logo",
      no_logo_available: "Kein Logo vorhanden",
    },
    durations_project_settings: {
      working_hours_header: "Arbeitszeiten",
      holidays_header: "Arbeitsfreie Tage (Feiertage, Betriebsurlaub und Störungen)",
      holidays_editor_header: "Nichtarbeitstag hinzufügen",
      start: "Start",
      end: "Ende",
      breaks: "Pausen (Std.)",
      hours: "Std.",
      add: "Hinzufügen",
      save_error_message: "Aktualisierungen können nicht gespeichert werden",
      validation_start_more_than_end: "Start soll vor Ende liegen",
      validation_start_or_end_empty: "Start/Ende ist erforderlich",
      validation_working_hours_breaks:
        "Pause muss größer als 0 und kleiner als die verfügbaren Stunden sein",
      validation_name_empty: "Name ist erforderlich",
      date_range: "Zeitraum auswählen",
      holidays_field_name: "Name",
      holidays_field_start: "Start",
      holidays_field_date: "Datum",
      holidays_field_type: "Typ",
      holidays_field_end: "Ende",
      holidays_field_duration: "Dauer",
      holidays_field_disturbance_type: "Störungstyp",
      holidays_field_disturbance_description: "Störungsbeschreibung",
      holidays_field_created_by: "Erstellt von",
      no_holidays_message: "Keine Feiertage",
      add_public_holidays_button: "Feiertage importieren",
      public_holidays_for: "Gesetzliche Feiertage für",
      no_public_holiday_message: "Keine gesetzlichen Feiertage für",
      validation_start_required: "Start ist erforderlich",
      validation_end_required: "Ende ist erforderlich",
      validation_date_required: "Datum ist erforderlich",
      validation_disturbance_type_empty: "Störungstyp ist erforderlich",
      working_day_label: "Tag | Tage",
      total_working_days_label: "Arbeitstage insgesamt: {total}",
      total_working_hours_label: "Arbeitsstunden insgesamt: {total}",
      working_days_calculation_tooltip_header:
        "Arbeitstage werden auf Basis der folgenden Regel berechnet:",
      working_days_calculation_tooltip_item1: "keine Arbeitszeit konfiguriert: 0 Tage",
      working_days_calculation_tooltip_item2: "0h < Arbeitsdauer < 8h: 0,5 Tag",
      working_days_calculation_tooltip_item3: "8h <= Arbeitsdauer: 1 Tag",
      non_working_day_type_labels: {
        public_holiday: "Feiertag",
        company_holiday: "Betriebsurlaub",
        disturbance: "Störung",
      },
    },
  },
  camera: {
    components: {
      weather_container: {
        title: "Wetter",
        kmh: " km/h",
        view_more: "Mehr ansehen",
        view_less: "Weniger ansehen",
        clock: " Uhr",
      },
    },
    period: {
      info_text: "Fordere originales, nicht komprimierte Videomaterial aus den letzten 7 Tage an.",
      playback_period: "Wiedergabe Zeitraum",
      date: "Datum",
      time: "Uhrzeit",
      duration: "Dauer",
      in_minutes: "In Minuten",
      play: "Wiedergabe",
    },
    gallery: {
      pick_a_date: "Wähle ein Datum",
      date: "Datum",
      search: "Suche",
      error_timeframe: "Es gibt keine Daten für diesen Zeitraum",
      download: {
        menu_title: "Bilder herunterladen",
        title: "Galeriebilder herunterladen",
        date_picker_placeholder: "Alle Tage",
        files: "Datei | Dateien",
        start_download: "Download starten",
        stop_download: "Download stoppen",
        resume_download: "Download fortsetzen",
        ongoing_download: "Laufender Download. Bitte stoppe den Download vor dem Schließen!",
        success: "Zip-Archiv erfolgreich heruntergeladen!",
        partial_download: "Zip-Archiv teilweise heruntergeladen.",
        base_file_name: "Galerie",
        imgs_per_day: "Bild pro Tag | Bilder pro Tag",
        all_imgs: "Alle Bilder",
      },
    },
    daily_timelapse: {
      pick_a_date: "Wähle ein Datum",
      date: "Datum",
      search: "Suche",
      error_timeframe: "Es gibt keine Daten für diesen Zeitraum",
    },
    compare: {
      pick_a_date: "Pick date and time",
      date: "Date",
      time: "Time",
      before: "Before",
      after: "After",
      button: "Search",
      err_no_image:
        "Es es kein Bild für die angefragte Kombination aus Datum und Uhrzeit verfügbar. Wir zeigen das nächstgelegene Bild, das wir gefunden haben",
      err_future_date: "Bitte wählen einen Tag vor dem heutigen Tag!",
      err_no_image_range_1: "Wir konnten kein Bild finden für die Spanne von",
      err_no_image_range_2: "Bitte geben einen anderen Wert ein!",
      err_after_date: "Das Nachher-Datum sollte nach dem Vorher-Datum liegen.",
      err_after_time: "Die Nachher-Zeit sollte nach der Vorher-Zeit liegen an gleichem Tag.",
      tutorial:
        "Hier können Bilder von zwei Zeitpunkten vergleichen werden. Verwende den Slider im Bild, um den Fortschritt der Baustelle zu sehen!",
      no_image: "Kein Bild für Datum.",
      sections: "Bauabschnitte",
      construction_sections: "Bauabschnitte",
      level: "Geschoss",
    },
    customizable_timelapse: {
      create_button: "Erstelle Zeitraffer",
      table: {
        name: "Name",
        camera: "Kamera",
        start_date: "Start",
        end_date: "Ende",
        duration: "Dauer",
        duration_min: " Min",
        created: "Erstelldatum",
        play: "Wiedergabe",
        play_button: "Play",
        extended: "Erweitert",
        extended_remove: "Entferne Zeitraffer",
        extended_download: "Download",
      },
      create_modal: {
        title: "Individueller Zeitraffer",
        timelapse_name: "Zeitraffer Name",
        camera: "Kamera",
        start: "Start",
        end: "Ende",
        duration: "Zeitraffer-Dauer",
        option_1: "1 Minute",
        option_2: "2 Minuten",
        option_3: "3 Minuten",
        option_4: "4 Minuten",
        option_5: "5 Minuten",
        create: "Erstelle Zeitraffer",
        cancel: "Abbruch",
      },
      success_notification: {
        message:
          "Zeitraffer erfolgreich erstellt! Die Erstellung des Videos kann bis zu 60 Minuten dauern.",
      },
      errors: {
        create_error: "Fehler bei der Erstellung des Videos. Bitte überprüfe deine Eingabe.",
        unique_name:
          "Bitte wähle einen einmaligen Zeitraffer-Namen, welcher bisher noch nicht von Dir vergeben wurde.",
        start_date_after_today: "Startdatum liegt nach dem heutigen Datum.",
        end_date_after_today: "Enddatum liegt nach dem heutigen Datum.",
        end_date_before_start_date: "Enddatum liegt vor dem Startdatum.",
        missing_name: "Fehlender Zeitraffer-Name.",
        missing_start_date: "Bitte wähle ein Start-Datum.",
        missing_end_date: "Bitte wähle ein End-Datum.",
      },
    },
    dashboard: {
      rename: "Kamera umbenennen",
    },
    update_error: "Kameraeinstellung kann nicht aktualisiert werden",
  },
  analytics: {
    processes: {
      last_updated: "Zuletzt aktualisiert:",
      processes: "Prozesse",
      no_data: "Es sind noch keine Prozessdaten vorhanden. Das Tracking startet in Kürze!",
      display_type_label: "Art der Anzeige",
      sort_by: "Sortieren nach",
      process_group: "Leistung",
      building: "Gebäude",
      section: "Abschnitt",
      level: "Geschoss",
      component: "Komponente",
      tags: "Tags",
      placeholder_hierarchy_tag: "Filter Komponenten",
      placeholder_process_type: "Wähle Prozesstypen",
      clear_filters: "Filter zurücksetzen",
      component_view: "Anzeige nach Bauteilen",
      section_view: "Anzeige nach Bauabschnitten",
      level_view: "Anzeige nach Geschoss",
      type: "Typ",
      location: "Ort",
      start: "Erste Tätigkeit",
      end: "Letzte Tätigkeit",
      process: "Prozess",
      date: "Datum",
      duration: "Dauer",
      item: "Vorgang | Vorgänge",
      brutto_day_duration: "Gesamte Dauer",
      brutto_hour_duration: "Gesamte Stunden",
      net_day_duration: "Aktive Dauer",
      net_hour_duration: "Aktive Stunden",
      standstill_days: "Stillstandstage",
      utilization: "Auslastungsgrad",
      processes_view: "Anzeige nach Prozessen",
      new_version: "Neue Version",
      old_version: "Alte Version",
      amount: "Anzahl",
      summary: "Zusammenfassung",
      aggregation_view: "Nach Leistungen",
      plain_view: "Nach Einzelprozessen",
      process_data_gantt: "Prozessdaten Gantt",
      process_data_table: "Aktivitätslog",
      unit_values: "Aufwandswerte",
      brutto_day_duration_tooltip:
        "Gesamtdauer in Arbeitstagen zwischen dem ersten und dem letzten in dieser Kategorie erfassten Prozess",
      net_day_duration_tooltip:
        "Anzahl an Tagen, an denen Prozesse in dieser Kategorie erfasst wurden",
      net_hour_duration_tooltip:
        "Summe der Dauern der einzelnen Prozesse in dieser Kategorie ohne zeitliche Überschneidungen",
      standstill_days_tooltip:
        "Anzahl der Tage zwischen der ersten und der letzten erfassten Tätigkeit, an denen keine Arbeit in dieser Kategorie erfasst wurde",
      utilization_tooltip: "Aktive Stunden / Gesamte Stunden",
      total_duration: "Gesamtdauer",
      net_duration: "Nettodauer",
      workers: "Arbeiter",
      working_hours: "Arbeitsstunden",
      time_range: "Zeitraum",
      show_activity_calendar: "Öffne Aktivitätskalender",
      sidebar: {
        analysis: "Analyse",
        before_after: "Vorher & Nachher",
        total: "Gesamt",
        processes_selected: "Prozess ausgewählt | Prozesse ausgewählt",
        active_total_days: "Aktive/Gesamte Tage",
        productive_days: "Produktive Zeit",
        outage_days: "Ausfalltage",
        working_hours: "Arbeitsstunden",
        select_row: "Zeile auswählen",
        open_in_activity_log: "Prozesse in Aktivitätslog öffnen",
      },
      shortcuts: {
        title: "Shortcuts",
        draw: "Markieren",
        draw_area: "Bereich markieren, um mehrere Prozesse auszuwählen",
        select_multiple: "Mehrere Prozesse auswählen",
        event: "Prozess",
        open_context_menu: "Kontextmenü öffnen",
        resource: "Zeile",
        right_click: "Rechtsklick",
        click: "Klicken",
      },
      unable_to_load: "Prozesse können nicht geladen werden",
    },
    reports: {
      my_reports: "Meine Berichte",
      reports: "Berichte",
      name: "Name",
      processes_components: "Prozesse / Komponente",
      building: "Gebäude",
      section: "Abschnitt",
      level: "Geschoss",
      by_building: "Nach Gebäude",
      by_section: "Nach Abschnitt",
      by_level: "Nach Geschoss",
      filters: "Filter",
      type: "Typ",
      is: "ist",
      custom: "Benutzerdefiniert",
      plot_config: "Plot Konfiguration",
      trade: "Gewerk",
      component: "Komponente",
      select_values: "Werte auswählen",
      utilization: "Auslastung",
      working_hours: "Arbeitsstunden",
      mean: "Mittelwert: ",
      planned: "Geplante Tage",
      total: "Gesamt",
      remove_report: "Bericht löschen",
      remove_filter: "Filter entfernen",
      select_filters: "Filter auswählen",
      no_available_filters_left: "Keine verfügbaren Filter mehr",
      all: "Alle",
      cycle_times_plot: "Durchlaufzeiten",
      working_hour_curve_plot: "Arbeitstundenkurve",
      unit_values_plot: "Aufwandswerte",
      unit_values: "Aufwandswerte",
      unit_value: "Aufwandswert",
      unit_values_missing: "Aufwandswerte fehlen",
      absolute_date: "Absolutes Datum",
      show_mean_working_hours: "Mittelwert Arbeitsstunden",
      in_progress: "In Arbeit",
      show_utilization: "Auslastung",
      show_planned: "Geplante Tage",
      no_reports: "Es sind noch keine Berichte vorhanden.",
      preset_mode: "Komponenten",
      process_mode: "Einzelprozesse",
      only: "Nur",
      location: "Standort",
      milestone: "Projektfortschritt",
      milestone_plot: "Projektfortschritt",
      target_period: "Soll-Zeitraum: ",
      actual_period: "Ist-Zeitraum: ",
      present: "Jetzt",
      sort_modes: "Gruppierung",
      actual_not_started: "Noch nicht begonnen",
      reset_zoom: "Zoom zurücksetzen",
      end_date_mode: "Ganzes Projekt",
      sequence_mode: "Nach dem kritischen Pfad",
      create_first_report: "Erstelle den ersten Bericht",
      planned_title: "Geplante Arbeitstage werden aus dem Terminplan entnommen.",
      planned_description:
        "Geplante Arbeitstage berechnen sich auf Basis der in den Projekteinstellungen konfigurierten Arbeitszeiten und exkludieren Feiertage und Betriebsurlaube.",
      active_title:
        "Aktive Arbeitstage sind Tage an welchen mindestens eine Aktivität stattgefunden hat",
      active_description:
        "Aktive Arbeitstage berechnen sich auf Basis der in den Projekteinstellungen konfigurierten Arbeitszeiten und exkludieren Feiertage und Betriebsurlaube und Störungen.",
      inactive_title: "Stillstandstage sind Tage an welchen keine Aktivität stattgefunden hat",
      inactive_description:
        "Stillstandstage berechnen sich auf Basis der in den Projekteinstellungen konfigurierten Arbeitszeiten und exkludieren Feiertage und Betriebsurlaube und Störungen.",
      utilization_title: "Auslastung basiert auf dem Verhältnis von Arbeitsstunden zur Kapazität.",
      utilization_description:
        "Die Kapazität wird auf Basis der maximalen Anzahl von Arbeitern multipliziert mit den in den Projekteinstellungen konfigurierten Arbeitszeiten berechnet.",
      working_hours_title: "Arbeitsstunden",
      working_hours_description:
        "Arbeitsstunden berechnen sich aus den aufsummierten Prozessdauern multipliziert mit der Anzahl, der am Prozess beteiligten Personen.",
      outages_title: "Kameraausfälle.",
      outages_description:
        "Als Tage mit Kameraausfällen gelten Arbeitstage an welchen die für das Bauteil relevanten Kameras für mehr als 50% der Zeit nicht in Betrieb waren.",
      unit_values_title: "Aufwandswert",
      unit_values_description:
        "Der Aufwandswert berechnet sich aus Arbeitsstunden / Menge und gibt den benötigten zeitlichen Aufwand pro Mengeneinheit an.",
      dont_save_report: "Bericht nicht speichern",
      update_report_error: "Bericht kann nicht gespeichert werden",
      delete_report_error: "Bericht kann nicht gelöscht werden",
      delete_confirmation: {
        header: "Bestätigung",
        message: 'Bericht "{name}" wird gelöscht.',
        confirm: "Bericht löschen",
        cancel: "Abbrechen",
      },
      edit_report: "Bericht bearbeiten",
      dashboard_new_report: "neu",
      query_value_plot: "Einzelwert",
      show_previous_period_value: "Vergleich mit Vorperiode",
      previous_period_label: "Vorperiode",
      metric: "Metrik",
      query_value_metrics: {
        working_hours: "Arbeitsstunden",
        unit_value: "Aufwandswert",
        utilization: "Auslastung",
        productive_days: "Produktive Zeit",
        active_days: "Aktive Tage",
        inactive_days: "Stillstandstage",
        delta_planner: "Terminplan-Delta",
        project_progress: "Projektfortschritt",
      },
      query_value_aggregations: {
        sum: "Summe",
        average: "Durchschnitt",
        median: "Median",
        latest: "Letzter Wert",
      },
      query_value_units: {
        hours: "h",
        working_days: "AT",
        working_days_full: "Arbeitstage",
      },
      query_value_formatting_rules: {
        header: "Visuelle Formatierungsregeln",
        if_value_is: "Wenn der Wert",
        show_with: "anzeigen mit",
        actions: {
          green_background: "Grüner Hintergrund",
          red_background: "Roter Hintergrund",
          yellow_background: "Gelber Hintergrund",
          green_foreground: "Grün",
          red_foreground: "Rot",
          yellow_foreground: "Gelb",
        },
      },
      query_value_progress: {
        weight: "Gewicht",
        progress_for_date: "Fortschritt an Datum",
        not_finished: "nicht abgeschlossen",
        finished_working_hours_parent_tooltip: "Summe der untergeordneten Bauteile",
        weight_tooltip:
          "Gibt den prozentualen Einfluss (Gewicht) des Bauteils auf den Gesamtfortschritt an, basierend auf den geplanten Dauern im Terminplan.",
        status: {
          completed: "Abgeschlossen",
          not_started: "Nicht gestartet",
          active: "Aktiv",
        },
        planner_item: "Vorgang",
        project: "Projekt",
        planned_working_days: "geplante Arbeitstage",
        worked_out_of: "Gearbeitet an {actual} von {total} geplanten Arbeitstagen",
        aggregated_progress: "Aggregierter Fortschritt",
        fixe_value: "pauschal",
      },
      aggregation: "Aggregation",
      period: "Zeitraum",
      previous_period: "Vorheriger Zeitraum",
      unable_to_load: "Berichte können nicht geladen werden",
      show_delta: "Prognose anzeigen",
      incomplete_path_no_delta: "unvollständiger Pfad - keine Deltainformation",
      by_daily: "Täglich",
      by_weekly: "Wöchentlich (KW)",
      by_biweekly: "Zweiwöchentlich (KW)",
      by_monthly: "Monatlich",
      plot_modes: "Modus",
      plot_type: "Typ",
      default_mode: "Standard",
      cumulative_mode: "Kumulativ",
      since_last_day: "Seit dem letzten Tag",
      since_last_week: "Seit der letzten Woche",
      since_last_biweek: "Seit den letzten zwei Wochen",
      since_last_month: "Seit dem letzten Monat",
      no_mapping: "Keine Zuordnung",
      progress_curve: "Fortschrittskurve (%)",
      milestone_variant: "Meilensteine",
      plot_descriptions: {
        cycle_times: "Messe geplante und tatsächliche Dauern von Komponenten",
        working_hour_curve: "Analysiere geleistete Arbeitsstunden verschiedener Gewerke",
        unit_values: "Verstehe die Entwicklung des Aufwandswerts",
        milestone: "Visualisiere den Soll-Ist Projektfortschritt über Zeit",
        query_value: "Behalte einzelne Projektkennzahlen tagesaktuell im Blick",
      },
    },
    planner: {
      label_upload_planner: "Terminplan hochladen",
      plan_upload_desc: "Drag & Drop MS Project XML-Datei.",
      title: "Terminplan",
      last_updated: "Zuletzt aktualisiert:",
      zoom: "Zoom",
      target_period: "Soll-Zeitraum: ",
      actual_period: "Ist-Zeitraum: ",
      chart_progress: "Fortschritt: ",
      chart_target_period: "Soll-Zeitraum: ",
      chart_actual_period: "Ist-Zeitraum: ",
      chart_sub_processes: "Unterprozesse: ",
      chart_not_started: "Nicht gestartet",
      chart_no_end_date: "kein Enddatum",
      no_planner_import_possible:
        "Das Tracking hat bereits begonnen. Kontaktiere das oculai Team, um eine neue Version des Plans hochzuladen.",
      wrong_file_format:
        "Dateiformat nicht unterstützt. Bitte stelle sicher, dass eine MS Project XML-Datei hochgeladen wurde.",
      all: "Alle",
      present: "Jetzt",
      visible: "Sichtbar",
      completeview: "Alle",
      currentview: "Sichtbar",
      events: "Zeitachse",
      items: "Vorgänge",
      critical_path: "Kritischer Pfad",
      show_complete_planner: "Zeige kompletten Terminplan",
      not_started_event: "Noch nicht begonnen",
      actual_not_started: "Noch nicht begonnen",
      milestone: "Projektfortschritt",
      modes: {
        visitor: "Besucher",
        revision: "Bearbeitung",
      },
      planned_event_name: "soll",
      actual_event_name: "ist",
      start_date: "Startdatum",
      start_time: "Startzeit",
      end_date: "Enddatum",
      end_time: "Endzeit",
      event_validation: {
        start_date_required: "Startdatum ist erforderlich",
        end_date_required: "Enddatum ist erforderlich",
        start_date_in_future: "Startdatum darf nicht in der Zukunft liegen",
        end_date_in_future: "Enddatum darf nicht in der Zukunft liegen",
        start_bigger_than_end: "Startdatum muss kleiner als das Enddatum sein",
      },
      comments: {
        add_comment_label: "Kommentar hinzufügen",
        commented: "kommentierte",
        add_comment_menu_item: "Kommentar hinzufügen",
        unable_to_create_error: "Kommentar kann nicht erstellt werden",
        unable_to_delete_error: "Kommentar kann nicht gelöscht werden",
        delete_comment_menu_item: "Löschen",
        delete_confirmation_title: "Kommentar wirklich löschen?",
        delete_confirmation_confirm: "Löschen",
        delete_confirmation_cancel: "Abbrechen",
        loading_message: "Kommentare werden geladen...",
      },
      edit_event_menu_item: "Bearbeiten",
      show_processes_menu_item: "Prozesse anzeigen",
      hide_processes_menu_item: "Prozesse ausblenden",
      more_info_menu_item: "Mehr informationen",
      add_actual_event: "Ist-Vorgang hinzufügen",
      edit_actual_event: "Vorgang bearbeiten",
      actual_active: "in Arbeit",
      actual_finished: "Abgeschlossen",
      delete_actual_confirmation_title: "Vorgang löschen",
      delete_actual_confirmation_confirm: "Löschen",
      delete_actual_confirmation_cancel: "Abbrechen",
      delete_actual_confirmation_message: "Möchtest du den Vorgang wirklich löschen?",
      actual_not_filled: "Bitte fülle alle Felder ",
      actual_period_error: "Das Ende liegt vor dem Start",
      actual_period_event_error: "Beim Speichern ist ein Fehler aufgetreten.",
      nothing_to_undo_message: "Nichts rückgängig zu machen",
      nothing_to_redo_message: "Nichts zu wiederholen",
      excel_export_error_message: "Exportdaten können nicht abgerufen werden",
      unable_to_load_plan_error_message: "Plan kann nicht geladen werden",
      unable_to_generate_pdf_error_message: "PDF kann nicht generiert werden",
      create_revision_error_message:
        "Es ist ein Fehler aufgetreten. Bitte versuche es noch einmal!",
      calendar_week_label: "KW",
      name_column_label: "Name",
      export_name: "Terminplan",
      processes: {
        breaks: "Pausen",
        intervals: "Intervalle",
      },
      working_days_label: "Arbeitstag | Arbeitstage",
      working_days_input_label: "Arbeitstage",
      outages: "Kameraausfälle",
      no_search_results: "Keine Einträge gefunden",
      non_working_days: "Feiertage und Störungen",
      unable_to_load_plan: "Plan kann nicht geladen werden",
      mark_as_done_modal: {
        mark_as_done_button: "Als erledigt markieren",
        date_label: "Wann wurde der Vorgang abgeschlossen?",
        go_to_last_process: "zum letzten Prozess gehen",
        go_to_last_concreting: "zur letzten Betonage gehen",
      },
      unable_to_save_changes: "Änderungen können nicht gespeichert werden",
      planned_progress: "Geplanter Fortschritt",
      actual_progress: "Ist-Fortschritt",
      project_completed_message: "Projekt abgeschlossen - 100%",
      to_planner_link: "Zum Terminplan",
      progress_excel_export: {
        name: "Name",
        planned_start: "Soll-Start",
        planned_end: "Soll-Ende",
        actual_start: "Ist-Start",
        actual_end: "Ist-Ende",
        planned_working_days: "Soll AT",
        actual_working_days: "Ist AT",
        progress: "Fortschritt",
        completed: "Abgeschlossen",
        deviation_end_date: "Enddatum Abweichung",
        weight: "Gewicht",
      },
    },
    site_activity: {
      title: "Bauaktivität",
      tab_walking_paths: "Laufwege",
      pick_a_date: "Wähle ein Datum",
      date: "Datum",
      search: "Suche",
      error_timeframe: "Es gibt keine Daten für diesen Zeitraum",
      no_data: "Bisher noch keine aufgezeichnete Bauaktivität. Das Tracking startet in Kürze!",
    },
    critical_path: {
      title: "Terminplan-Delta",
      similar_components: "Ähnliche Komponenten",
      no_critical_path: "Kein kritischer Pfad gefunden",
      unable_to_load: "Kritischer Pfad kann nicht geladen werden",
      unable_to_update: "Aktualisierung des kritischen Pfades nicht möglich",
      planned_end: "Soll Ende",
      actual_start: "Ist Start",
      projected_project_end: "Prognostiziertes Projektende",
      projected_end: "Prognostiziertes Ende",
      projected_end_abbreviated: "Progn. Ende",
      actual_end: "Ist Ende",
      days: "Tage",
      working_days: "AT",
      not_started: "nicht gestartet",
      active: "aktiv",
      new_tag: "neuer Tag",
      click_to_lock: "Zum Sperren klicken",
      latest_planned_component_label: "letzte geplante Komponente",
      time_critical_component_label: "zeitkritische Komponente",
      projected_end_component_label: "prognostiziertes Ende Komponente",
      in_delay: "in Verzug",
      previous_delta: "Delta aus vorherigem Geschoss",
      max_from: "Maximum aus",
      cycle_times: "Durchlaufzeiten",
    },
  },
  calendar: {
    today: "Heute",
    week_days: {
      "1": {
        long: "Montag",
        short: "Mo",
      },
      "2": {
        long: "Dienstag",
        short: "Di",
      },
      "3": {
        long: "Mittwoch",
        short: "Mi",
      },
      "4": {
        long: "Donnerstag",
        short: "Do",
      },
      "5": {
        long: "Freitag",
        short: "Fr",
      },
      "6": {
        long: "Samstag",
        short: "Sa",
      },
      "7": {
        long: "Sonntag",
        short: "So",
      },
      "0": {
        long: "Sonntag",
        short: "So",
      },
    },
    months: {
      "1": {
        long: "Januar",
        short: "Jan",
      },
      "2": {
        long: "Februar",
        short: "Feb",
      },
      "3": {
        long: "März",
        short: "Mär",
      },
      "4": {
        long: "April",
        short: "Apr",
      },
      "5": {
        long: "Mai",
        short: "Mai",
      },
      "6": {
        long: "Juni",
        short: "Jun",
      },
      "7": {
        long: "Juli",
        short: "Jul",
      },
      "8": {
        long: "August",
        short: "Aug",
      },
      "9": {
        long: "September",
        short: "Sep",
      },
      "10": {
        long: "Oktober",
        short: "Okt",
      },
      "11": {
        long: "November",
        short: "Nov",
      },
      "12": {
        long: "Dezember",
        short: "Dez",
      },
    },
  },
  report: {
    title: "Bautagesbericht",
    planned_project_start: "Projektstart",
    planned_project_end: "Geplantes Projektende",
    delete_report: "Lösche Bericht",
    overview_link: "Zur Berichtsübersicht",
    config: "Einstellungen",
    logo_upload: "Firmenlogo",
    logo_upload_desc: "Drag & Drop PNG oder JPG",
    config_sections: "Berichtsabschnitte",
    bulk_download: "Bulk Download",
    weather_threshold: "Wetterwarnungen",
    tracking_label_enabled: "Intelligentes Tracking durch oculai",
    tracking_label_disabled: "Anwendung der konfigurierten Projektarbeitszeiten",
    process_time_label: "Arbeitszeit als Start- und Endzeit für neue Prozesse zuweisen",
    process_time_desc:
      "Initialisiere die Start- und Endzeit von Prozessen auf Basis der Arbeitszeiten",
    logo_tooltip:
      "Lade hier das Firmenlogo in PNG oder JPEG Format hoch, um den Bautagesbericht zu invidualisieren.",
    working_hours_tooltip:
      "Definiere hier, ob oculai die Arbeitszeit automatisiert ermitteln soll, oder lege fixe Arbeitszeit für jeden Wochentag fest.",
    weather_warnings_tooltip:
      "Definiere Regeln, um Schlechtwetterwarnungen in der Monatsübersicht des Bautagesberichts anzuzeigen.\n\nRegeln können nach dem Format des DWD angelegt werden.",
    sections_tooltip:
      "Verschiebe Berichtsabschnitte mittels Drag & Drop zwischen den Boxen Aktiv und Inaktiv, um Abschnitte im Bericht Ein- bzw. Auszublenden.\n\nVerschiebe einen Abschnitt innerhalb einer Box, um die Reihenfolge zu invidualisieren.",
    site_duration_start: "Start",
    site_duration_end: "Ende",
    report_approved: "Bericht genehmigt!",
    weather: {
      time_range: "Berücksichtige Warnungen im Zeitraum von:",
      precipitation: "Niederschlag",
      cold_temperature: "Lufttemperatur (kalt)",
      hot_temperature: "Lufttemperatur (warm)",
      wind: "Wind",
      max_wind_speed: "Max. Windgeschwindigkeit",
      cold_temperature_type: "°C",
      hot_temperature_type: "°C",
      precipitation_type: "mm/h",
      wind_type: "km/h",
      min: "min",
      max: "max",
      min_value: "Tagesminimum",
      max_value: "Tagesmaximum",
      "24/7-7": "7-7 Uhr (24h)",
      "7/7-14": "7-14 Uhr",
      WEATHER_TIME_RANGE: "Arbeitszeitraum",
      ENTIRE_TIME_PERIOD: "Ges. Spanne",
      precipitation_volume: "Niederschlagsmenge",
      for: "für",
      within: "innerhalb von",
      unit: "Einheit",
      and: "und",
      or: "oder",
    },
    section_title: {
      working_hours: "Arbeitszeit",
      workforce: "Personalstärke",
      processes: "Prozesse",
      special_events: "Besondere Vorkommnisse",
      notes: "Notizen",
      materials: "Eintreffendes Material",
      material: "Material",
      machinery: "Maschineneinsatz",
      old_data_label: "Einträge von vorherigem Tag übernehmen",
      gallery: "Galerie",
      site_duration: "Arbeitszeit",
      weather: "Wetter",
      disturbances: "Störungen",
    },
    sections: {
      workforce: {
        workforce_type_entries: {
          project_manager: "Projektleiter",
          construction_manager: "Bauleiter",
          foreman: "Polier",
          construction_worker: "Arbeiter",
          construction_skilled_worker: "Baufacharbeiter",
          crane_operator: "Kranfahrer",
          equipment_operator: "Geräteführer",
        },
        workforce_type: "Rolle",
        company: "Unternehmen",
        amount: "Personalanzahl",
        note: "Notiz",
      },
      processes: {
        process_type: "Prozess",
        location: "Standort",
        start_time: "Startzeit",
        end_time: "Endzeit",
        duration: "Dauer",
        note: "Notiz",
      },
      special_events: {
        special_events_type_entries: {
          additional_service: "Zusätzliche Leistungen",
          order_contractor: "Anordnung des Bauherrn",
          plan_change: "Planänderung",
          heavy_equipment_use: "Großgeräte-Einsatz",
          acceptance: "Abnahme",
          visit: "Besuche",
          other: "Sonstiges",
          impediment: "Störung",
          hourly_wage_work: "Stundenlohnarbeiten",
          reinforcement_inspection: "Bewehrungsabnahme",
          condition_assessment: "Zustandsfeststellung",
        },
        special_events_type: "Typ",
        note: "Notiz",
      },
      material: {
        material_type: "Name",
        amount: "Menge",
        unit: "Einheit",
        note: "Notiz",
        unit_entries: {
          kg: "kg",
          "m^2": "m^2",
          "m^3": "m^3",
          piece: "Stk",
          t: "t",
        },
      },
      machinery: {
        machinery_type: "Typ",
        amount: "Menge",
        note: "Notiz",
      },
      gallery: {
        oai_images_title: "Wähle Bilder aus der Kamera Galerie",
        custom_images_title: "ODER lade ein eigenes Bild hoch",
        no_images: "Keine Bilder gefunden für: ",
        add_note: "Füge Notiz hinzu",
      },
      disturbances: {
        name: "Name",
        type: "Typ",
        date_range: "Zeitraum",
        description: "Beschreibung",
        confirmation_header: "Bestätigung",
        cancel_action: "Abbrechen",
        delete_action: "Löschen",
        confirmation_message:
          "Möchtest du diese Störung wirklich löschen? Diese Aktion betrifft die Störungen aller Berichte.",
      },
    },
    general: {
      custom_author: "Fix eingestellte Arbeitszeit",
      empty_combobox: "Bitte wähle eine Option",
      empty_options: "Add a new value",
      last_update: "Zuletzt aktualisiert",
      approved_at: "Genehmigt am",
      approved_by: "Genehmigt von",
      by: "von",
      before: "Vorher",
      after: "Nachher",
      old_data_label: "Übernehme von",
      merge_processes_title: "Führe eigene Einträge mit oculai Daten zusammen.",
      merge_processes_sub_title: "Identische Einträge werden nicht hinzugefügt.",
      merge_processes_button: "Daten hinzufügen",
      merge_notification: "oculai Daten hinzufügen",
      prev_day: "Vorheriger Arbeitstag",
      old_data_title: "Übernehme Daten von",
      no_images: "Noch keine Bilddaten verfügbar!",
      processing: "Erstellung",
      data: "Daten",
      create: "Füge hinzu",
      type_value: "Gebe Wert ein, der hinzugefügt werden soll",
      gallery_no_image: "Kein Bild verfügbar",
      notes_placeholder: "Notiz hinzufügen...",
      section_empty: "Keine Einträge.",
      enable_time_visibility: "Anzeige von Start- und Endzeiten für Prozesseinträge",
    },
    bulk_export: {
      title: "Sammel Download",
      main_label: "Wähle eine Zeitspanne",
      calendar_label:
        "Du kannst eine Zeitspanne von bis zu 31 Tagen für das Herunterladen von Berichten wählen",
      file_type_label: "Dokumententyp",
      status_type_label: "Status des Berichts",
      date_range_placeholder: "Wähle bis zu 31 Tage",
      approved_reports: "Nur Genehmigte Berichte",
      all_reports: "Alle Berichte",
      today: "Heute",
      this_month: "Dieser Monat",
      last_month: "Letzter monat",
      last_30_days: "Letzte 30 Tage",
      this_week: "Diese Woche",
      last_week: "Letzte Woche",
      pdf: "Kombinierte PDF",
      zip: "ZIP",
    },
  },
  buttons: {
    cancel: "Schließen",
    save: "Speichern",
    back_overview: "Zurück zur Übersicht",
    export_report: "Bericht exportieren",
    export_plain: "Export",
    send: "Sende",
    copy: "Übernehme",
    stay_page: "Bearbeitung fortsetzen",
    leave_page: "Seite verlassen",
    export: "Trotzdem exportieren",
    search: "Suche",
    download: "Download",
    edit: "Bearbeiten",
    bulk_export: "Sammel Download",
    save_and_approve: "Speichern und genehmigen",
    approve: "Genehmigen",
    revert_changes: "Änderungen verwerfen",
    apply: "Übernehmen",
    comment: "Kommentieren",
    add_images: "Bilder hinzufügen",
    download_image_range: "Bilder für Zeitraum herunterladen",
    count_selected: "{selected} von {total} ausgewählt",
    add: "Hinzufügen",
    delete: "Löschen",
    time_range: "Zeitraum",
    import: "Importieren",
    clear: "Löschen",
    load_more: "Mehr laden",
    create: "Erstellen",
    submit: "Absenden",
    next: "Weiter",
  },
  err: {
    no_reports_found: "Keine Berichte gefunden für den ausgewählten Zeitraum",
    required: "Wert erforderlich",
    negative_value: "Negativer Wert",
    time: "Endzeit muss größer als die Startzeit sein",
    empty_values: "Bitte befülle alle markierten Felder!",
    saved_values: "Keine Daten für den gewählten Tag verfügbar",
    invalid_date: "Invalides Datum. Bitte folge dem Muster.",
    save_before_leave:
      "Änderungen, die du vorgenommen hast, werden möglicherweise nicht gespeichert.",
    save_before_export:
      "Es gibt ungespeicherte Änderungen. Möchtest du die Änderungen verwerfen und trotzdem exportieren?",
    save_before_leave_title: "Ungespeicherte Änderungen",
    save_before_mode_switch: "Nicht gespeicherte Änderungen gehen verloren. Trotzdem fortfahren?",
    server_error: "Etwas ist schief gelaufen. Bitte versuche es später noch einmal.",
    file_type_image: "Ungültiger Dateityp. Bitte lade eine Bilddatei hoch.",
    err_future_date: "Dieses Datum liegt in der Zukunft. Bitte wähle ein anderes Datum!",
    weather: {
      data_not_found: "Keine Wetterdaten gefunden!",
      project_not_found: "Projekt nicht gefunden.",
    },
    internal_error: "Interner Server error.",
    error_streams_not_found: "Im Moment sind keine Bilder verfügbar!",
    no_images_found: "Es sind keine Bilder für das gewählte Datum verfügbar.",
    no_data_found: "Es sind keine Daten für das gewählte Datum verfügbar.",
    no_weather_data: "Es sind keine Wetterdaten für das gewählte Datum verfügbar.",
    no_live_found: "Die Kamera ist aktuell offline.",
    no_data_yet: "Bilddaten werden nach der Installation der Kameras angezeigt.",
    no_streaming_found: "Es wurden keine Streamingdaten für den gewählten Zeitraum gefunden.",
    err_period_future_date: "Bitte wähle einen früheren Tag als heute.",
    no_timelapse_found: "Für das ausgewählte Datum ist kein Zeitraffer gespeichert.",
    export: {
      no_date_selected: "Kein Datum ausgewählt",
      no_export_option: "Keine Export Option gewählt",
      no_status_option: "Keine Status Option gewählt",
      no_reports_found: "Keine Berichte für den ausgewählten Zeitraum gefunden",
    },
    planner: {
      no_camera_per_item: "Es gibt keine Bilddaten für diesen Vorgang.",
    },
    inputs: {
      time_wrong_format: "Falsches Zeitformat",
    },
  },
  general: {
    new_label: "Neu",
    before: "Vorher",
    after: "Nachher",
    active: "Aktiv",
    inactive: "Inaktiv",
    label_upload_image: "Lade ein Bild hoch",
    loading_message: "Das kann ein paar Sekunden dauern, bitte schließe die Seite nicht.",
    loading: "Wird geladen...",
    search_placeholder: "Suche",
    no_data_yet: "Noch keine Daten vorhanden",
  },
  video_player: {
    unable_to_play_message: "Video kann nicht abgespielt werden",
    low_bandwidth_mode: "Low-Bandwidth-Modus",
  },
  time: {
    abbr_hour: "Std.",
    abbr_day: "AT",
    working_day: "Arbeitstag | Arbeitstage",
    day: "Tag | Tage",
    hour: "Stunde | Stunden",
    minute: "Minute | Minuten",
    last_day: "Letzter Tag",
    last_week: "Letzte Woche | Letzte {count} Wochen",
    last_calendar_week: "Letzte Kalenderwoche",
    last_month: "Letzter Monat | Letzte {count} Monate",
    last_year: "Letztes Jahr | Letzte {count} Jahre",
    all_time: "Gesamt",
    start_gantt: "START",
    end_gantt: "ENDE",
  },
  working_day: {
    working_day: "Arbeitstag | Arbeitstage",
    working_day_abbrev: "AT",
    calendar_day: "Tage | Tag",
    non_working_day: "Arbeitsfreier Tag",
    holiday: "Urlaub/Feiertag",
    holiday_days: "Feier-/Urlaubstag | Feier-/Urlaubstage",
    critical_disturbance: "Kritische Störung",
    critical_disturbance_days: "Kritischer Störungstag | Kritische Störungstage",
    active: "Aktiv",
    active_days: "Aktiver Tag | Aktive Tage",
    inactive: "Stillstand",
    inactive_days: "Stillstandstag | Stillstandstage",
    outage: "Kamerausfall",
    outage_days: "Kamerausfallstag | Kameraausfallstage",
  },
  unit_values: {
    header: "Aufwandswerte",
    loading_error: "Aufwandswerte können nicht geladen werden",
    saving_error: "Aufwandswerte können nicht gespeichert werden",
    no_unit_values: "Keine Aufwandswerte",
    building_label: "Gebäude",
    level_label: "Geschoss",
    section_label: "Abschnitt",
    duration_label: "Dauer",
    duration_unit: "Std.",
    workforce_label: "Arbeitskräfte",
    workforce_unit: "n",
    quantity_label: "Menge",
    unit_value_label: "Aufwandswert",
    unit_value_unit: "Ah",
    merge_rows: "Komp. zusammenführen",
    split_rows: "Komp. aufteilen",
    add_type: "Aufwandswert hinzufügen",
    export_to_excel: "Excel Export",
    planned_start: "Start",
    planned_end: "Ende",
    detail_oculai_new_calculated: "oculai angepasst",
    detail_delta_label: "Aufschlag",
    detail_custom_delta_label: "Anpassung",
    approved: "Genehmigt",
    select_type: "Aufwandswert auswählen",
    header_included_processes: "Enthaltene Prozesse",
    column_modal: {
      title_add: "Neuer Aufwandswert",
      title_edit: "Aufwandswert bearbeiten",
      type_field: "Typ",
      validation_type_required: "Typ ist erforderlich",
      no_types_found: "Kein Typ gefunden",
      invalid_number: "Ungültige Nummer",
      cleaned_unit_values_header: "Bereinigter Aufwandswert",
      type_duration_factor: "Randstundenfaktor",
      type_duration_factor_tooltip:
        "Randstunden sind Arbeitsstunden, welche nicht der Kerntätigkeit zugeordnet werden. Ein Beispiel hierfür ist die Vorbereitung der Arbeitsstelle (Reinigen, Aufräumen, Umlagern).",
      type_workforce_delta: "Arbeitskraft Aufschlag",
      type_workforce_delta_tooltip:
        "Blindstunden sind Arbeitsstunden, welche nicht von oculai erfasst werden können, da diese nicht durch die Kamera erfasst werden können. Ein Beispiel hierfür ist das Ausschalen der Decke.",
      type_value_delta: "Statischer Aufschlag Aufwandswert",
      type_value_delta_tooltip:
        "Mittels der Blinstundenpauschale kann der Aufwandswert direkt korrigiert werden: 0.5 (oculai Aufwandswert) + 0.3 (Blindstundenpauschale) = 0.8",
    },
    types: {
      uv_wall_default: { label: "Ortbetonwände", unit: "m²" },
      uv_wall_default_formwork_m2: { label: "Ortbetonwände Schalung", unit: "m²" },
      uv_wall_default_rebar_t: { label: "Ortbetonwände Bewehrung", unit: "t" },
      uv_wall_default_concrete_m3: {
        label: "Ortbetonwände Betonage Kübel/Pumpe",
        unit: "m³",
      },
      uv_wall_default_concrete_bucket_m3: {
        label: "Ortbetonwände Betonage Kübel",
        unit: "m³",
      },
      uv_wall_default_concrete_pump_m3: { label: "Ortbetonwände Betonage Pumpe", unit: "m³" },
      uv_wall_default_element_m2: { label: "Fertigteilwände/Hohlkammerwände", unit: "m²" },
      uv_wall_parapet_m2: { label: "Brüstung", unit: "m²" },
      uv_wall_parapet_formwork_m2: { label: "Brüstung Schalung", unit: "m²" },
      uv_wall_parapet_rebar_t: { label: "Brüstung Bewehrung", unit: "t" },
      uv_wall_parapet_concrete_m3: { label: "Brüstung Betonage Kübel/Pumpe", unit: "m³" },
      uv_wall_parapet_concrete_bucket_m3: { label: "Brüstung Betonage Kübel", unit: "m³" },
      uv_wall_parapet_concrete_pump_m3: { label: "Brüstung Betonage Pumpe", unit: "m³" },
      uv_wall_column_m3: { label: "Ortbetonstützen", unit: "m³" },
      uv_wall_column_pc: { label: "Ortbetonstützen", unit: "Stk" },
      uv_wall_column_m2: { label: "Ortbetonstützen", unit: "m²" },
      uv_wall_column_formwork_m2: { label: "Ortbetonstützen Schalung", unit: "m²" },
      uv_wall_column_rebar_t: { label: "Ortbetonstützen Bewehrung", unit: "t" },
      uv_wall_column_concrete_bucket_m3: {
        label: "Ortbetonstützen Betonage Kübel/Pumpe",
        unit: "m³",
      },
      uv_wall_column_concrete_bucket: { label: "Ortbetonstützen Betonage Kübel", unit: "m³" },
      uv_wall_column_concrete_pump: { label: "Ortbetonstützen Betonage Pumpe", unit: "m³" },
      uv_wall_column_element_pc: { label: "Fertigteilstützen", unit: "Stk" },
      uv_wall_brickwork_all_m2: { label: "Mauerwerk ges.", unit: "m²" },
      uv_wall_brick_outline_lm: { label: "Kimmschicht", unit: "lfm" },
      uv_wall_brickwork_m2: { label: "Mauerwerk", unit: "m²" },
      uv_ceiling_m2: { label: "Decke", unit: "m²" },
      uv_ceiling_default_formwork_m2: { label: "Deckenschalung", unit: "m²" },
      uv_ceiling_element_semi_m2: { label: "Filigrandecken", unit: "m²" },
      uv_ceiling_default_rebar: { label: "Deckenbewehrung", unit: "t" },
      uv_ceiling_default_concrete_m3: { label: "Deckenbetonage Kübel/Pumpe", unit: "m³" },
      uv_ceiling_default_concrete_bucket_m3: { label: "Deckenbetonage Kübel", unit: "m³" },
      uv_ceiling_default_concrete_pump_m3: { label: "Deckenbetonage Pumpe", unit: "m³" },
      uv_ceiling_element_full_m2: { label: "Vollfertigteildecken", unit: "m²" },
      uv_ceiling_underbeams_m3: { label: "Unterzüge", unit: "m³" },
      uv_ceiling_underbeams_formwork_m2: { label: "Unterzug Schalung", unit: "m²" },
      uv_ceiling_underbeams_rebar_t: { label: "Unterzug Bewehrung", unit: "t" },
      uv_ceiling_underbeams_concrete_m3: {
        label: "Unterzug Betonage Kübel/Pumpe",
        unit: "m³",
      },
      uv_ceiling_underbeams_concrete_bucket_m3: {
        label: "Unterzug Betonage Kübel",
        unit: "m³",
      },
      uv_ceiling_underbeams_concrete_pump_m3: {
        label: "Unterzug Betonage Pumpe",
        unit: "m³",
      },
      uv_foundation_slab_m2: { label: "Bodenplatte", unit: "m²" },
      uv_foundation_slab_formwork_lm: { label: "Bodenplatte Schalung", unit: "lfm" },
      uv_foundation_slab_rebar_t: { label: "Bodenplatte Bewehrung", unit: "t" },
      uv_foundation_slab_concrete_m3: {
        label: "Bodenplatte Betonage Kübel/Pumpe",
        unit: "m³",
      },
      uv_foundation_slab_concrete_bucket_m3: {
        label: "Bodenplatte Betonage Kübel",
        unit: "m³",
      },
      uv_foundation_slab_concrete_pump_m3: {
        label: "Bodenplatte Betonage Pumpe",
        unit: "m³",
      },
      uv_foundation_point_m2: { label: "Einzelfundamente", unit: "m²" },
      uv_foundation_point_formwork_lm: { label: "Einzelfundamente Schalung", unit: "lfm" },
      uv_foundation_point_rebar_t: { label: "Einzelfundamente Bewehrung", unit: "t" },
      uv_foundation_point_concrete_m3: {
        label: "Einzelfundamente Betonage Kübel/Pumpe",
        unit: "m³",
      },
      uv_foundation_point_concrete_bucket_m3: {
        label: "Einzelfundamente Betonage Kübel",
        unit: "m³",
      },
      uv_foundation_point_concrete_pump_m3: {
        label: "Einzelfundamente Betonage Pumpe",
        unit: "m³",
      },
      uv_foundation_stripe_m2: { label: "Streifenfundamente", unit: "m²" },
      uv_foundation_stripe_formwork_lm: { label: "Streifenfundamente Schalung", unit: "lfm" },
      uv_foundation_stripe_rebar_t: { label: "Streifenfundamente Bewehrung", unit: "t" },
      uv_foundation_stripe_concrete_m3: {
        label: "Streifenfundamente Betonage Kübel/Pumpe",
        unit: "m³",
      },
      uv_foundation_stripe_concrete_bucket_m3: {
        label: "Streifenfundamente Betonage Kübel",
        unit: "m³",
      },
      uv_foundation_stripe_concrete_pump_m3: {
        label: "Streifenfundamente Betonage Pumpe",
        unit: "m³",
      },
    },
  },
  paywall: {
    working_hours: "Interesse an den Arbeitsstunden pro Prozess? Kontaktiere das oculai Team.",
  },
  disturbances: {
    disturbance: "Störung | Störungen",
    type: {
      plans_not_available: "Pläne liegen nicht vor",
      bad_weather: "Schlechtwetter",
      holiday_season: "Urlaubszeit",
      late_deliveries: "Späte Lieferungen",
      staff_shortage: "Personalmangel",
      refused_acceptance: "Verweigerte Abnahme von statisch kritischen Bauteile",
      missing_installation_parts: "Fehlende Einbauteile",
      unpaid_invoices: "Nicht bezahlte Rechnungen",
      missing_scaffolding: "Fehlendes Gerüst",
      missing_formwork: "Fehlende Schalung",
      missing_material: "Fehlendes Material",
      relocation_of_construction_site_equipment: "Umsetzen von Baustelleneinrichtung",
      missing_cranes: "Fehlende Kräne",
      missing_pre_prepayment: "Fehlende Vorleistung",
    },
  },
  process_classes: {
    "0": "Kiesschicht",
    "1": "Sauberkeitsschicht (Kübel)",
    "2": "Sauberkeitsschicht (Pumpe)",
    "3": "Schalung Fundamentplatte",
    "4": "Schalung Streifenfundament",
    "5": "Schalung Einzelfundament",
    "6": "Schalung Fundament Aufzugschacht",
    "7": "Bewehrung Fundamentplatte",
    "8": "Bewehrung Streifenfundament",
    "9": "Bewehrung Einzelfundament",
    "10": "Bewehrung Fundament Aufzugschacht",
    "11": "Betonage Fundamentplatte (Kübel)",
    "12": "Betonage Fundamentplatte (Pumpe)",
    "13": "Betonage Streifenfundament (Kübel)",
    "14": "Betonage Streifenfundament (Pumpe)",
    "15": "Betonage Einzelfundament (Kübel)",
    "16": "Betonage Einzelfundament (Pumpe)",
    "17": "Betonage Fundament Aufzugschacht (Kübel)",
    "18": "Betonage Fundament Aufzugschacht (Pumpe)",
    "19": "Isolierung Fundamentplatte",
    "20": "Isolierung Streifenfundament",
    "21": "Isolierung Einzelfundament",
    "22": "Isolierung Fundament Aufzugschacht",
    "23": "Filigrandecke",
    "24": "Vollfertigteildecken",
    "25": "Fertigteil Träger",
    "26": "Fertigteil Balkon",
    "27": "Fertigteil Treppe",
    "28": "Schalung Decke",
    "29": "Randschalung Decke",
    "30": "Schalung Unterzug",
    "31": "Bewehrung Decke",
    "32": "Einlegearbeiten Decke",
    "33": "Bewehrung Unterzug",
    "34": "Betonage Decke (Kübel)",
    "35": "Betonage Decke (Pumpe)",
    "36": "Betonage Unterzug (Kübel)",
    "37": "Betonage Unterzug (Pumpe)",
    "38": "Isolierung Decke",
    "39": "Mauerwerk",
    "40": "Kimmschicht",
    "41": "Fertigteil Wand / Hohlkammerwand",
    "42": "Fertigteil Stütze",
    "43": "Nasszelle",
    "44": "Schalung Wand",
    "45": "Schalung Stütze",
    "46": "Schalung Brüstung",
    "47": "Bewehrung Wand",
    "48": "Bewehrung Stütze",
    "49": "Bewehrung Brüstung",
    "50": "Betonage Wand (Kübel)",
    "51": "Betonage Wand (Pumpe)",
    "52": "Betonage Stütze (Kübel)",
    "53": "Betonage Stütze (Pumpe)",
    "54": "Betonage Brüstung (Kübel)",
    "55": "Betonage Brüstung (Pumpe)",
    "56": "Dachsparren",
    "57": "Isolierung Dach",
    "58": "Dachziegeln",
    "59": "Metalldach",
    "60": "Bewehrung Hohlkammerwand",
    "61": "Betonage Hohlkammerwand (Kübel)",
    "62": "Betonage Hohlkammerwand(Pumpe)",
    "63": "Mauerwerk Brüstung",
    "64": "Fertigteil Brüstung",
    "65": "Schalung Plattform Treppe",
    "66": "Bewehrung Plattform Treppe",
    "67": "Betonage Plattform Treppe (Kübel)",
    "68": "Betonage Plattform Treppe (Pumpe)",
    "69": "Unterjochung",
    "70": "Hohlkörper Decke",
    "1000": "Bewehrungsmatten Wand",
    "1001": "Bewehrung Stabstahl Wand",
    "1002": "Schubglieder Wand",
    "1003": "Schweißen Wand",
    "1004": "Installation Schlüsselverbindungen Wand",
    "1005": "Installation eingebettete Stahlkonstruktion Wand",
    "1006": "Schlüsselverbindungen lose Stäbe Wand",
    "1007": "Schalung Wandaussparung",
    "1008": "Sicherheitsunterlagen Wand",
    "1009": "Schutzgeländer Wand",
    "1010": "Installation Hebegerät Wand",
    "1011": "Kippen der Wand",
    "1012": "Einbau verbaute Wand Deckplatte",
    "1013": "Eck-Bewehrungsmatten Deckplatte",
    "1014": "Bewehrungslangstäbe",
    "1015": "Betonabstandhalter Deckplatte",
    "1016": "Bewehrungsmatten Platte",
    "1017": "Schweißen Platte",
    "1018": "Stahlkonstruktion (ESS) Platte",
    "1019": "ESS Befestigungen Deckplatte",
    "1020": "PT Kanäle / Plastikrohre Zugverbindung ",
    "1021": "Längsstahlkonstruktion Platte",
    "1022": "Einzelstäbe Deckplatte",
    "1023": "Externe Eck-Bewehrungsmatten Platte",
    "1024": "Plattenbewehrung Wasserstop",
    "1025": "Scherverbindungen (S-Haken) Platte",
    "1026": "Leerrohre (für Elektronik) Deckplatte",
    "1027": "Entfernen Hebegerät Deckplatte",
    "1028": "Sicherheitsgeländer Platte",
    "1029": "Sicherheitsmatten Platte",
    "1030": "Start Bügel Grundplatte",
    "1031": "Start Winkel Bügel Grundplatte",
    "1032": "Schlüsselverbindung (Male) Grundplatte",
    "1033": "Schubprozess Grundplatte",
    "1034": "Schubprozess - Schiebehaken setzen Grundplatte",
    "1035": "Eck-Bewehrungsmatten Gundplatte",
    "1036": "Reinigung Boden Buffer",
    "1037": "Schweißen Buffer",
    "1038": "Schubprozess - Schiebehaken setzen Buffer",
    "1039": "Schubprozess Element Buffer",
    "1040": "Schub Drücker Montage Buffer",
    "1041": "Hebekonsolen Buffer",
    "1042": "Stahlendrahmen Buffer",
    "1043": "Vorhang Schließung",
    "1044": "Vorhang Öffnung",
    "1045": "Gerüst Abbau Zentraler Gang",
    "1046": "Gerüst Aufbau",
    "1047": "Schließschalung Buffer",
    "1048": "WU Dichtband Buffer",
    "1049": "Schubprozess - Schiebehaken entfernen",
    "1050": "Entfernen Brücken",
    "1051": "Schalung Einfahren",
    "1052": "Vorgießen Rohre",
    "1053": "Folie für Rohre",
    "1054": "Betonieren Betonfahrzeug",
    "1055": "Betonieren Pumpe",
    "1056": "Betonaufbau Reinigung",
    "1057": "Flügelglätten",
    "1058": "Frischbetonverbundsfolie",
    "1059": "Entfernung Schalung Äußere Ecke Decke",
    "1060": "Schieben Wände Schließschalung",
    "1061": "Abdeckung Äußere Ecke",
    "1062": "Auftragung Bitumen",
    "1063": "Gerüstturm Abbau",
    "1064": "Tragbalken zurückschieben MT1",
    "1065": "Tragbalken zurückschieben Zentraler Gang",
    "1066": "Tragbalken zurückschieben MT2",
    "1067": "Tragbalken zurückschieben RT1",
    "1068": "Tragbalken zurückschieben RT2",
    "1069": "Installation Schieber",
    "1070": "Abbau Schieber",
    "1071": "Unterstützungsjoche SN+1",
    "1072": "Schalungsölung",
    "1073": "Beton Abstandshalter",
    "1074": "Tragbalken zurückschieben Zentrale Position",
    "1075": "Installation Brücken S+1",
    "1076": "Sicherheitsgeländer Decke",
    "1077": "Reinigung Betongeräte",
    concrete: "Betonage",
    prefab: "Fertigteil",
    reinforce: "Bewehrung",
    support: "Schalung",
    brickwork: "Mauerwerk",
    sheath: "Abdeckung",
    safety: "Sicherheit",
    transport: "Transport",
    ground: "Boden",
    foundation: "Fundament",
    ceiling: "Decke",
    wall: "Wand",
    roof: "Dach",
  },
  issue_report: {
    report_process_issue: "Prozessfehler melden",
    issue_type: "Fehlertyp",
    select_issue: "Fehlertyp wählen",
    new_process_class: "Neue Prozessklasse",
    process_mappable: "Zuordenbar",
    non_mappable_process_warning:
      "Bist du sicher? Prozessklasse kann keinem Vorgang im Terminplan zugeordnet werden.",
    new_level: "Geschoss",
    start: "Start",
    end: "Ende",
    intervals_error: "Intervalle dürfen nicht überlappen.",
    issue_description: "Fehlerbeschreibung",
    issue_process_class: "Prozessklasse",
    issue_time_interval: "Zeitintervall",
    issue_level: "Geschoss",
    issue_people_count: "Personenanzahl",
    issue_location: "Bauabschnitt",
    issue_process_not_exist: "Prozess sollte nicht existieren",
    resolve: "Aktualisieren",
    report_to_oculai: "Keine Lösung? An oculai Team übermitteln.",
    process_updated: "Prozess erfolgreich aktualisiert!",
    issue_failed_to_resolve: "Fehler konnte nicht behoben werden",
    issue_reported: "Anfrage erfolgreich an oculai übermittelt!",
    issue_failed_to_report: "Fehler konnte nicht übermittelt werden",
    level_to_mask_mapping_not_applicable:
      "Durch die Änderung des Geschosses konnte der Prozess keinem Vorgang im Terminplan zugeordnet werden - das oculai Team prüft die Änderung!",
    update_process_class: "Prozessklasse aktualisieren",
    update_level: "Geschoss aktualisieren",
    update_time_interval: "Zeitintervalle aktualisieren",
  },
  form: {
    select_option: "Option wählen",
    update: "Aktualisieren",
  },
};

export default translations;
