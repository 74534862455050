import processDurationService from "@/services/processDurationService";
import { QueryValueContext, QueryValueSingleValue } from "@/types/reports/PlotQueryValue";

const getPreviousPeriodValue = (context: QueryValueContext) => {
  const {
    previousPeriodProcesses,
    projectDurationSettings,
    processClasses,
    hasWorkingHoursFeature,
    outagesByRange,
  } = context;

  if (!previousPeriodProcesses || !projectDurationSettings || !outagesByRange) {
    return null;
  }

  const valueAnalysisPreviousPeriod = processDurationService.calculateProcessesAnalysis(
    previousPeriodProcesses,
    processClasses,
    projectDurationSettings,
    outagesByRange,
    {
      hasWorkingHoursFeature,
    },
  );

  return valueAnalysisPreviousPeriod.length === 1
    ? valueAnalysisPreviousPeriod[0].productiveDays
    : valueAnalysisPreviousPeriod.find((entry) => entry.name === "total")?.productiveDays ?? null;
};

const calculateProductiveDaysMetric = (
  context: QueryValueContext,
): QueryValueSingleValue | null => {
  const {
    processes,
    projectDurationSettings,
    processClasses,
    hasWorkingHoursFeature,
    outagesByRange,
  } = context;

  if (!processes || !projectDurationSettings || !outagesByRange) {
    return null;
  }

  const valueAnalysis = processDurationService.calculateProcessesAnalysis(
    processes,
    processClasses,
    projectDurationSettings,
    outagesByRange,
    {
      hasWorkingHoursFeature,
    },
  );
  const value =
    valueAnalysis.length === 1
      ? valueAnalysis[0].productiveDays
      : valueAnalysis.find((entry) => entry.name === "total")?.productiveDays ?? null;

  const previousPeriodValue = getPreviousPeriodValue(context);

  return {
    value,
    previous_period_value: previousPeriodValue,
  };
};

export default calculateProductiveDaysMetric;
