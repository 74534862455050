import { endOfDay } from "date-fns";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { PlanConfig } from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { createPlanContext } from "shared/views/planner/planner";
import { CycleTimesProcessGroup } from "@/types/reports/PlotCycleTimes";

const groupProcesses = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[],
  hierarchyTags: HierarchyTagStore[],
): CycleTimesProcessGroup[] => {
  const planContext = createPlanContext(planConfig, hierarchyTags);

  const processGroups = processes.reduce((acc, process) => {
    const key = process.planner_item_mapping.source_id;
    if (!key) {
      return acc;
    }
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(process);
    return acc;
  }, {} as Record<string, ShortenedProcessWithTags[]>);

  const result = Object.entries(processGroups)
    .map(([sourceId, processes]) => {
      const plannedEvent = planContext.plannedEventsBySourceId[sourceId];
      if (!plannedEvent) {
        return null;
      }
      const plannerItem = planContext.plannerItemsById[plannedEvent.planner_item_id];
      if (!plannerItem) {
        return null;
      }
      const actualEvent = planContext.actualEventsBySourceId[sourceId];
      if (!actualEvent) {
        return null;
      }
      const entry: CycleTimesProcessGroup = {
        section_mask_mapping: {
          building_name: planContext.tagsByTypeAndSourceId[`building_${sourceId}`]?.name || "",
          level_name: planContext.tagsByTypeAndSourceId[`level_${sourceId}`]?.name || "",
          section_name: planContext.tagsByTypeAndSourceId[`section_${sourceId}`]?.name || "",
        },
        processes: processes.filter(
          (process) => !actualEvent.end || process.start_time <= endOfDay(actualEvent.end),
        ),
        planned_event: plannedEvent,
        planner_item: plannerItem,
        is_active: !actualEvent.end,
      };
      return entry;
    })
    .filter((item) => item && item.processes.length > 0) as CycleTimesProcessGroup[];

  result.sort((a, b) => a.planner_item.order - b.planner_item.order);

  return result;
};

export default groupProcesses;
