<template>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1498.48 1498.48">
    <path class="cls-1" d="M167.88,180.6v1067.03c0,49.86,40.61,90.28,90.71,90.28h1072.02" />
    <polyline
      class="cls-1"
      points="436.07 1056.89 573.93 722.38 684.98 849.28 787.91 554.12 1052.61 873.08 1159.83 777.93"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped>
.cls-1 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 150px;
}
</style>
