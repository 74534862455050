<template>
  <Sidebar isHome>
    <Projects />
  </Sidebar>
</template>

<script setup lang="ts">
import Sidebar from "@/components/layout/Sidebar.vue";
import { useDisableScrollbarsOnBody } from "@/composables/screen";
import Projects from "@/views/dashboard/components/Projects.vue";

useDisableScrollbarsOnBody();
</script>
