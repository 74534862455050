import { addDays, eachWeekOfInterval, format, getDay, startOfDay, subDays } from "date-fns";
import * as Highcharts from "highcharts";
import { TooltipFormatterCallbackFunction, TooltipFormatterContextObject } from "highcharts";
import { CriticalPath } from "shared/types/CriticalPath";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { PlanConfig } from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import { getCriticalPathContext } from "shared/views/critical_path/criticalPath";
import * as criticalPathDelta from "shared/views/critical_path/criticalPathDelta";
import { createCriticalPathEx } from "shared/views/critical_path/criticalPathNode";
import i18n from "@/i18n";
import projectProgressService from "@/services/projectProgressService";
import { PlannerItemProgress, PlanProgressContext } from "@/types/Plan";

const { t } = i18n.global;

const seriesColors = {
  plan: "#BCCFCC",
  actual: "#336260",
  projected: "#2563eb",
};

const getPoint = (context: TooltipFormatterContextObject, seriesName: string) => {
  const series = context.series.chart.series.find((item) => item.name === seriesName);
  return series?.data ? series.data.find((point) => point.x === context.point.x) : undefined;
};

const createTooltipForPoint = (context: TooltipFormatterContextObject, seriesName: string) => {
  const point = getPoint(context, seriesName);
  if (!point || point.options.custom?.seriesName !== seriesName) {
    return "";
  }
  return `
    <tr>
      <td>
        <div
          style="border-radius: 5px; background: ${
            point.color
          }; color: #fff; padding: 2px 8px; margin-top: 8px; text-transform: capitalize; width: min-content;"
        >
          ${point.options.title}
        </div>
      </td>
      <td>
        <div style="margin-left: 8px; margin-top: 8px;">
          ${point.y?.toFixed(1) ?? "-"}%
        </div>
      </td>
    </tr>`;
};

const createProjectEndTooltip = (context: TooltipFormatterContextObject) => {
  const point = getPoint(context, "planned");
  if (!point || !point.options.custom?.isProjectEnd) {
    return "";
  }
  return `<div style="margin-top: 8px; font-weight: bold;">${t(
    "report.planned_project_end",
  )}</div>`;
};

const createActualEndTooltip = (context: TooltipFormatterContextObject) => {
  const point = getPoint(context, "actual");
  if (!point || !point.options.custom?.isActualEnd) {
    return "";
  }
  return `<div style="margin-top: 8px; font-weight: bold;">${t(
    "analytics.critical_path.actual_end",
  )}</div>`;
};

const seriesTooltipFormatter: TooltipFormatterCallbackFunction = function () {
  const formattedDate = format(this.point.x, "dd.MM.yyyy");
  const weekday = t(`calendar.week_days.${getDay(this.point.x)}.long`);
  const plannedTooltip = createTooltipForPoint(this, "planned");
  const actualTooltip = createTooltipForPoint(this, "actual");
  const projectedTooltip = createTooltipForPoint(this, "projected");
  const projectEndTooltip = createProjectEndTooltip(this);
  const actualEndTooltip = createActualEndTooltip(this);
  return `
    <div style="padding:6px">
      <div style="font-size: 1rem; padding-bottom: 4px; border-bottom: 1px solid #d1d5db">${formattedDate} <span style="font-size: 13px;">(${weekday})</span></div>
      <table>
        ${plannedTooltip}
        ${actualTooltip}
        ${projectedTooltip}
      </table>      
      ${projectEndTooltip}
      ${actualEndTooltip}
    </div>`;
};

const getOnlyLeafPlannerItems = (
  planConfig: PlanConfig,
  planProgressContext: PlanProgressContext,
) =>
  planConfig.planner_items.filter((plannerItem) => {
    const children = planProgressContext.plannerItemsByParentId[plannerItem._id];
    return !children || children.length === 0;
  });

const getPlannedStartEnd = (planConfig: PlanConfig, planProgressContext: PlanProgressContext) => {
  const interval = getOnlyLeafPlannerItems(planConfig, planProgressContext).reduce(
    (acc, plannerItem) => {
      const plannedEvent = planProgressContext.plannedEventsByPlannerItemId[plannerItem._id];
      if (!plannedEvent) {
        return acc;
      }
      if (!acc.start || plannedEvent.start < acc.start) {
        acc.start = startOfDay(plannedEvent.start);
      }
      if (!acc.end || plannedEvent.end > acc.end) {
        acc.end = startOfDay(plannedEvent.end);
      }
      return acc;
    },
    { start: null, end: null } as { start: Date | null; end: Date | null },
  );
  return {
    start: interval.start,
    end: interval.end,
  };
};

const getActualStartEnd = (planConfig: PlanConfig, planProgressContext: PlanProgressContext) => {
  const now = startOfDay(new Date());
  const interval = getOnlyLeafPlannerItems(planConfig, planProgressContext).reduce(
    (acc, plannerItem) => {
      const actualEvent = planProgressContext.actualEventsBySourceId[plannerItem.source_id];
      if (!actualEvent) {
        return acc;
      }
      if (!acc.start || actualEvent.start < acc.start) {
        acc.start = startOfDay(actualEvent.start);
      }
      if (actualEvent.end && (!acc.end || actualEvent.end > acc.end)) {
        acc.end = startOfDay(actualEvent.end);
      }
      return acc;
    },
    { start: null, end: null } as { start: Date | null; end: Date | null },
  );
  if (!interval.end || interval.end < now) {
    return {
      start: interval.start,
      end: now,
    };
  }
  return {
    start: interval.start,
    end: addDays(interval.end, 7),
  };
};

const calculateProgressForDate = (
  planConfig: PlanConfig,
  projectDurationSettings: ProjectDurationSettings,
  planProgressContext: PlanProgressContext,
  date: Date,
) => {
  // Here we only look at leafs and simply sum them up to calculate the progress to avoid traversing the tree.
  // This gives the same result as traversing and summing up the roots.
  const plannerProgressItems = getOnlyLeafPlannerItems(planConfig, planProgressContext).map(
    (plannerItem) => {
      const plannerProgressItem = projectProgressService.calculateProjectProgressForPlannerItem(
        plannerItem,
        planProgressContext,
        projectDurationSettings,
        date,
      );
      const result: PlannerItemProgress = {
        _id: plannerItem._id,
        ...plannerProgressItem,
        root: true,
        weight: null,
      };
      return result;
    },
  );
  const progress = projectProgressService.calculateProjectProgress(plannerProgressItems);
  return projectProgressService.calculatePercentage(
    progress.finishedWorkingDays,
    progress.totalWorkingDays,
  );
};

const calculatePlotPoint = (
  monday: Date,
  planConfig: PlanConfig,
  projectDurationSettings: ProjectDurationSettings,
  planProgressContext: PlanProgressContext,
) => {
  const progress = calculateProgressForDate(
    planConfig,
    projectDurationSettings,
    planProgressContext,
    monday,
  );
  const percentage = progress !== null ? progress * 100 : null;
  return {
    x: monday.getTime(),
    y: percentage,
  };
};

const getEachWeekOfInterval = (start: Date | null, end: Date | null) => {
  if (!start || !end) {
    return [];
  }
  const now = startOfDay(new Date());
  const oneWeekBeforeNow = subDays(now, 7);
  const result: Date[] = [];
  const days = eachWeekOfInterval({ start, end });
  for (const sunday of days) {
    const monday = addDays(sunday, 1);
    result.push(monday);
    if (monday > oneWeekBeforeNow && monday < now) {
      result.push(now);
    }
  }
  if (end && result[result.length - 1]?.getTime() !== end.getTime()) {
    result.push(end);
  }
  return result;
};

const mergeDays = (days: (Date | null)[]) => {
  const dateMsList = [
    ...new Set([...days.filter((date) => date).map((date) => (date as Date).getTime())]),
  ];
  dateMsList.sort();
  return dateMsList.map((dateMs) => new Date(dateMs));
};

const createProjectedEndDate = (
  planConfig: PlanConfig,
  hierarchyTags: HierarchyTagStore[],
  projectDurationSettings: ProjectDurationSettings,
  criticalPath: CriticalPath,
): Date | null => {
  const criticalPathEx = createCriticalPathEx(criticalPath, planConfig, hierarchyTags);
  const context = getCriticalPathContext(
    criticalPathEx,
    planConfig,
    hierarchyTags,
    projectDurationSettings,
  );
  const pathDelta = criticalPathDelta.calculateDelta(criticalPathEx, context);
  if (!pathDelta || !pathDelta.delta.projectedEndDate) {
    return null;
  }
  return pathDelta.delta.projectedEndDate;
};

const createProjectedSeries = (
  projectedEndDate: Date | null,
  actualSeries: Highcharts.SeriesSplineOptions,
): Highcharts.SeriesSplineOptions | null => {
  if (!projectedEndDate || !actualSeries.data || actualSeries.data.length === 0) {
    return null;
  }
  const point1 = actualSeries.data[actualSeries.data.length - 1];
  if (!point1) {
    return null;
  }
  const projectedSeriesName = "projected";
  const point2 = {
    x: projectedEndDate.getTime(),
    y: 100,
    color: seriesColors.projected,
    title: t("analytics.critical_path.projected_project_end"),
    custom: { seriesName: projectedSeriesName },
    marker: {
      enabled: true,
      radius: 7,
      symbol: "diamond",
    },
  } as Highcharts.PointOptionsObject;
  return {
    name: projectedSeriesName,
    type: "spline",
    marker: {
      enabled: false,
    },
    lineWidth: 3,
    color: seriesColors.projected,
    dashStyle: "ShortDot",
    data: [point1, point2],
    clip: false,
  } as Highcharts.SeriesSplineOptions;
};

const createPlannedAndActualSeries = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[],
  hierarchyTags: HierarchyTagStore[],
  projectDurationSettings: ProjectDurationSettings,
  projectedEndDate: Date | null,
) => {
  const planForPlannedProgress = projectProgressService.calculatePlanForPlannedProgress(planConfig);
  const planProgressContextForPlanned = projectProgressService.createPlanProgressContext(
    planForPlannedProgress,
    undefined,
    projectDurationSettings,
    hierarchyTags,
  );
  const plannedInterval = getPlannedStartEnd(planForPlannedProgress, planProgressContextForPlanned);
  const plannedDays = getEachWeekOfInterval(plannedInterval.start, plannedInterval.end);

  const planProgressContext = projectProgressService.createPlanProgressContext(
    planConfig,
    processes,
    projectDurationSettings,
    hierarchyTags,
  );
  const actualInterval = getActualStartEnd(planConfig, planProgressContext);
  const actualDays = getEachWeekOfInterval(actualInterval.start, actualInterval.end);

  const plannedSeries = {
    name: "planned",
    type: "spline",
    marker: {
      enabled: false,
    },
    lineWidth: 3,
    color: seriesColors.plan,
    data: [],
    clip: false,
  } as Highcharts.SeriesSplineOptions;

  const actualSeries: Highcharts.SeriesSplineOptions = {
    name: "actual",
    type: "spline",
    marker: {
      enabled: false,
    },
    lineWidth: 3,
    color: seriesColors.actual,
    data: [],
    clip: false,
  } as Highcharts.SeriesSplineOptions;

  const actualDaysMsSet = new Set(actualDays.map((day) => day.getTime()));
  const extendedDays = mergeDays([...plannedDays, ...actualDays, projectedEndDate]);

  let plannedReached100 = false;
  let actualReached100 = false;

  let plannedEnd: Date | null = null;
  let actualEnd: Date | null = null;

  for (const day of extendedDays) {
    const dayMs = day.getTime();
    const isProjectedEndDate = dayMs === projectedEndDate?.getTime();
    const shouldAddActual =
      (!actualReached100 && actualDaysMsSet.has(dayMs)) || (actualReached100 && !plannedReached100);

    const plannedPoint = calculatePlotPoint(
      day,
      planForPlannedProgress,
      projectDurationSettings,
      planProgressContextForPlanned,
    );
    const actualPoint = calculatePlotPoint(
      day,
      planConfig,
      projectDurationSettings,
      planProgressContext,
    );

    if (plannedPoint.y === 100 && !plannedEnd) {
      plannedEnd = day;
    }

    if (actualPoint.y === 100 && !actualEnd) {
      actualEnd = day;
    }

    const isProjectEnd = dayMs === plannedEnd?.getTime();
    const isActualEnd = dayMs === actualEnd?.getTime();

    if (!plannedReached100 || (plannedReached100 && (shouldAddActual || isProjectedEndDate))) {
      plannedSeries.data?.push({
        ...plannedPoint,
        color: seriesColors.plan,
        marker: isProjectEnd
          ? {
              enabled: isProjectEnd,
              radius: 7,
              symbol: "diamond",
            }
          : undefined,
        title: t("analytics.planner.planned_event_name"),
        custom: { seriesName: plannedSeries.name, isProjectEnd },
      });
    }
    if (shouldAddActual) {
      actualSeries.data?.push({
        ...actualPoint,
        color: seriesColors.actual,
        marker: isActualEnd
          ? {
              enabled: isActualEnd,
              radius: 7,
              symbol: "diamond",
            }
          : undefined,
        title: t("analytics.planner.actual_event_name"),
        custom: { seriesName: actualSeries.name, isActualEnd },
      });
    }
    if (plannedPoint.y === 100) {
      plannedReached100 = true;
    }
    if (actualPoint.y === 100) {
      actualReached100 = true;
    }
  }
  return { plannedSeries, actualSeries, plannedEnd, actualEnd };
};

export const getProgressCurveChartOptions = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[],
  hierarchyTags: HierarchyTagStore[],
  projectDurationSettings: ProjectDurationSettings,
  criticalPath: CriticalPath,
  options: { addProjectedCurve: boolean },
): Highcharts.Options => {
  const now = new Date();

  const projectedEndDate = options.addProjectedCurve
    ? createProjectedEndDate(planConfig, hierarchyTags, projectDurationSettings, criticalPath)
    : null;

  const { plannedSeries, actualSeries, plannedEnd, actualEnd } = createPlannedAndActualSeries(
    planConfig,
    processes,
    hierarchyTags,
    projectDurationSettings,
    projectedEndDate,
  );
  const projectedSeries = createProjectedSeries(projectedEndDate, actualSeries);

  const maxY = 110;

  return {
    chart: {
      zoomType: "y",
      spacingTop: 25,
    },
    title: undefined,
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        findNearestPointBy: "xy",
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    series: [actualSeries, plannedSeries, projectedSeries].filter((item) => item),
    tooltip: {
      useHTML: true,
      outside: true,
      crosshairs: {
        color: "gray",
        dashStyle: "dash",
      },
      formatter: seriesTooltipFormatter,
    },
    xAxis: {
      type: "datetime",
      dateTimeLabelFormats: {
        week: "%e. %B",
        month: "%B '%y",
      },
      plotLines: [
        {
          color: "#fa5252",
          dashStyle: "Dot",
          width: 1,
          value: now.getTime(),
          zIndex: 5,
          label: {
            rotation: 0,
            textAlign: "right",
            x: 16,
            y: -13,
            useHTML: true,
            formatter(): string {
              const formattedDate = format(now, "dd.MM.yyyy");
              return `<div style="background:#fa5252; padding: 4px 8px; color: white; border-radius: 0 5px 5px 0;">${formattedDate}</div>`;
            },
          },
        },
        plannedEnd && {
          color: "#7a9896",
          dashStyle: "Dot",
          width: 1,
          value: plannedEnd.getTime(),
          zIndex: 5,
        },
        actualEnd && {
          color: seriesColors.actual,
          dashStyle: "Dot",
          width: 1,
          value: actualEnd.getTime(),
          zIndex: 5,
        },
        projectedEndDate && {
          color: seriesColors.projected,
          dashStyle: "Dot",
          width: 1,
          value: projectedEndDate.getTime(),
          zIndex: 5,
          label: {
            rotation: 0,
            textAlign: "right",
            x: 16,
            y: -13,
            useHTML: true,
            formatter() {
              const formattedDate = format(projectedEndDate, "dd.MM.yyyy");
              return `<div style="background: ${seriesColors.projected}; padding: 4px 8px; color: #fff; border-radius: 0 5px 5px 0;">${formattedDate}</div>`;
            },
          },
        },
      ].filter((item) => item),
    },
    yAxis: {
      max: maxY,
      tickInterval: 10,
      title: {
        text: undefined,
      },
      labels: {
        formatter: function () {
          if (this.value === maxY) {
            return "";
          }
          return `${this.value}%`;
        },
      },
    },
  } as Highcharts.Options;
};
