<template>
  <BaseWidget
    :isLoading="isInitialLoad && isLoading"
    @delete="emit('delete')"
    @trackInteraction="emit('trackInteraction')"
    hideDragHandle
  >
    <div
      class="h-full flex flex-col px-4 py-3 overflow-auto"
      ref="containerRef"
      @mouseenter="showOverlay = false"
    >
      <div class="mb-3 mr-3 flex items-center gap-2">
        <DashboardDragHandle class="shrink-0" />
        <RouterLink
          class="font-semibold text-gray-900 truncate hover:text-yellow-800"
          :class="width > normalWidgetWidth ? 'text-lg' : 'text-base'"
          :to="{
            name: 'ProcessesTable',
            params: {
              customer_name: currentCustomerName,
              site_id: currentSiteId,
            },
          }"
          @click.prevent="emit('widgetRouteClicked')"
        >
          {{ $t("dashboard.process_widget.header") }}
        </RouterLink>
        <div
          v-if="lastSeenResult?.process && lastSeenResult?.not_seen_count !== null"
          class="text-xs bg-red-500 text-white rounded-full px-1 py-0.5 absolute flex items-center justify-center"
          style="top: -7px; right: -7px; min-width: 20px"
        >
          {{ lastSeenResult.not_seen_count }}
        </div>
      </div>
      <div
        v-if="lastSeenResult && !lastSeenResult.process && lastSeenResult?.process_count > 0"
        class="text-sm flex flex-col items-center justify-center gap-1 select-none flex-1 text-center"
      >
        <CheckCircleIcon class="h-16 w-16 text-green-400" v-if="width > normalWidgetWidth" />
        <span>{{ $t("dashboard.process_widget.all_caught_up") }}</span>
        <router-link :to="{ name: 'ProcessesTable' }" class="text-gray-600 underline">
          {{ $t("dashboard.process_widget.to_overview_link") }}
        </router-link>
      </div>
      <div
        v-if="!lastSeenResult || (!lastSeenResult.process && lastSeenResult.process_count === 0)"
        class="text-sm text-center flex-1 flex items-center justify-center"
      >
        {{ $t("dashboard.process_widget.no_processes") }}
      </div>
      <div
        class="flex flex-col gap-2 justify-center relative overflow-hidden"
        data-oai-left-over-space-calculator="ignore"
        v-if="lastSeenResult?.process"
      >
        <WidgetVideoPlayer
          :getContainerRef="getContainerRef"
          :loading="isLoading"
          :src="lastSeenResult?.url"
          :autoPlay="!useLiveImage"
          :section-mask-id="`${lastSeenResult.process.section_mask_mapping.id}`"
          :process-name="`${lastSeenResult.process.section_mask_mapping.level_name} - ${lastSeenResult.process.section_mask_mapping.section_name}`"
        />
        <div
          v-if="lastSeenResult?.process && showOverlay"
          class="absolute text-white text-center bg-yellow-500 py-3 px-3 whitespace-nowrap opacity-90 top-1/2 left-1/2"
          :style="{
            transform: `translate(-50%, -50%)`,
            width: `${videoPlayerDimensions.width}px`,
          }"
        >
          <div
            class="font-semibold truncate"
            :class="width > normalWidgetWidth ? 'text-base' : 'text-sm'"
          >
            {{ $t(`process_classes.${lastSeenResult?.process.encoded_label}`) }}
          </div>
          <div class="text-white-300 text-xs truncate" style="font-size: 12px">
            {{
              getFormattedDuration(
                lastSeenResult?.process.start_time,
                lastSeenResult?.process.end_time,
              )
            }}
            ({{ format(lastSeenResult?.process.start_time, "dd.MM.yyyy") }})
          </div>
        </div>
      </div>
      <div class="flex items-center gap-3 mt-3" v-if="lastSeenResult?.process">
        <router-link
          @click="trackEvent('dashboard_widget_process-detail_click')"
          :to="{
            name: 'ProcessesTable',
            params: {
              customer_name: currentCustomerName,
              site_id: currentSiteId,
            },
            query: {
              processId: lastSeenResult?.process?._id,
            },
          }"
          class="hover:!text-orange-700 flex-1 overflow-hidden"
        >
          <div
            class="font-bold truncate"
            :class="width > normalWidgetWidth ? 'text-base' : 'text-sm'"
          >
            {{ $t(`process_classes.${lastSeenResult?.process.encoded_label}`) }}
          </div>
          <div class="truncate" :class="width > normalWidgetWidth ? 'text-sm' : 'text-xs'">
            {{
              getFormattedDuration(
                lastSeenResult?.process.start_time,
                lastSeenResult?.process.end_time,
              )
            }}
            ({{ format(lastSeenResult?.process.start_time, "dd.MM.yyyy") }})
          </div>
          <div class="text-gray-500 text-xs truncate" style="font-size: 12px">
            {{ location }}
          </div>
        </router-link>
        <button
          type="button"
          class="flex items-center gap-1 bg-orange-300 text-white rounded pr-2 py-1 text-xs w-min"
          @click="handleNextClick"
          :class="width > normalWidgetWidth ? 'pl-3' : 'pl-2'"
        >
          <span v-if="width > normalWidgetWidth">{{
            $t("dashboard.process_widget.next_button")
          }}</span>
          <ChevronRightIcon class="w-5 h-5 cursor-pointer select-none" />
        </button>
      </div>
    </div>
  </BaseWidget>
</template>

<script lang="ts" setup>
import { ChevronRightIcon, CheckCircleIcon } from "@heroicons/vue/24/outline";
import { format, intervalToDuration } from "date-fns";
import { computed, onMounted, ref } from "vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useTrackEvent } from "shared/composables/tracking";
import logger from "shared/services/logger";
import { useLiveImageSetting } from "@/composables/stream";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";
import { LastSeenProcessResult, Process } from "@/types/Process";
import BaseWidget from "@/views/dashboard/componentsV2/BaseWidget.vue";
import DashboardDragHandle from "@/views/dashboard/componentsV2/DashboardDragHandle.vue";
import WidgetVideoPlayer from "@/views/dashboard/componentsV2/WidgetVideoPlayer.vue";
import { normalWidgetWidth, useVideoPlayerDimensions } from "@/views/dashboard/composables";

defineProps<{ width: number; height: number }>();
const emit = defineEmits(["delete", "widgetRouteClicked", "trackInteraction"]);

const lastSeenResult = ref<LastSeenProcessResult | null>(null);
const isInitialLoad = ref(true);
const isLoading = ref(false);
const showOverlay = ref(true);

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();
const trackEvent = useTrackEvent();

const containerRef = ref<HTMLDivElement | null>(null);
const getContainerRef = () => containerRef;

const videoPlayerDimensions = useVideoPlayerDimensions(containerRef);

const load = () => {
  isLoading.value = true;
  return OpsProcessesRepository.loadLastSeenProcess(currentCustomerName, currentSiteId)
    .then((data) => {
      lastSeenResult.value = data;
    })
    .catch((error) => {
      logger.error(error);
      lastSeenResult.value = null;
    })
    .finally(() => {
      isInitialLoad.value = false;
      isLoading.value = false;
    });
};

const loadNext = () => {
  isLoading.value = true;
  return OpsProcessesRepository.loadNextLastSeenProcess(
    currentCustomerName,
    currentSiteId,
    ((lastSeenResult.value as LastSeenProcessResult).process as Process).process_id,
  )
    .then((data) => {
      lastSeenResult.value = data;
      if (!data.process) {
        trackEvent("dashboard_widget_process_all_caught_up");
      }
    })
    .catch((error) => {
      logger.error(error);
      lastSeenResult.value = null;
    })
    .finally(() => {
      isLoading.value = false;
    });
};

const handleNextClick = () => {
  trackEvent("dashboard_widget_process-next_click");
  loadNext();
};

const getFormattedDuration = (startTime: Date, endTime: Date) => {
  const duration = intervalToDuration({
    start: startTime,
    end: endTime,
  });
  const durationFormattedHours = (duration.hours || 0).toString().padStart(2, "0");
  const durationFormattedMinutes = (duration.minutes || 0).toString().padStart(2, "0");

  return `${durationFormattedHours}:${durationFormattedMinutes}h`;
};

const location = computed(() =>
  [
    lastSeenResult.value?.process?.section_mask_mapping.building_name,
    lastSeenResult.value?.process?.section_mask_mapping.section_name,
    lastSeenResult.value?.process?.section_mask_mapping.level_name,
  ]
    .filter((item) => item)
    .join(" / "),
);

onMounted(() => {
  load();
});

const { useLiveImage } = useLiveImageSetting();
</script>
