<template>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1498.48 1498.48">
    <path class="cls-1" d="M166,180.6v1067.03c0,49.86,40.61,90.28,90.71,90.28h1072.02" />
    <line class="cls-1" x1="524.13" y1="1081.22" x2="524.13" y2="701.7" />
    <line class="cls-1" x1="774.36" y1="1081.22" x2="774.36" y2="564.16" />
    <line class="cls-1" x1="1032" y1="1081.22" x2="1032" y2="849.28" />
    <ellipse class="cls-3" cx="524.13" cy="383.65" rx="24.75" ry="26.53" />
    <ellipse class="cls-3" cx="774.36" cy="233.79" rx="24.75" ry="26.53" />
    <ellipse class="cls-3" cx="1032" cy="537.63" rx="24.75" ry="26.53" />
    <polyline class="cls-2" points="524.13 383.65 774.36 233.79 1032 537.63" />
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped>
.cls-1,
.cls-2 {
  fill: none;
}

.cls-1,
.cls-2,
.cls-3 {
  stroke-linecap: round;
  stroke-linejoin: round;
}

.cls-1,
.cls-3 {
  stroke-width: 150px;
}

.cls-2 {
  stroke-width: 100px;
}

.cls-3 {
  fill: #222221;
}
</style>
