import {
  parse,
  differenceInSeconds,
  startOfMonth,
  getWeek,
  setWeek,
  startOfDay,
  format,
  subHours,
  addHours,
  subDays,
  startOfISOWeek,
  addDays,
  getDaysInMonth,
} from "date-fns";
import { Ref } from "vue";
import { OutagesByRange } from "shared/types/Camera";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { NonWorkingDay, ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import {
  WorkingHourCurveAggregation,
  WorkingHourCurveReportFilters,
} from "@/types/reports/PlotWorkingHourCurve";
import { filterProcesses } from "@/views/reports/filters/filters";

type Duration = {
  name: string;
  duration: number;
};

const createWorkdayDict = (
  startDate: Date,
  endDate: Date,
  aggregation: WorkingHourCurveAggregation,
) => {
  const workdayDict = {} as Record<string, number>;
  let currentDate = new Date(startOfDay(startDate));

  if (aggregation === "week") {
    currentDate = startOfISOWeek(currentDate);
  }

  if (aggregation === "biweek") {
    const week = getWeek(startDate);
    const newWeek = Math.ceil(Number(week) / 2) * 2;
    currentDate = startOfISOWeek(setWeek(currentDate, newWeek));
  }

  if (aggregation === "month") {
    currentDate = startOfMonth(currentDate);
  }

  while (currentDate <= endDate) {
    const date = currentDate.getTime();
    workdayDict[date] = 0;

    if (aggregation === "day") {
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }

    if (aggregation === "week") {
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 7));
    }

    if (aggregation === "biweek") {
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 14));
    }

    if (aggregation === "month") {
      currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    }
  }

  return workdayDict;
};

export const getWorkingHourCurveData = (
  processes: ShortenedProcessWithTags[] = [],
  hierarchyTags: HierarchyTagStore[] = [],
  filter: WorkingHourCurveReportFilters,
  aggregation: WorkingHourCurveAggregation,
  t: (key: string) => string,
) => {
  let startDate = new Date();
  let endDate = new Date(0);

  const filteredProcesses = filterProcesses(processes, hierarchyTags, filter);

  const aggregatedProcesses = filteredProcesses.reduce((acc, process) => {
    const parsedDate = parse(process.date, "yyyy-MM-dd", new Date());
    let dateEntry = new Date(parsedDate);

    startDate = parsedDate < startDate ? parsedDate : startDate;
    endDate = parsedDate > endDate ? parsedDate : endDate;

    if (aggregation === "week") {
      dateEntry = startOfISOWeek(dateEntry);
    }

    if (aggregation === "biweek") {
      const week = getWeek(parsedDate);
      const newWeek = Math.ceil(Number(week) / 2) * 2;
      dateEntry = startOfISOWeek(setWeek(dateEntry, newWeek));
    }

    if (aggregation === "month") {
      dateEntry = startOfMonth(dateEntry);
    }

    const time = dateEntry.getTime();
    if (!acc[time]) {
      acc[time] = [];
    }

    acc[time].push(process);

    return acc;
  }, {} as Record<number, ShortenedProcessWithTags[]>);

  const sortedAggregatedProcesses = Object.keys(aggregatedProcesses)
    .sort()
    .reduce((acc, key) => {
      acc[key] = aggregatedProcesses[Number(key)];
      return acc;
    }, {} as Record<string, ShortenedProcessWithTags[]>);

  const workdayDict = createWorkdayDict(startDate, endDate, aggregation);

  const workingHourSchedule = Object.keys(workdayDict).reduce((acc, key) => {
    acc[key] = { sum_working_hours: 0, durations: [] };
    return acc;
  }, {} as Record<string, { sum_working_hours: number; durations: Duration[] }>);

  for (const [date, processes] of Object.entries(sortedAggregatedProcesses)) {
    const locationGroupedProcesses = processes.reduce((acc, process) => {
      const tags = [
        process.planner_item_mapping.building_name,
        process.planner_item_mapping.level_name,
        process.planner_item_mapping.section_name,
      ];

      const key = tags.filter(Boolean).join(" - ") || t("analytics.reports.no_mapping");

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(process);

      return acc;
    }, {} as Record<string, ShortenedProcessWithTags[]>);

    const durations: Duration[] = [];
    let sumWorkHours = 0;

    for (const [key, processGroup] of Object.entries(locationGroupedProcesses)) {
      let intervalsWorkHours = 0;

      processGroup.forEach((process) => {
        process.work_intervals.forEach((interval) => {
          const duration = differenceInSeconds(interval.end_time, interval.start_time) / 60 / 60;
          const durationInHours = Math.round(duration * 100) / 100;
          intervalsWorkHours += durationInHours * (interval.workforce.validated_count ?? 0);
        });
      });
      sumWorkHours += intervalsWorkHours;
      durations.push({
        name: key,
        duration: Math.round(intervalsWorkHours * 100) / 100,
      });
    }
    durations.sort((a, b) => a.name.localeCompare(b.name));

    workingHourSchedule[date] = {
      sum_working_hours: Math.round(sumWorkHours * 100) / 100,
      durations,
    };
  }

  return workingHourSchedule;
};

export const generateOutagesTooltip = (
  point: { x: number },
  aggregation: WorkingHourCurveAggregation,
  outages: Ref<OutagesByRange>,
  t: (key: string) => string,
) => {
  const day = format(point.x, "yyyy-MM-dd");

  if (aggregation !== "day" || !outages.value[day]?.length) {
    return "";
  }

  const filteredOutages = outages.value[day];

  return `
    <div class="flex flex-col mt-4">
      <h4 class="border-b pb-1 text-sm flex gap-2 justify-between items-center">
        ${t("analytics.planner.outages")}
      </h4>
      <div class="flex gap-2 flex-wrap mt-2" style="max-width: 400px">
            ${filteredOutages
              .map(
                (outage) => `
              <div class="flex text-sm text-white px-1 py-0.5 rounded w-min whitespace-nowrap bg-red-300">
                <span>${outage.camera_id}:&nbsp;</span>
                <span>${format(outage.start_time, "HH:mm")}-${format(
                  outage.end_time,
                  "HH:mm",
                )}</span>
              </div>
            `,
              )
              .join("")}
    </div>
  `;
};

export const generateHolidayTooltip = (
  point: { x: number },
  points: { x: number }[],
  aggregation: WorkingHourCurveAggregation,
  holidays: NonWorkingDay[] = [],
) => {
  const day = point.x;
  const pointIndex = points.findIndex((p) => p.x === point.x);
  const nextPoint = points[pointIndex + 1]?.x;

  const filteredHolidays: NonWorkingDay[] = [];

  if (aggregation === "day") {
    holidays.forEach((holiday) => {
      if (holiday.start_date.getTime() <= day && holiday.end_date.getTime() >= day) {
        filteredHolidays.push(holiday);
      }
    });
  } else {
    const lastDayRange = {
      week: 6,
      biweek: 13,
      month: getDaysInMonth(new Date(day)) - 1,
    };

    const lastDay = nextPoint
      ? subDays(nextPoint, 1).getTime()
      : addDays(day, lastDayRange[aggregation]).getTime();

    holidays.forEach((holiday) => {
      if (holiday.start_date.getTime() < lastDay && holiday.end_date.getTime() >= day) {
        filteredHolidays.push(holiday);
      }
    });
  }

  if (filteredHolidays.length === 0) {
    return "";
  }

  return `
        <div class="flex gap-2 flex-wrap mt-2" style="max-width: 400px">
            ${filteredHolidays
              .map((holiday) => {
                const timeRange =
                  holiday.start_date.getTime() === holiday.end_date.getTime()
                    ? format(holiday.start_date, "dd.MM.yyyy")
                    : `${format(holiday.start_date, "dd.MM.yyyy")}-${format(
                        holiday.end_date,
                        "dd.MM.yyyy",
                      )}`;
                return `
                      <div style="${
                        holiday.type === "disturbance"
                          ? "background-color: #f5e3be; color: #9c7018"
                          : "background-color: #bfdbfe4c; color: #1d4ed8"
                      }; border-radius: 4px; width: min-content; padding: 2px 4px; font-size: 14px; white-space: nowrap;">
                        ${holiday.name} (${timeRange})
                      </div>
                    `;
              })
              .join("")}
    `;
};

export const getHolidaysPlotBands = (
  projectDurationSettings: Ref<ProjectDurationSettings | undefined>,
) => {
  const nonWorkingDays = projectDurationSettings?.value?.non_working_days || [];

  const holidaysPlotBands = nonWorkingDays.map((day) => {
    const start = day.start_date.getTime();
    const end = day.end_date.getTime();

    return {
      from: subHours(start, 12).getTime(),
      to: addHours(end, 12).getTime(),
      color: day.type === "disturbance" ? "#F5E3BE" : "#ECF4FF",
      zIndex: 2,
    };
  });

  return holidaysPlotBands;
};

export const getWeekendPlotBands = (
  projectDurationSettings: Ref<ProjectDurationSettings | undefined>,
  outages: Ref<OutagesByRange>,
) => {
  if (!Object.keys(outages.value).length) {
    return [];
  }

  const start = parse(Object.keys(outages.value)[0], "yyyy-MM-dd", new Date());
  const end = parse(Object.keys(outages.value).at(-1) || "", "yyyy-MM-dd", new Date());
  const weekends = [];

  for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
    if (
      !projectDurationSettings.value?.settings.workingDaysAndHoursMap[date.getDay()].workingHours
    ) {
      weekends.push({
        from: subHours(date, 12).getTime(),
        to: addHours(date, 12).getTime(),
        color: "#F3F4F6",
        zIndex: 0,
      });
    }
  }

  return weekends;
};

export const generateDateTooltip = (
  point: { x: number },
  points: { x: number }[],
  aggregation: WorkingHourCurveAggregation,
  t: (key: string) => string,
) => {
  if (aggregation === "day") {
    const weekday = new Date(point.x).getDay() === 0 ? 7 : new Date(point.x).getDay();
    return `${format(point.x, "dd.MM.yyyy")}, ${t(`calendar.week_days.${weekday}.long`)}`;
  }

  const pointIndex = points.findIndex((p) => p.x === point.x);
  const nextPoint = points[pointIndex + 1];

  if (aggregation === "week" || aggregation === "biweek") {
    const start = format(point.x, "dd.MM.yyyy");
    let end = "";

    if (nextPoint) {
      end = format(subHours(nextPoint.x, 1), "dd.MM.yyyy");
    } else {
      if (aggregation === "week") {
        end = format(addDays(point.x, 6), "dd.MM.yyyy");
      } else {
        end = format(addDays(point.x, 13), "dd.MM.yyyy");
      }
    }
    const week =
      aggregation === "week"
        ? `(${t("analytics.planner.calendar_week_label")}${format(point.x, "w")})`
        : "";
    return `${start} - ${end} ${week}`;
  }

  if (aggregation === "month") {
    const month = t(`calendar.months.${new Date(point.x).getMonth() + 1}.long`);
    const year = format(point.x, "yyyy");

    return `${month} ${year}`;
  }
};

export const generatePercentageScoreTooltip = (
  point: { x: number; y: number },
  points: { x: number; y: number }[],
  aggregation: WorkingHourCurveAggregation,
  t: (key: string) => string,
) => {
  const pointIndex = points.findIndex((p) => p.x === point.x);
  const previousPoint = points[pointIndex - 1];

  if (!previousPoint) {
    return "";
  }

  const rawScore = Math.round(((point.y - previousPoint.y) / previousPoint.y) * 100) || 0;
  const score = rawScore === Infinity ? 100 : rawScore;
  const scoreColor = score > 0 ? "#56AF9C" : "#F87171";
  const defaultArrowStyle =
    "border-left: 7px solid transparent; border-right: 7px solid transparent; width: 5px; height:5px";
  const arrowStyle =
    score > 0
      ? `${defaultArrowStyle}; border-bottom: 10px solid ${scoreColor}`
      : `${defaultArrowStyle}; border-top: 10px solid ${scoreColor}`;

  const scoreTexts = {
    day: "since_last_day",
    week: "since_last_week",
    biweek: "since_last_biweek",
    month: "since_last_month",
  };
  const scoreText = scoreTexts[aggregation];

  return `
    <div class="flex items-center gap-1 overflow-hidden mb-3">
        <div style="${arrowStyle}"></div>
        <p>
          <span style="color: ${scoreColor}">${score > 0 ? "+" : ""}${score}%</span> 
          <span>${t(`analytics.reports.${scoreText}`)}</span>
        </p>
    </div>`;
};
