<template><slot /></template>

<script lang="ts" setup>
import { defineProps, inject, onBeforeMount, Ref, watch } from "vue";

const props = defineProps<{ isLoading: boolean }>();

const parentIsLoading = inject<Ref<boolean>>("loadingStateEmitterRef");

const updateLoadingState = () => {
  if (parentIsLoading) {
    parentIsLoading.value = props.isLoading;
  }
};

watch(
  () => props.isLoading,
  () => {
    updateLoadingState();
  },
);

onBeforeMount(() => {
  updateLoadingState();
});
</script>
