import { HierarchyTagContext, HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";

export const tagTypes: HierarchyType[] = ["building", "level", "section", "component"];

export const createHierarchyTagContext = (
  hierarchyTags: HierarchyTagStore[],
): HierarchyTagContext => {
  const tagsById = hierarchyTags.reduce((acc, tag) => {
    acc[tag._id] = tag;
    return acc;
  }, {} as Record<string, HierarchyTagStore | undefined>);

  const tagsBySourceId = hierarchyTags.reduce((acc, tag) => {
    for (const sourceId of tag.source_ids) {
      if (!acc[sourceId]) {
        acc[sourceId] = [];
      }
      (acc[sourceId] as HierarchyTagStore[]).push(tag);
    }
    return acc;
  }, {} as Record<string, HierarchyTagStore[] | undefined>);

  const tagsByTypeAndSourceId = hierarchyTags.reduce((acc, tag) => {
    for (const sourceId of tag.source_ids) {
      acc[`${tag.type}_${sourceId}`] = tag;
    }
    return acc;
  }, {} as Record<string, HierarchyTagStore | undefined>);

  return {
    tagsById,
    tagsBySourceId,
    tagsByTypeAndSourceId,
  };
};

export const createIsMatchingLocationFnByContext = (
  filterTagIds: string[],
  hierarchyTagContext: HierarchyTagContext,
) => {
  const { tagsById } = hierarchyTagContext;

  const filteredTypes = new Set<HierarchyType>(
    filterTagIds.map((tagId) => tagsById[tagId]?.type).filter((type) => type) as HierarchyType[],
  );
  const filterTagIdsSet = new Set(filterTagIds);

  return (entity: Partial<Record<HierarchyType, string | null>>) =>
    tagTypes.every((tagType) => {
      const tagId = entity[tagType];
      return !filteredTypes.has(tagType) || (tagId && filterTagIdsSet.has(tagId));
    });
};

export const createIsMatchingLocationFn = (
  filterTagIds: string[],
  hierarchyTags: HierarchyTagStore[],
) => {
  const hierarchyTagContext = createHierarchyTagContext(hierarchyTags);
  return createIsMatchingLocationFnByContext(filterTagIds, hierarchyTagContext);
};
