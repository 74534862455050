<template>
  <div class="flex border-r flex-col xl:flex-row pt-4 xl:pt-0">
    <div class="xl:hidden px-4">
      <select class="w-auto oai-inputs" @change="handleSelectChange">
        <option
          v-for="report in reports"
          :key="report.name"
          :value="report._id"
          :selected="selectedReport?._id === report._id"
        >
          {{ report.name }}
        </option>
      </select>
    </div>
    <div class="relative flex flex-col bg-white hidden xl:block w-[250px]">
      <div class="overflow-y-auto flex-auto">
        <div v-for="(reports, type) in reportsByType" :key="type" class="border-b">
          <Disclosure v-slot="{ open }" defaultOpen>
            <DisclosureButton
              class="flex items-center justify-between bg-gray-100 px-2 py-1 w-full border-l-4 font-medium"
              :class="[open && 'bg-gray-100', selectedReport?.type === type && '']"
            >
              <div class="flex items-center gap-2">
                <Component
                  :is="reportIconsConfig[type].icon"
                  :class="[
                    selectedReport?.type === type
                      ? reportIconsConfig[type].style
                      : 'stroke-gray-600',
                    'h-5 w-5 flex-shrink-0',
                  ]"
                />
                <small>{{ $t(`analytics.reports.${type}_plot`) }}</small>
              </div>

              <ChevronLeftIcon :class="open ? '-rotate-90 transform' : ''" class="w-4" />
            </DisclosureButton>

            <DisclosurePanel>
              <div
                v-for="report in reports"
                :class="selectedReport?._id === report._id && 'border-l-yellow text-oaiGray-400 '"
                :key="report.name"
                @click="switchToReport(report)"
                class="py-2 px-2 lg:pl-6 lg:py-4 border-l-4 cursor-pointer truncate border-t text-gray-600 text-xs break-words"
                :title="report.name"
              >
                {{ report.name }}
              </div>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronLeftIcon } from "@heroicons/vue/24/outline";
import { computed } from "vue";
import { Report, ReportPlotType } from "@/types/Report";
import reportIconsConfig from "./reportIconsConfig";

const props = defineProps<{
  reports: Report[];
  selectedReport?: Report;
}>();
const emit = defineEmits<{
  (eventName: "selectReport", report: Report): void;
}>();

const switchToReport = (report: Report) => {
  if (report && report._id !== props.selectedReport?._id) {
    emit("selectReport", report);
  }
};

const handleSelectChange = (event: Event) => {
  const reportId = (event.target as HTMLInputElement).value;
  const report = props.reports.find((f) => f._id === reportId);
  if (report) {
    switchToReport(report);
  }
};

const reportsByType = computed(() => {
  const reportsType = props.reports.reduce((acc, report) => {
    acc[report.type] = acc[report.type] || [];
    acc[report.type].push(report);
    return acc;
  }, {} as Record<ReportPlotType, Report[]>);

  const sortedKeys = Object.keys(reportsType).sort() as ReportPlotType[];
  return sortedKeys.reduce((acc: Record<ReportPlotType, Report[]>, key: ReportPlotType) => {
    acc[key] = reportsType[key];
    return acc;
  }, {} as Record<ReportPlotType, Report[]>);
});
</script>
