<template>
  <ReportFormSection number="2" :label="t('analytics.reports.filters')">
    <div class="flex gap-2 flex-col">
      <ReportLocationFilterField
        :filter="filters.location"
        :hierarchyTags="hierarchyTags"
        @change="emit('change', { filters: { ...filters, location: $event } })"
      />
      <ReportProcessFilterField
        :filter="filters.processes"
        :hierarchyTags="hierarchyTags"
        @change="emit('change', { filters: { ...filters, processes: $event } })"
      />
      <ReportDateFilterField
        :filter="filters.daterange"
        @change="emit('change', { filters: { ...filters, daterange: $event } })"
      />
    </div>
  </ReportFormSection>
  <ReportFormSection number="3" :label="t('analytics.reports.aggregation')" defaultOpen>
    <SelectList
      :options="aggregationOptions"
      :defaultSelected="aggregation"
      :onUpdate:selected="handleAggregationChange"
    />
  </ReportFormSection>

  <ReportFormSection number="4" :label="t('analytics.reports.plot_config')" defaultOpen>
    <WorkingHourCurveConfigFields
      :config="config"
      :filters="filters"
      @configChange="emit('change', { config: $event })"
    />
  </ReportFormSection>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import SelectList from "shared/components/other/OaiListbox.vue";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { Report } from "@/types/Report";
import {
  WorkingHourCurveReportConfig,
  WorkingHourCurveReportFilters,
} from "@/types/reports/PlotWorkingHourCurve";
import { WorkingHourCurveAggregation } from "@/types/reports/PlotWorkingHourCurve";
import ReportFormSection from "@/views/reports/components/ReportFormSection.vue";
import ReportDateFilterField from "@/views/reports/filters/ReportDateFilterField.vue";
import ReportLocationFilterField from "@/views/reports/filters/ReportLocationFilterField.vue";
import ReportProcessFilterField from "@/views/reports/filters/ReportProcessFilterField.vue";
import WorkingHourCurveConfigFields from "@/views/reports/plots/working_hour_curve/WorkingHourCurveConfigFields.vue";

defineProps<{
  filters: WorkingHourCurveReportFilters;
  config: WorkingHourCurveReportConfig;
  aggregation: WorkingHourCurveAggregation;
  hierarchyTags: HierarchyTagStore[];
}>();
const emit = defineEmits<{ (eventName: "change", report: Partial<Report>): void }>();

const { t } = useI18n();

const aggregationOptions = [
  { value: "day", name: t("analytics.reports.by_daily") },
  { value: "week", name: t("analytics.reports.by_weekly") },
  { value: "biweek", name: t("analytics.reports.by_biweekly") },
  { value: "month", name: t("analytics.reports.by_monthly") },
];

const handleAggregationChange = (aggregation: WorkingHourCurveAggregation) => {
  emit("change", { aggregation });
};
</script>
