import { Component as VueComponent } from "vue";
import { ReportPlotType } from "@/types/Report";
import CycleTimesIcon from "@/views/reports/components/icons/CycleTimesIcon.vue";
import MilestoneIcon from "@/views/reports/components/icons/MilestoneIcon.vue";
import QueryValueIcon from "@/views/reports/components/icons/QueryValueIcon.vue";
import UnitValueIcon from "@/views/reports/components/icons/UnitValueIcon.vue";
import WorkingHoursIcon from "@/views/reports/components/icons/WorkingHoursIcon.vue";

const reportIconsConfig: Record<ReportPlotType, { style: string; icon: VueComponent }> = {
  cycle_times: {
    style: "stroke-red-700",
    icon: CycleTimesIcon,
  },
  working_hour_curve: {
    style: "stroke-blue-600",
    icon: WorkingHoursIcon,
  },
  unit_values: {
    style: "stroke-yellow-600",
    icon: UnitValueIcon,
  },
  milestone: {
    style: "stroke-green-400",
    icon: MilestoneIcon,
  },
  query_value: {
    style: "stroke-orange-600",
    icon: QueryValueIcon,
  },
};

export default reportIconsConfig;
