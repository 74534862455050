<template>
  <div :style="{ height: height && `${height}px` }" class="flex gap-1">
    <div class="flex flex-1 gap-1 items-start min-w-0">
      <OaiTooltip simple v-if="dateFilterLabel && !hideDateFilter">
        <div
          class="bg-gray-200 rounded-md px-2 py-0.5 whitespace-nowrap truncate"
          :style="{ fontSize: '11px' }"
        >
          {{ dateFilterLabel }}
        </div>
        <template #tooltip>
          <div v-if="formattedPeriod || formattedPreviousPeriod" class="text-xs">
            <div v-if="formattedPeriod" class="whitespace-nowrap flex gap-1">
              <div :class="{ 'min-w-[130px]': formattedPreviousPeriod }">
                {{ t("analytics.reports.period") }}:
              </div>
              <div>{{ formattedPeriod }}</div>
            </div>
            <div v-if="formattedPreviousPeriod" class="whitespace-nowrap flex gap-1">
              <div class="min-w-[130px]">{{ t("analytics.reports.previous_period") }}:</div>
              <div>{{ formattedPreviousPeriod }}</div>
            </div>
          </div>
        </template>
      </OaiTooltip>
      <div
        class="bg-gray-200 rounded-md px-2 py-0.5 whitespace-nowrap truncate"
        :style="{ fontSize: '11px' }"
        v-if="aggregationLabel"
      >
        {{ aggregationLabel }}
      </div>
      <OaiTooltip simple v-if="nonEmptyFilters.length > 0 && !hideNonEmptyFilters">
        <div
          class="bg-gray-200 rounded-md px-2 py-0.5 whitespace-nowrap truncate"
          :style="{ fontSize: '11px' }"
        >
          {{ t("analytics.reports.filters") }} ({{ nonEmptyFilters.length }})
        </div>
        <template #tooltip>
          <div
            v-for="filter in nonEmptyFilters"
            :key="filter.type"
            class="whitespace-nowrap text-xs"
          >
            {{ filterLabels[filter.type] }}
          </div>
        </template>
      </OaiTooltip>
      <slot name="badges" />
    </div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import deepEqual from "deep-equal";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { defaultPlotFilters } from "@/composables/report";
import { ReportFilters, ReportPlotType, ReportSummaryContext } from "@/types/Report";
import { ReportDateFilter } from "@/types/reports/filters";

type KeysOfUnion<T> = T extends T ? keyof T : never;
type ReportFilterType = KeysOfUnion<ReportFilters>;

const dateRangeFilterKey: ReportFilterType = "daterange";
const dateFormat = "dd.MM.yyyy";

const props = defineProps<{
  type: ReportPlotType;
  filters: ReportFilters;
  aggregationLabel?: string;
  reportSummaryContext?: ReportSummaryContext;
  height?: number;
  hideDateFilter?: boolean;
  hideNonEmptyFilters?: boolean;
  showPreviousPeriodBadge?: boolean;
}>();

const { t } = useI18n();

const dateFilterLabels: Record<ReportDateFilter["type"], string> = {
  last_day: t("time.last_day"),
  last_week: t("time.last_week", 1),
  last_calendar_week: t("time.last_calendar_week"),
  last_2_weeks: t("time.last_week", 2),
  last_month: t("time.last_month", 1),
  last_3_months: t("time.last_month", 3),
  last_6_months: t("time.last_month", 6),
  last_12_months: t("time.last_month", 12),
  date_range: t("analytics.reports.absolute_date"),
  total: t("time.all_time"),
};

const filterLabels = computed<Record<ReportFilterType, string>>(() => ({
  location: t("analytics.reports.location"),
  processes:
    "processes" in props.filters && props.filters.processes.mode === "component"
      ? t("analytics.reports.preset_mode")
      : t("analytics.reports.process_mode"),
  unit_value_type: t("analytics.reports.unit_value"),
  daterange: t("buttons.time_range"),
}));

const nonEmptyFilters = computed(() => {
  const defaultFilters = defaultPlotFilters[props.type]();
  return Object.entries(props.filters)
    .filter(([key, value]) => {
      const defaultFilter = (defaultFilters as Record<string, unknown>)[key];
      return key !== dateRangeFilterKey && !deepEqual(defaultFilter, value);
    })
    .map(([key, value]) => ({
      type: key as ReportFilterType,
      filter: value,
    }));
});

const dateFilterLabel = computed(() => {
  if (dateRangeFilterKey in props.filters) {
    if (
      props.filters.daterange.type === "date_range" &&
      props.filters.daterange.start_date &&
      props.filters.daterange.end_date
    ) {
      return `${format(props.filters.daterange.start_date, dateFormat)}-${format(
        props.filters.daterange.end_date,
        dateFormat,
      )}`;
    }
    const label = dateFilterLabels[props.filters.daterange.type];

    if (props.showPreviousPeriodBadge) {
      return `${label} | ${t("analytics.reports.previous_period_label")}`;
    }

    return label;
  }
  return undefined;
});

const formatPeriod = (start: Date, end: Date) => {
  if (start.getTime() === end.getTime()) {
    return format(start, dateFormat);
  }
  return `${format(start, dateFormat)}-${format(end, dateFormat)}`;
};

const formattedPeriod = computed(() => {
  const context = props.reportSummaryContext;
  if (!context || !context.start_date || !context.end_date) {
    return null;
  }
  return formatPeriod(context.start_date, context.end_date);
});

const formattedPreviousPeriod = computed(() => {
  const context = props.reportSummaryContext;
  if (!context || !context.previous_period_start_date || !context.previous_period_end_date) {
    return null;
  }
  return formatPeriod(context.previous_period_start_date, context.previous_period_end_date);
});
</script>
