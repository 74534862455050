<template>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1498.48 1498.48">
    <path class="cls-1" d="M192.99,226.85v1067.03c0,49.86,40.61,90.28,90.71,90.28h1072.02" />
    <line class="cls-1" x1="551.17" y1="1146.65" x2="551.17" y2="767.13" />
    <line class="cls-1" x1="551.17" y1="556.31" x2="551.17" y2="456.97" />
    <line class="cls-1" x1="801.4" y1="1146.65" x2="801.4" y2="629.59" />
    <line class="cls-1" x1="801.4" y1="428.95" x2="801.4" y2="291.41" />
    <line class="cls-1" x1="1059.04" y1="1146.65" x2="1059.04" y2="1037.12" />
    <line class="cls-1" x1="1059.04" y1="847.95" x2="1059.04" y2="556.31" />
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped>
.cls-1 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 150px;
}
</style>
