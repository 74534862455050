import { calculateDelta } from "shared/views/critical_path/criticalPath";
import { QueryValueContext, QueryValueSingleValue } from "@/types/reports/PlotQueryValue";

const calculateDeltaPlannerMetric = (context: QueryValueContext): QueryValueSingleValue | null => {
  const { criticalPath, planConfig, hierarchyTags, projectDurationSettings } = context;
  if (!criticalPath || !planConfig || hierarchyTags.length === 0 || !projectDurationSettings) {
    return null;
  }
  const delta = calculateDelta(criticalPath, planConfig, hierarchyTags, projectDurationSettings);
  return {
    value: delta,
    previous_period_value: null,
  };
};

export default calculateDeltaPlannerMetric;
