<template>
  <OaiTooltip
    v-if="delta !== null"
    :cls="[
      cls,
      'text-xs px-2 py-0.5 rounded-full whitespace-nowrap cursor-pointer',
      lastFinishedClass,
    ]"
    :style="style"
    @click="emit('click')"
  >
    {{ delta }} {{ t("working_day.working_day_abbrev") }}
    <template #tooltip>
      <div class="text-xs">
        <div class="flex gap-1" v-if="deltaForNode.plannedEvent">
          <div class="w-[130px]">{{ t("analytics.critical_path.planned_end") }}:</div>
          <div>{{ format(deltaForNode.plannedEvent.end, dateFormat) }}</div>
        </div>
        <div class="flex gap-1" v-if="deltaForNode.endDate">
          <div class="w-[130px]">
            {{
              deltaForNode.projectedEndDate
                ? t("analytics.critical_path.projected_end")
                : t("analytics.critical_path.actual_end")
            }}:
          </div>
          <div>
            {{ format(deltaForNode.endDate, dateFormat) }}
          </div>
        </div>
        <div class="flex gap-1">
          <div class="w-[130px]">{{ t("working_day.working_day") }}:</div>
          <div>
            {{ delta }}
          </div>
        </div>
      </div>
    </template>
  </OaiTooltip>
</template>

<script setup lang="ts">
import { format } from "date-fns";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { CriticalPathDelta } from "shared/types/CriticalPath";
import { useDeltaTextClass, useDeltaTextWithBackgroundClass } from "../composables";

const dateFormat = "dd.MM.yyyy";

const props = defineProps<{
  deltaForNode: CriticalPathDelta;
  cls?: string;
  noBackgroundHighlight?: boolean;
  style?: string | Record<string, string | number>;
}>();

const emit = defineEmits<{ (eventName: "click"): void }>();

const { t } = useI18n();

const delta = computed(() => props.deltaForNode.workingDays);

const lastFinishedTextClass = useDeltaTextClass(delta);
const lastFinishedTextWithBackgroundClass = useDeltaTextWithBackgroundClass(delta);

const lastFinishedClass = computed(() =>
  props.noBackgroundHighlight
    ? lastFinishedTextClass.value
    : lastFinishedTextWithBackgroundClass.value,
);
</script>
