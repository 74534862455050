import {
  CriticalPath,
  CriticalPathContext,
  CriticalPathEdgesBySourceAndTargetId,
  CriticalPathEx,
  CriticalPathNode,
  CriticalPathNodeEx,
} from "shared/types/CriticalPath";
import { HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";
import { PlanConfig } from "shared/types/Plan";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import { createPlanContext } from "../planner/planner";
import * as criticalPathDelta from "./criticalPathDelta";
import { createCriticalPathEx } from "./criticalPathNode";

export const createBuildingIds = (
  criticalPath: CriticalPath,
  hierarchyTags: HierarchyTagStore[],
) => {
  const nodeBuildingIds = new Set((criticalPath?.nodes || []).map((node) => node.building_id));
  const ids = hierarchyTags
    .filter((tag) => tag.type === "building" && nodeBuildingIds.has(tag._id))
    .map((tag) => tag._id);
  return [...(nodeBuildingIds.has(null) ? [null] : []), ...ids];
};

export const createLevels = (
  criticalPath: CriticalPath,
  hierarchyTags: HierarchyTagStore[],
  buildingIds: (string | null)[],
) => {
  const buildingIdSet = new Set(buildingIds);
  const nodes = criticalPath.nodes.filter((node) => buildingIdSet.has(node.building_id));
  const nodeLevelIds = new Set(nodes.map((node) => node.level_id));
  const buildingIdsByLevel = nodes.reduce((acc, node) => {
    if (!buildingIdSet.has(node.building_id)) {
      return acc;
    }
    if (!acc[node.level_id]) {
      acc[node.level_id] = new Set();
    }
    acc[node.level_id].add(node.building_id);
    return acc;
  }, {} as Record<string, Set<string | null>>);

  const levels = hierarchyTags.filter((tag) => tag.type === "level" && nodeLevelIds.has(tag._id));
  levels.sort((a, b) => b.number - a.number);
  return levels.map((level) => {
    const buildingIdsForLevel = buildingIdsByLevel[level._id];
    return {
      level,
      shared:
        buildingIdsForLevel.size === 1 && buildingIdsForLevel.has(null) && buildingIdSet.size > 1,
    };
  });
};

const traverseEdges = (
  edgesBySourceAndTargetId: CriticalPathEdgesBySourceAndTargetId,
  selectedNodeIds: string[],
  context: CriticalPathContext,
  direction: "both" | "up" | "down",
  lastNodeId: string | null,
): { edgeIds: Set<string>; nodeIds: Set<string> } => {
  const { edgesBySourceId, edgesByTargetId } = edgesBySourceAndTargetId;
  const edgeIds = new Set<string>();
  const nodeIds = new Set<string>();
  for (const nodeId of selectedNodeIds) {
    nodeIds.add(nodeId);

    if (direction === "up" || direction === "both") {
      const targetIds = edgesBySourceId[nodeId] || [];
      for (const targetId of targetIds) {
        const traverseResult = traverseEdges(
          edgesBySourceAndTargetId,
          [targetId],
          context,
          "up",
          lastNodeId,
        );
        if (!lastNodeId || traverseResult.nodeIds.has(lastNodeId)) {
          traverseResult.nodeIds.forEach((nodeId) => nodeIds.add(nodeId));
          traverseResult.edgeIds.forEach((edgeId) => edgeIds.add(edgeId));
          nodeIds.add(targetId);
          edgeIds.add(`${nodeId}_${targetId}`);
        }
      }
    }
    if (direction === "down" || direction === "both") {
      const sourceIds = edgesByTargetId[nodeId] || [];
      sourceIds.forEach((sourceId) => {
        nodeIds.add(sourceId);
        edgeIds.add(`${sourceId}_${nodeId}`);
      });
      const traverseResult = traverseEdges(
        edgesBySourceAndTargetId,
        sourceIds,
        context,
        "down",
        lastNodeId,
      );
      traverseResult.nodeIds.forEach((nodeId) => nodeIds.add(nodeId));
      traverseResult.edgeIds.forEach((edgeId) => edgeIds.add(edgeId));
    }
  }
  return { edgeIds, nodeIds };
};

export const getCriticalPathEdgeIds = (
  criticalPath: CriticalPath,
  nodeIds: string[],
  lastNodeId: string | null,
  context: CriticalPathContext,
) => {
  const edgesBySourceAndTargetId = getEdgesBySourceAndTargetId(criticalPath);
  return traverseEdges(edgesBySourceAndTargetId, nodeIds, context, "both", lastNodeId);
};

export const getEdgesBySourceAndTargetId = (
  criticalPath: CriticalPath,
): CriticalPathEdgesBySourceAndTargetId => {
  const edgesBySourceId = criticalPath.edges.reduce((acc, edge) => {
    if (!acc[edge.source_id]) {
      acc[edge.source_id] = [];
    }
    acc[edge.source_id].push(edge.target_id);
    return acc;
  }, {} as Record<string, string[]>);

  const edgesByTargetId = criticalPath.edges.reduce((acc, edge) => {
    if (!acc[edge.target_id]) {
      acc[edge.target_id] = [];
    }
    acc[edge.target_id].push(edge.source_id);
    return acc;
  }, {} as Record<string, string[]>);

  return {
    edgesBySourceId,
    edgesByTargetId,
  };
};

export const getCriticalPathContext = (
  criticalPath: CriticalPathEx,
  planConfig: PlanConfig,
  hierarchyTags: HierarchyTagStore[],
  projectDurationSettings: ProjectDurationSettings,
): CriticalPathContext => {
  const criticalPathNodesById = criticalPath.nodes.reduce((acc, node) => {
    acc[node._id] = node;
    return acc;
  }, {} as Record<string, CriticalPathNodeEx | undefined>);

  const criticalPathNodeLocationById = criticalPath.nodes.reduce((acc, node) => {
    acc[node._id] = {
      building: node.building_id,
      level: node.level_id,
      section: node.section_id,
    };
    return acc;
  }, {} as Record<string, Partial<Record<HierarchyType, string | null>> | undefined>);

  return {
    ...createPlanContext(planConfig, hierarchyTags),
    projectDurationSettings,
    criticalPathNodesById,
    criticalPathNodeLocationById,
  };
};

export const getCriticalPathContextOrNull = (
  criticalPath: CriticalPathEx | null,
  planConfig: PlanConfig | undefined,
  hierarchyTags: HierarchyTagStore[],
  projectDurationSettings: ProjectDurationSettings | undefined,
): CriticalPathContext | null => {
  if (!criticalPath || !planConfig || hierarchyTags.length === 0 || !projectDurationSettings) {
    return null;
  }
  return getCriticalPathContext(criticalPath, planConfig, hierarchyTags, projectDurationSettings);
};

export const calculateIsLastInCriticalPath = (
  node: CriticalPathNode,
  edgesBySourceAndTargetId: CriticalPathEdgesBySourceAndTargetId,
) => {
  const { edgesBySourceId } = edgesBySourceAndTargetId;
  const childNodeIds = edgesBySourceId[node._id] || [];
  return childNodeIds.length === 0;
};

export const calculateDelta = (
  criticalPath: CriticalPath,
  planConfig: PlanConfig,
  hierarchyTags: HierarchyTagStore[],
  projectDurationSettings: ProjectDurationSettings,
) => {
  const criticalPathEx = createCriticalPathEx(criticalPath, planConfig, hierarchyTags);
  const context = getCriticalPathContext(
    criticalPathEx,
    planConfig,
    hierarchyTags,
    projectDurationSettings,
  );
  const pathDelta = criticalPathDelta.calculateDelta(criticalPathEx, context);
  return pathDelta?.delta.workingDays ?? null;
};
