import { AxiosResponse } from "axios";
import { parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { apiClient } from "shared/repositories/clients";
import {
  Project,
  ProjectThumbnailUrl,
  SimplifiedProject,
  ProjectRequest,
  ProjectExtendedData,
  ProjectWeather,
  ProjectThumbnailAll,
  ProjectStatus,
} from "shared/types/Project";

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

const mapSimplifiedProject = (project: SimplifiedProject<string>): SimplifiedProject => ({
  ...project,
  planned_start: parseUtcDate(project.planned_start),
  planned_end:
    project.planned_end === null ? project.planned_end : parseUtcDate(project.planned_end),
});

const mapProject = (project: Project<string>): Project => ({
  ...project,
  planned_start: parseUtcDate(project.planned_start),
  planned_end:
    project.planned_end === null ? project.planned_end : parseUtcDate(project.planned_end),
});

const loadProjectsForUser = (): Promise<SimplifiedProject[]> =>
  apiClient
    .get<void, AxiosResponse<SimplifiedProject<string>[]>>(`/project/`)
    .then((response) => response.data.map((project) => mapSimplifiedProject(project)));

const loadThumbnailUrls = (
  page: number,
  pageSize: number,
  projectStatus: ProjectStatus | null,
): Promise<ProjectThumbnailUrl[]> => {
  const query = new URLSearchParams();
  query.set("page", page.toString());
  query.set("page_size", pageSize.toString());
  if (projectStatus) {
    query.set("project_status", projectStatus);
  }
  return apiClient
    .get<void, AxiosResponse<ProjectThumbnailUrl[]>>(`/project/thumbnail-urls?${query.toString()}`)
    .then((response) => response.data);
};

const loadThumbnailUrlsAll = (
  page: number,
  pageSize: number,
  projectStatus: ProjectStatus | null,
): Promise<ProjectThumbnailAll[]> => {
  const query = new URLSearchParams();
  query.set("page", page.toString());
  query.set("page_size", pageSize.toString());
  if (projectStatus) {
    query.set("project_status", projectStatus);
  }
  return apiClient
    .get<void, AxiosResponse<ProjectThumbnailAll[]>>(
      `/project/thumbnail-urls/all?${query.toString()}`,
    )
    .then((response) => response.data);
};

const loadWeatherForProjects = (): Promise<ProjectWeather[]> =>
  apiClient
    .get<void, AxiosResponse<ProjectWeather[]>>(`/project/weather`)
    .then((response) => response.data);

const loadExtendedDataForProjects = (): Promise<ProjectExtendedData[]> =>
  apiClient
    .get<void, AxiosResponse<ProjectExtendedData[]>>(`/project/extended`)
    .then((response) => response.data);

const loadProject = (customerName: string, siteId: string): Promise<Project> =>
  apiClient
    .get<void, AxiosResponse<Project<string>>>(`/project/${customerName}/${siteId}`)
    .then((response) => mapProject(response.data));

export type ProjectToUpdateAsProjectAdmin = Partial<Pick<Project, "name" | "street">>;

const updateProjectAsProjectAdmin = (
  customerName: string,
  siteId: string,
  projectToUpdate: ProjectToUpdateAsProjectAdmin,
): Promise<Project> =>
  apiClient
    .patch<void, AxiosResponse<Project<string>>>(
      `/project/${customerName}/${siteId}/project-admin`,
      projectToUpdate,
    )
    .then((response) => mapProject(response.data));

const loadLogoUrl = (customerName: string, siteId: string): Promise<string | null> => {
  return apiClient
    .get<void, AxiosResponse<{ url: string }>>(`/project/${customerName}/${siteId}/logo`)
    .then((response) => response.data.url)
    .catch((error) => {
      if (error?.response?.status === 404) {
        return null;
      }
      throw error;
    });
};

const deleteLogo = (customerName: string, siteId: string): Promise<void> => {
  return apiClient
    .delete(`/project/${customerName}/${siteId}/logo`)
    .then((response) => response.data);
};

const uploadLogo = (customerName: string, siteId: string, fileString: string): Promise<string> => {
  return apiClient
    .post<void, AxiosResponse<{ url: string }>>(`/project/${customerName}/${siteId}/logo`, {
      file: fileString,
    })
    .then((response) => response.data.url);
};

const requestProject = (payload: ProjectRequest): Promise<{ status: string }> => {
  return apiClient
    .post<void, AxiosResponse<{ status: string }>>(`/project/request`, payload)
    .then((response) => response.data);
};

export default {
  loadProjectsForUser,
  loadProject,
  updateProjectAsProjectAdmin,
  loadLogoUrl,
  deleteLogo,
  uploadLogo,
  loadThumbnailUrls,
  loadThumbnailUrlsAll,
  loadWeatherForProjects,
  loadExtendedDataForProjects,
  requestProject,
};
