<template>
  <div>
    <Popover v-slot="{ open }" class="relative">
      <PopoverButton @click="handlePopoverClick(open)" class="outline-none">
        <InformationCircleIcon v-if="open" class="h-6 w-6 text-yellow ring-0" />
        <InformationCircleIconOutlined v-else class="h-6 w-6 text-yellow ring-0" />
      </PopoverButton>
      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          class="absolute right-0 z-10 mt-3 w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl"
        >
          <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-gray-300 text-sm">
            <div class="relative grid gap-4 bg-white p-7">
              <div>
                <p class="flex gap-1 items-center">
                  <span class="text-[#BCCFCC] text-[8px]">●</span>
                  <span>{{ t("analytics.reports.planned_title") }}</span>
                </p>
                <p class="text-gray mt-1 text-xs">
                  {{ t("analytics.reports.planned_description") }}
                </p>
              </div>
              <div>
                <p class="flex gap-1 items-center">
                  <span class="text-[#336260] text-[8px]">●</span>
                  <span>{{ t("analytics.reports.active_title") }} </span>
                </p>
                <p class="text-gray mt-1 text-xs">
                  {{ t("analytics.reports.active_description") }}
                </p>
              </div>
              <div>
                <p class="flex gap-1 items-center">
                  <span class="text-[#991B1B] text-[8px]">●</span>
                  <span>{{ t("analytics.reports.inactive_title") }}</span>
                </p>
                <p class="text-gray mt-1 text-xs">
                  {{ t("analytics.reports.inactive_description") }}
                </p>
              </div>
              <div>
                <p class="flex gap-1 items-center">
                  <span class="text-[#FCA5A5] text-[8px]">●</span>
                  <span>{{ t("analytics.reports.outages_title") }}</span>
                </p>
                <p class="text-gray mt-1 text-xs">
                  {{ t("analytics.reports.outages_description") }}
                </p>
              </div>
              <div>
                <p class="flex gap-1 items-center">
                  <span class="text-[#E7BC66] text-[8px]">●</span>
                  <span>{{ t("analytics.reports.utilization_title") }}</span>
                </p>
                <p class="text-gray mt-1 text-xs">
                  {{ t("analytics.reports.utilization_description") }}
                </p>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { InformationCircleIcon as InformationCircleIconOutlined } from "@heroicons/vue/24/outline";
import { InformationCircleIcon } from "@heroicons/vue/24/solid";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const emits = defineEmits(["reportInfoClick"]);

const handlePopoverClick = (isClosed: boolean) => {
  if (!isClosed) {
    emits("reportInfoClick");
  }
};
</script>
