import { useQuery } from "@tanstack/vue-query";
import SectionMasksRepository from "../repositories/SectionMasksRepository";
import logger from "../services/logger";
import { SectionMask } from "../types/SectionMask";
import { useCurrentCustomerName, useCurrentSiteId, useHasPermission } from "./project";
import { useCustomToast } from "./toast";

export const useMostRecentSectionMasks = (
  customerName: string,
  siteId: string,
  cameraId: string,
  date: string,
) => {
  const { data, isLoading } = useQuery<{ [key: string]: SectionMask[] }>({
    queryKey: ["section-masks-most-recent", customerName, siteId, cameraId, date],
    queryFn: () =>
      SectionMasksRepository.loadMostRecentSectionMasks(customerName, siteId, cameraId, date),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load most recent section masks");
      return false;
    },
  });

  return { sectionMasks: data, areSectionMasksLoading: isLoading };
};

export const useSectionMasks = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const hasPermission = useHasPermission("processes_user");

  const {
    data: sectionMasks,
    isLoading,
    error,
  } = useQuery<SectionMask[]>({
    queryKey: ["section-masks", customerName, siteId],
    queryFn: () => {
      if (hasPermission) {
        return SectionMasksRepository.loadSectionMasks(customerName, siteId);
      }
      return [];
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error("Unable to load section masks");
      return false;
    },
  });

  return { sectionMasks, isLoading, error };
};
