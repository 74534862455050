import { toRaw } from "vue";
import { HierarchyTagContext, HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";
import { PlanConfig, PlannerItem } from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { createHierarchyTagContext, tagTypes } from "shared/views/planner/hierarchyTags";

export type TagGroupedProcess = {
  _id: string;
  tags: Record<HierarchyType, string>;
  tag_ids: Record<HierarchyType, string>;
  processes: ShortenedProcessWithTags[];
};

export type TagGroupedProcessResponse = {
  data: TagGroupedProcess[];
  levelOrders: Record<string, number>;
};

const getLevelOrders = (hierarchyTags: HierarchyTagStore[]) =>
  hierarchyTags
    .filter((tag) => tag.type === "level")
    .reduce((acc, tag) => {
      acc[tag.name] = tag.number;
      return acc;
    }, {} as Record<string, number>);

const getTags = (sourceId: string, hierarchyTagContext: HierarchyTagContext) =>
  tagTypes
    .map((type) => hierarchyTagContext.tagsByTypeAndSourceId[`${type}_${sourceId}`])
    .filter((tag) => tag) as HierarchyTagStore[];

const getData = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[],
  hierarchyTags: HierarchyTagStore[],
) => {
  const hierarchyTagContext = createHierarchyTagContext(hierarchyTags);

  const plannerItemsBySourceId = planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem.source_id] = plannerItem;
    return acc;
  }, {} as Record<string, PlannerItem>);

  const groupsMap = processes.reduce((acc, process) => {
    const sourceId = process.planner_item_mapping.source_id;
    if (!sourceId) {
      return acc;
    }
    if (!acc[sourceId]) {
      const tags = getTags(sourceId, hierarchyTagContext);
      acc[sourceId] = {
        _id: sourceId,
        tags: tags.reduce((acc, tag) => {
          acc[tag.type] = tag.name;
          return acc;
        }, {} as Record<HierarchyType, string>),
        tag_ids: tags.reduce((acc, tag) => {
          acc[tag.type] = tag._id;
          return acc;
        }, {} as Record<HierarchyType, string>),
        processes: [],
      };
    }
    acc[sourceId].processes.push(toRaw(process));
    return acc;
  }, {} as Record<string, TagGroupedProcess>);
  const groups = Object.values(groupsMap).filter((group) => group.tag_ids.component);
  groups.sort(
    (a, b) =>
      (plannerItemsBySourceId[a._id]?.order || 0) - (plannerItemsBySourceId[b._id]?.order || 0),
  );
  return groups;
};

const group = (
  planConfig: PlanConfig | undefined,
  processes: ShortenedProcessWithTags[] | undefined,
  hierarchyTags: HierarchyTagStore[],
): TagGroupedProcessResponse | null => {
  if (!planConfig || !processes || hierarchyTags.length === 0) {
    return null;
  }
  return {
    data: getData(planConfig, processes, hierarchyTags),
    levelOrders: getLevelOrders(hierarchyTags),
  };
};

export default group;
