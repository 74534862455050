import {
  addWeeks,
  format,
  startOfDay,
  startOfISOWeek,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import { HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { createIsMatchingLocationFn } from "shared/views/planner/hierarchyTags";
import { WorkingHourCurveReportFilters } from "@/types/reports/PlotWorkingHourCurve";
import {
  ReportDateFilter,
  ReportLocationFilter,
  ReportProcessFilter,
} from "@/types/reports/filters";

export const getStartEndDate = (dateFilter: ReportDateFilter) => {
  const now = startOfDay(new Date());

  if (dateFilter.type === "total") {
    return { startDate: null, endDate: null };
  }

  if (dateFilter.type === "date_range") {
    return { startDate: dateFilter.start_date as Date, endDate: dateFilter.end_date as Date };
  }

  if (dateFilter.type === "last_calendar_week") {
    const start = startOfISOWeek(subWeeks(now, 1));
    const end = addWeeks(start, 1);
    return { startDate: start, endDate: end };
  }

  const endDate = subDays(now, 1);

  const rangeMap = {
    last_day: subDays(now, 1),
    last_week: subWeeks(now, 1),
    last_2_weeks: subWeeks(now, 2),
    last_month: subMonths(now, 1),
    last_3_months: subMonths(now, 3),
    last_6_months: subMonths(now, 6),
    last_12_months: subMonths(now, 12),
  };

  return { startDate: rangeMap[dateFilter.type], endDate };
};

export const getStartEndDateFromProcesses = (processes: ShortenedProcessWithTags[] | undefined) => {
  if (!processes || processes.length === 0) {
    return { startDate: null, endDate: null };
  }

  const startDate = startOfDay(processes[0].start_time);
  const endDate = startOfDay(processes[processes.length - 1].start_time);

  return { startDate, endDate };
};

export const getPreviousPeriodStartEndDate = (dateFilter: ReportDateFilter) => {
  const { startDate, endDate } = getStartEndDate(dateFilter);

  if (!startDate || !endDate) {
    return { startDate: null, endDate: null };
  }

  const now = startOfDay(new Date());

  const newStartDate =
    dateFilter.type === "last_calendar_week"
      ? startOfISOWeek(subWeeks(now, 2))
      : subDays(new Date(startDate.getTime() - (endDate.getTime() - startDate.getTime())), 1);

  const newEndDate = subDays(startDate, 1);

  return { startDate: newStartDate, endDate: newEndDate };
};

const getProcessEncodedLabels = (processFilter: WorkingHourCurveReportFilters["processes"]) => {
  if (processFilter.mode === "single_process") {
    return processFilter.single_processes;
  }
  return processFilter.components.flatMap((sublist) => sublist.processes);
};

export const filterProcesses = (
  processes: ShortenedProcessWithTags[],
  hierarchyTags: HierarchyTagStore[],
  filter: {
    location?: ReportLocationFilter;
    processes?: ReportProcessFilter;
    daterange?: ReportDateFilter;
  },
) => {
  const isMatchingLocation = filter.location
    ? createIsMatchingLocationFn(filter.location, hierarchyTags)
    : null;
  const trueEncodedLabels = filter.processes ? getProcessEncodedLabels(filter.processes) : [];
  const startEndDate = filter.daterange ? getStartEndDate(filter.daterange) : null;

  const startDate = startEndDate?.startDate ? format(startEndDate.startDate, "yyyy-MM-dd") : null;
  const endDate = startEndDate?.endDate ? format(startEndDate.endDate, "yyyy-MM-dd") : null;

  return processes.filter((process) => {
    const hasMatchingEncodedLabels = trueEncodedLabels.length
      ? trueEncodedLabels.includes(process.encoded_label)
      : true;
    const isMatchingDate =
      startDate && endDate
        ? startDate.localeCompare(process.date) <= 0 && endDate.localeCompare(process.date) >= 0
        : true;
    const location: Partial<Record<HierarchyType, string | null>> = {
      building: process.planner_item_mapping.building_id,
      level: process.planner_item_mapping.level_id,
      section: process.planner_item_mapping.section_id,
    };
    return (
      hasMatchingEncodedLabels &&
      isMatchingDate &&
      (!isMatchingLocation || isMatchingLocation(location))
    );
  });
};
