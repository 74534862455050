<template>
  <div class="flex flex-1 min-w-0">
    <Menu as="div" class="flex relative min-w-0" id="dashboard-menu">
      <MenuButton
        type="button"
        class="flex-1 flex items-center font-semibold text-gray-700 max-w-[300px] min-w-0 outline-none"
        @click="resetNameEditing"
      >
        <div class="truncate">
          {{ currentDashboard?.name || t("dashboard.default_dashboard") }}
        </div>
        <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400 shrink-0" />
      </MenuButton>
      <MenuItems
        class="absolute top-6 z-50 mt-3 origin-top-right rounded-md bg-white shadow-lg focus:outline-none flex flex-col gap-3 py-2 w-[300px] lg:w-[350px] overflow-auto dashboardMenuItems"
        :style="{ left: '-2px' }"
      >
        <div v-for="category in categories" :key="category.type">
          <div
            class="text-gray-600 font-semibold px-4 pt-2 pb-1 mr-4 flex items-center justify-between gap-2"
          >
            <div class="truncate">
              {{ t(`dashboard.menu_categories.${category.type}`) }}
            </div>
            <MenuItem
              v-if="category.canAdd"
              @click="dashboardNameModalType = category.type"
              :style="{ marginRight: '-2px' }"
            >
              <PlusIcon class="w-5 h-5 cursor-pointer shrink-0 outline-none" />
            </MenuItem>
          </div>
          <MenuItem
            v-slot="{ active }"
            v-for="dashboard in category.dashboards"
            :key="dashboard._id"
            @click="handleMenuItemClick($event, dashboard)"
          >
            <div
              class="px-4 py-2 text-sm truncate cursor-pointer flex items-center gap-4 mx-4 rounded"
              :class="{
                'bg-gray-100': active,
                'bg-yellow-300': currentDashboard?._id === dashboard._id,
              }"
            >
              <div
                class="flex-1 flex gap-2 min-w-0"
                :class="
                  active && currentDashboard?._id !== dashboard._id
                    ? 'text-yellow-600'
                    : 'text-gray-700'
                "
              >
                <div class="truncate" v-if="nameEditingDashboard?._id !== dashboard._id">
                  {{ dashboard.name }}
                </div>
                <input
                  ref="nameEditingInputs"
                  v-else
                  type="text"
                  autocomplete="one-time-code"
                  v-model="nameEditingValue"
                  @keydown.stop
                  @keyup.enter="handleConfirmNameClick"
                  @keyup.esc="resetNameEditing"
                  @click.stop
                  class="-mx-1 -my-1 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 sm:text-sm sm:leading-6"
                />
                <div
                  v-if="dashboard.default && nameEditingDashboard?._id !== dashboard._id"
                  class="bg-yellow-400 px-1 py-0.5 text-xs rounded text-white"
                >
                  default
                </div>
              </div>
              <div class="flex gap-2 shrink-0" data-oai-type="actionButton">
                <PencilIcon
                  class="w-4 h-4 cursor-pointer text-gray-600 hover:text-yellow-600"
                  v-if="
                    (active || dashboard._id === currentDashboard?._id) &&
                    canRenameDashboard(dashboard) &&
                    !nameEditingDashboard &&
                    !isDashboardCreatingOrUpdating
                  "
                  @click.stop="handleEditNameClick(dashboard)"
                />
                <CheckIcon
                  class="w-5 h-5 cursor-pointer text-gray-600 hover:text-yellow-600"
                  v-if="
                    nameEditingDashboard?._id === dashboard._id && !isDashboardCreatingOrUpdating
                  "
                  @click.stop="handleConfirmNameClick"
                />
                <XMarkIcon
                  class="w-5 h-5 cursor-pointer text-gray-600 hover:text-yellow-600"
                  v-if="
                    nameEditingDashboard?._id === dashboard._id && !isDashboardCreatingOrUpdating
                  "
                  @click.stop="resetNameEditing"
                />
                <LoadingSpinner
                  size="w-5 h-5"
                  v-if="
                    nameEditingDashboard?._id === dashboard._id && isDashboardCreatingOrUpdating
                  "
                />
                <OaiTooltip
                  position="right"
                  v-if="
                    (active || dashboard._id === currentDashboard?._id) &&
                    !nameEditingDashboard &&
                    canEdit &&
                    hasPermissionToEditDashboard(dashboard) &&
                    dashboard._id === currentDashboard?._id &&
                    !isDashboardCreatingOrUpdating &&
                    !isDashboardDeleting
                  "
                >
                  <ArrowUturnLeftIcon
                    class="w-4 h-4 shrink-0 cursor-pointer hover:text-yellow-600"
                    @click="emit('resetCurrentDashboard')"
                  />
                  <template #tooltip>
                    <div>{{ t("dashboard.reset_dashboard") }}</div>
                  </template>
                </OaiTooltip>
                <TrashIcon
                  v-if="
                    (active || dashboard._id === currentDashboard?._id) &&
                    canDeleteDashboard(dashboard) &&
                    nameEditingDashboard?._id !== dashboard._id
                  "
                  @click="handleDeleteDashboardClick(dashboard)"
                  class="w-4 h-4 cursor-pointer"
                  :class="
                    isDashboardDeleting ? 'text-gray-300' : 'text-gray-600 hover:text-yellow-600'
                  "
                />
              </div>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </Menu>
    {{ dashboardNameModalType }}
  </div>
  <DashboardNameModal
    v-if="dashboardNameModalType"
    @close="dashboardNameModalType = null"
    :dashboardNameModalType="dashboardNameModalType"
  />
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ChevronDownIcon,
  PlusIcon,
  CheckIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/vue/24/outline";
import { TrashIcon, PencilIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import { computed, nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useConfirmationModal } from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import { useCreateOrUpdateDashboard, useDeleteDashboard } from "@/composables/dashboard";
import { Dashboard, DashboardType } from "@/types/Dashboard";
import DashboardNameModal from "@/views/dashboard/componentsV2/DashboardNameModal.vue";
import { useHasPermissionToEditDashboard } from "@/views/dashboard/composables";
import { createDashboardMenuCategories } from "@/views/dashboard/services/projectOverviewV2Layout";

const props = defineProps<{
  currentDashboard?: Dashboard;
  dashboards: Dashboard[];
  canEdit: boolean;
}>();
const emit = defineEmits<{
  (eventName: "selectDashboard", dashboard: Dashboard): void;
  (eventName: "showAddWidgetsSidebar", open: boolean): void;
  (eventName: "resetCurrentDashboard"): void;
  (eventName: "refresh"): void;
}>();

const { t } = useI18n();

const showConfirmationModal = useConfirmationModal();
const { deleteDashboard, isLoading: isDashboardDeleting } = useDeleteDashboard();
const { createOrUpdateDashboard, isLoading: isDashboardCreatingOrUpdating } =
  useCreateOrUpdateDashboard();
const hasPermissionToEditDashboard = useHasPermissionToEditDashboard();

const dashboardNameModalType = ref<DashboardType | null>(null);
const nameEditingDashboard = ref<Dashboard | null>(null);
const nameEditingValue = ref("");
const nameEditingInputs = ref<HTMLInputElement[] | null>(null);

const categories = computed(() =>
  createDashboardMenuCategories(props.dashboards).map((category) => ({
    ...category,
    canAdd: props.canEdit && hasPermissionToEditDashboard(category),
  })),
);

const trackEvent = useTrackEvent();

const handleDeleteDashboardClick = (dashboard: Dashboard) => {
  if (isDashboardDeleting.value) {
    return;
  }
  showConfirmationModal({
    confirmAction: t("dashboard.delete_confirmation.confirm"),
    cancelAction: t("dashboard.delete_confirmation.cancel"),
    message: t("dashboard.delete_confirmation.message", { name: dashboard.name }),
    header: t("dashboard.delete_confirmation.header"),
  }).then((confirmed) => {
    if (confirmed) {
      // have to catch the error here, otherwise it's propagated as an unhandled error
      // it's already logged by vue-query
      deleteDashboard(dashboard._id).catch(() => undefined);
    }
  });
  trackEvent("dashboard_delete", {
    type: dashboard.type,
  });
};

const canDeleteDashboard = (dashboard: Dashboard) =>
  hasPermissionToEditDashboard(dashboard) && !dashboard.default;

const canRenameDashboard = (dashboard: Dashboard) =>
  hasPermissionToEditDashboard(dashboard) && !dashboard.generated;

const eventIsFromActionButton = (element: HTMLElement): boolean => {
  if (element.dataset.oaiType === "actionButton") {
    return true;
  }
  if (element.parentElement) {
    return eventIsFromActionButton(element.parentElement);
  }
  return false;
};

const handleMenuItemClick = (event: Event, dashboard: Dashboard) => {
  const source = event.target as HTMLElement;
  if (eventIsFromActionButton(source)) {
    return;
  }
  emit("selectDashboard", dashboard);
};

const resetNameEditing = () => {
  nameEditingDashboard.value = null;
  nameEditingValue.value = "";
};

const handleEditNameClick = (dashboard: Dashboard) => {
  nameEditingDashboard.value = dashboard;
  nameEditingValue.value = dashboard.name;
  nextTick(() => {
    if (nameEditingInputs.value) {
      nameEditingInputs.value[0]?.focus();
    }
  });
};

const handleConfirmNameClick = () => {
  if (!nameEditingDashboard.value || !nameEditingValue.value) {
    return;
  }
  createOrUpdateDashboard({
    ...nameEditingDashboard.value,
    name: nameEditingValue.value,
    version: nameEditingDashboard.value.version + 1,
  })
    .then(() => {
      resetNameEditing();
    })
    // have to catch the error here, otherwise it's propagated as an unhandled error
    // it's already logged by vue-query
    .catch(() => undefined);
  trackEvent("dashboard_rename_apply", { type: nameEditingDashboard.value.type });
};

watch(
  () => props.dashboards,
  () => {
    resetNameEditing();
  },
);
</script>

<style scoped>
.dashboardMenuItems {
  max-height: min(700px, calc(100vh - 120px));
}
</style>
