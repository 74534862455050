import {
  CriticalPathContext,
  CriticalPathDelta,
  CriticalPathEx,
  CriticalPathNode,
} from "shared/types/CriticalPath";
import {
  calculatePathDeltas,
  calculateDeltaByPaths,
} from "shared/views/critical_path/criticalPathDelta";
import { createIsMatchingLocationFnByContext } from "shared/views/planner/hierarchyTags";
import { MilestonePlotGroup } from "@/views/reports/plots/milestone/types";

const getSortedNodeDeltas = (nodeDeltas: CriticalPathDelta[]) => {
  const sortedNodeDeltas = nodeDeltas.slice();
  sortedNodeDeltas.sort((a, b) => {
    if (!a.plannedEvent) {
      return 1;
    }
    if (!b.plannedEvent) {
      return -1;
    }
    return a.plannedEvent.end.getTime() - b.plannedEvent.end.getTime();
  });
  return sortedNodeDeltas;
};

const getMatchingAndNotMatchingDeltas = (
  nodeDeltas: CriticalPathDelta[],
  filterTagIds: string[],
  context: CriticalPathContext,
) => {
  const { criticalPathNodeLocationById } = context;
  const isMatchingLocation = createIsMatchingLocationFnByContext(filterTagIds, context);

  const sortedNodeDeltas = getSortedNodeDeltas(nodeDeltas);
  const matchingNodeDeltas = sortedNodeDeltas.filter((item) => {
    const location = criticalPathNodeLocationById[item.node._id];
    return item.plannedEvent && location && isMatchingLocation(location);
  });
  const matchingNodeIds = new Set(matchingNodeDeltas.map((item) => item.node._id));
  const notMatchingNodeDeltas = sortedNodeDeltas.filter(
    (item) => !matchingNodeIds.has(item.node._id),
  );

  return { matchingNodeDeltas, notMatchingNodeDeltas };
};

export const getCriticalPathGroups = (
  criticalPath: CriticalPathEx,
  context: CriticalPathContext,
  filterTagIds: string[],
): MilestonePlotGroup[] => {
  const pathDeltas = calculatePathDeltas(criticalPath, context);

  return pathDeltas
    .map((pathDelta) => {
      const { matchingNodeDeltas, notMatchingNodeDeltas } = getMatchingAndNotMatchingDeltas(
        pathDelta.nodeDeltas,
        filterTagIds,
        context,
      );
      return {
        lastNodeId: pathDelta.lastNodeId,
        delta: pathDelta.delta,
        nodeDeltas: matchingNodeDeltas,
        notMatchingNodeDeltas,
      };
    })
    .filter((path) => path.nodeDeltas.length > 1);
};

const removeDuplicateDeltas = (deltas: CriticalPathDelta[]) => {
  const nodeIds = new Set<string>();
  const result: CriticalPathDelta[] = [];

  for (const delta of deltas) {
    if (!nodeIds.has(delta.node._id)) {
      result.push(delta);
      nodeIds.add(delta.node._id);
    }
  }

  return result;
};

export const getEntireProjectGroup = (
  criticalPath: CriticalPathEx,
  context: CriticalPathContext,
  filterTagIds: string[],
): MilestonePlotGroup[] => {
  const pathDeltas = calculatePathDeltas(criticalPath, context);
  const pathDelta = calculateDeltaByPaths(pathDeltas, context);

  const nodeDeltasWithDuplicates = pathDeltas.flatMap((pathDelta) => pathDelta.nodeDeltas);
  const nodeDeltas = removeDuplicateDeltas(nodeDeltasWithDuplicates);

  const { matchingNodeDeltas, notMatchingNodeDeltas } = getMatchingAndNotMatchingDeltas(
    nodeDeltas,
    filterTagIds,
    context,
  );

  return [
    {
      lastNodeId: "root",
      delta: pathDelta?.delta ?? null,
      nodeDeltas: matchingNodeDeltas,
      notMatchingNodeDeltas,
    },
  ];
};

export const getNodeNameByTags = (node: CriticalPathNode, context: CriticalPathContext) =>
  [node.building_id, node.level_id, node.section_id]
    .map((tagId) => (tagId ? context.tagsById[tagId]?.name : ""))
    .filter((name) => name)
    .join(" | ");
