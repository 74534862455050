import { HierarchyTagStore, HierarchyType } from "shared/types/HierarchyTag";
import {
  ActualEvent,
  PlanConfig,
  PlanContext,
  PlannedEvent,
  PlannerItem,
  PlannerItemWithChildren,
} from "shared/types/Plan";
import {
  createHierarchyTagContext,
  createIsMatchingLocationFnByContext,
  tagTypes,
} from "./hierarchyTags";

export const createPlanContext = (
  planConfig: PlanConfig,
  hierarchyTags: HierarchyTagStore[],
): PlanContext => {
  const hierarchyTagContext = createHierarchyTagContext(hierarchyTags);

  const plannerItemsById = planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = plannerItem;
    return acc;
  }, {} as Record<string, PlannerItem | undefined>);

  const plannerItemsByParentId = planConfig.planner_items.reduce((acc, plannerItem) => {
    if (plannerItem.parent_id) {
      if (!acc[plannerItem.parent_id]) {
        acc[plannerItem.parent_id] = [];
      }
      (acc[plannerItem.parent_id] as PlannerItem[]).push(plannerItem);
    }
    return acc;
  }, {} as Record<string, PlannerItem[] | undefined>);

  const plannedEventsByPlannerItemId = planConfig.planned_events.reduce((acc, plannedEvent) => {
    acc[plannedEvent.planner_item_id] = plannedEvent;
    return acc;
  }, {} as Record<string, PlannedEvent | undefined>);

  const plannedEventsBySourceId = planConfig.planned_events.reduce((acc, plannedEvent) => {
    const plannerItem = plannerItemsById[plannedEvent.planner_item_id];
    if (plannerItem) {
      acc[plannerItem.source_id] = plannedEvent;
    }
    return acc;
  }, {} as Record<string, PlannedEvent | undefined>);

  const actualEventsBySourceId = planConfig.actual_events.reduce((acc, actualEvent) => {
    acc[actualEvent.source_id] = actualEvent;
    return acc;
  }, {} as Record<string, ActualEvent | undefined>);

  const tagsByPlannerItemId = planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = tagTypes
      .map((type) => hierarchyTagContext.tagsByTypeAndSourceId[`${type}_${plannerItem.source_id}`])
      .filter((tag) => tag) as HierarchyTagStore[];
    return acc;
  }, {} as Record<string, HierarchyTagStore[] | undefined>);

  const tagIdsByPlannerItemId = planConfig.planner_items.reduce((acc, plannerItem) => {
    const tags = tagsByPlannerItemId[plannerItem._id];
    if (tags) {
      acc[plannerItem._id] = tags.reduce((tagAcc, tag) => {
        tagAcc[tag.type] = tag._id;
        return tagAcc;
      }, {} as Record<HierarchyType, string>);
    }
    return acc;
  }, {} as Record<string, Record<HierarchyType, string> | undefined>);

  return {
    ...hierarchyTagContext,
    plannerItemsById,
    plannerItemsByParentId,
    plannedEventsByPlannerItemId,
    plannedEventsBySourceId,
    actualEventsBySourceId,
    tagsByPlannerItemId,
    tagIdsByPlannerItemId,
  };
};

export const createPlannerItemsWithChildren = (
  plannerItems: PlannerItem[],
): PlannerItemWithChildren[] => {
  const roots = [] as PlannerItemWithChildren[];
  const plannerItemsById = plannerItems.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = { ...plannerItem, children: [] };
    return acc;
  }, {} as Record<string, PlannerItemWithChildren>);
  plannerItems.forEach(({ _id }) => {
    const plannerItem = plannerItemsById[_id];
    if (plannerItem.parent_id) {
      const parent = plannerItemsById[plannerItem.parent_id];
      if (parent) {
        parent.children?.push(plannerItem);
      }
    } else {
      roots.push(plannerItem);
    }
  });
  return roots;
};

export const filterPlannerItemsByHierarchy = (
  plannerItems: PlannerItem[],
  isPlannerItemVisible: (plannerItem: PlannerItem) => boolean,
): PlannerItem[] => {
  const result = [] as PlannerItem[];

  const plannerItemsByParentId = plannerItems.reduce((acc, plannerItem) => {
    if (plannerItem.parent_id) {
      if (!acc[plannerItem.parent_id]) {
        acc[plannerItem.parent_id] = [];
      }
      acc[plannerItem.parent_id].push(plannerItem);
    }
    return acc;
  }, {} as Record<string, PlannerItem[]>);

  const traverse = (plannerItems: PlannerItem[]): boolean => {
    let visible = false;
    for (const plannerItem of plannerItems) {
      const childPlannerItems = plannerItemsByParentId[plannerItem._id] || [];

      const childVisible = traverse(childPlannerItems);
      const noChildOrNoChildVisible = childPlannerItems.length === 0 || !childVisible;

      if (noChildOrNoChildVisible) {
        if (isPlannerItemVisible(plannerItem)) {
          result.push(plannerItem);
          visible = true;
        }
      } else {
        if (childVisible) {
          result.push(plannerItem);
          visible = true;
        }
      }
    }
    return visible;
  };

  traverse(plannerItems.filter((plannerItem) => !plannerItem.parent_id));

  return result;
};

export const filterPlanConfigByPlannerItems = (
  planConfig: PlanConfig,
  plannerItems: PlannerItem[],
) => {
  const plannerItemIds = new Set(plannerItems.map((plannerItem) => plannerItem._id));
  const plannerItemSourceIds = new Set(plannerItems.map((plannerItem) => plannerItem.source_id));

  return {
    ...planConfig,
    planner_items: plannerItems,
    planned_events: planConfig.planned_events.filter((plannedEvent) =>
      plannerItemIds.has(plannedEvent.planner_item_id),
    ),
    actual_events: planConfig.actual_events.filter((actualEvent) =>
      plannerItemSourceIds.has(actualEvent.source_id),
    ),
  };
};

export const filterPlanConfigByLocation = (
  planConfig: PlanConfig,
  hierarchyTags: HierarchyTagStore[],
  filterTagIds: string[],
): PlanConfig => {
  const planContext = createPlanContext(planConfig, hierarchyTags);
  const { tagsByPlannerItemId, tagIdsByPlannerItemId } = planContext;

  const isMatchingLocation = createIsMatchingLocationFnByContext(filterTagIds, planContext);

  const isPlannerItemVisible = (plannerItem: PlannerItem) => {
    const tags = tagsByPlannerItemId[plannerItem._id] || [];
    const tagIds = tagIdsByPlannerItemId[plannerItem._id];
    return (
      plannerItem.tracking_enabled && tags.length > 0 && !!tagIds && isMatchingLocation(tagIds)
    );
  };
  const filteredPlannerItems = filterPlannerItemsByHierarchy(
    planConfig.planner_items,
    isPlannerItemVisible,
  );

  return filterPlanConfigByPlannerItems(planConfig, filteredPlannerItems);
};
