import projectProgressService from "@/services/projectProgressService";
import { QueryValueContext, QueryValueSingleValue } from "@/types/reports/PlotQueryValue";

const calculateProjectProgressMetric = (
  context: QueryValueContext,
): QueryValueSingleValue | null => {
  const { planConfig, processes, projectDurationSettings, hierarchyTags, filters } = context;

  if (!planConfig || !processes || !projectDurationSettings || hierarchyTags.length === 0) {
    return null;
  }

  const date = undefined;
  const value = projectProgressService.calculateProjectProgressPercentage(
    planConfig,
    processes,
    projectDurationSettings,
    hierarchyTags,
    date,
    filters.location,
  );
  return {
    value: value !== null ? value * 100 : null,
    previous_period_value: null,
  };
};

export default calculateProjectProgressMetric;
