<template>
  <OaiTooltip
    v-if="!isLastNode && selected && deltaForNode.workingDays !== null"
    :cls="['absolute text-xs -top-[17px] right-[2px]', differenceForNodeClass]"
  >
    {{ deltaForNode.workingDays }} {{ t("working_day.working_day_abbrev") }}
    <template #tooltip>
      <div class="text-xs" v-if="deltaForNode.plannedEvent || deltaForNode.actualEvent">
        <div class="flex gap-1" v-if="deltaForNode.plannedEvent">
          <div class="w-[130px]">{{ t("analytics.critical_path.planned_end") }}:</div>
          <div>{{ format(deltaForNode.plannedEvent.end, dateFormat) }}</div>
        </div>
        <div class="flex gap-1" v-if="deltaForNode.endDate">
          <div class="w-[130px]">
            {{
              deltaForNode.projectedEndDate
                ? t("analytics.critical_path.projected_end")
                : t("analytics.critical_path.actual_end")
            }}:
          </div>
          <div>
            {{ format(deltaForNode.endDate, dateFormat) }}
          </div>
        </div>
        <div class="flex gap-1">
          <div class="w-[130px]">{{ t("working_day.working_day") }}:</div>
          <div>{{ deltaForNode.workingDays }}</div>
        </div>
      </div>
    </template>
  </OaiTooltip>
  <CriticalPathDelay
    v-if="isLastNode"
    :deltaForNode="deltaForNode"
    cls="absolute -top-[25px] left-1/2 -translate-x-1/2 z-[100]"
  />
</template>

<script setup lang="ts">
import { format } from "date-fns";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import {
  CriticalPathContext,
  CriticalPathDelta,
  CriticalPathNodeEx,
} from "shared/types/CriticalPath";
import CriticalPathDelay from "shared/views/critical_path/components/CriticalPathDelay.vue";
import { useDeltaTextClass } from "../composables";

const dateFormat = "dd.MM.yyyy";

const props = defineProps<{
  criticalPathNode: CriticalPathNodeEx;
  selected: boolean;
  context: CriticalPathContext;
  deltaForNode: CriticalPathDelta;
  isLastNode: boolean;
}>();

const { t } = useI18n();

const differenceForNodeClass = useDeltaTextClass(computed(() => props.deltaForNode.workingDays));
</script>
