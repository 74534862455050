<template>
  <Popover class="relative flex items-center">
    <PopoverButton class="outline-none">
      <slot name="icon"></slot>
    </PopoverButton>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel v-slot="{ close }" :class="[position, width, 'absolute z-50 mt-3']">
        <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-gray-300 text-sm bg-white">
          <div class="py-3">
            <div
              @click="plot.enabled && createReport(plot?.type, close)"
              v-for="plot in sortedPlotTypes"
              :key="plot.type"
            >
              <div
                :class="[
                  plot.enabled ? 'hover:bg-gray-100 hover:cursor-pointer' : 'text-gray-700',
                  'flex flex-col px-4 py-1.5 w-full group',
                ]"
              >
                <div class="flex items-center">
                  <Component
                    :is="reportIconsConfig[plot.type].icon"
                    :class="[reportIconsConfig[plot.type].style, 'h-4 w-4 flex-shrink-0']"
                  />
                  <div class="ml-2">
                    <div class="flex items-center gap-1">
                      <span class="truncate font-medium">{{
                        t(`analytics.reports.${plot.type}_plot`)
                      }}</span>
                      <LockClosedIcon v-if="!plot.enabled" class="h-4 w-4 ml-2" />
                    </div>
                  </div>
                </div>
                <small class="ml-6 break-words text-gray text-xs">{{
                  t(`analytics.reports.plot_descriptions.${plot.type}`)
                }}</small>
              </div>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { LockClosedIcon } from "@heroicons/vue/24/outline";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ReportPlotType } from "@/types/Report";
import reportIconsConfig from "@/views/reports/components/reportIconsConfig";
import { usePlotTypes } from "@/views/reports/composables";

const plotTypes = usePlotTypes();

defineProps({
  position: {
    type: String,
    required: false,
  },
  width: {
    type: String,
    required: false,
  },
});

const emits = defineEmits(["createReport"]);

const { t } = useI18n();

const sortedPlotTypes = computed(() =>
  plotTypes?.value ? [...plotTypes.value].sort((a, b) => a.type.localeCompare(b.type)) : [],
);

const createReport = (plotType: ReportPlotType, close: () => void) => {
  emits("createReport", plotType);
  close();
};
</script>
