import processDurationService from "@/services/processDurationService";
import { QueryValueContext, QueryValueSingleValue } from "@/types/reports/PlotQueryValue";

const calculateUtilizationMetric = (context: QueryValueContext): QueryValueSingleValue | null => {
  const { processes, previousPeriodProcesses, projectDurationSettings } = context;

  if (!processes || !projectDurationSettings) {
    return null;
  }

  const value =
    processDurationService.calculateUtilization(processes, projectDurationSettings) ?? null;
  const previousPeriodValue = previousPeriodProcesses
    ? processDurationService.calculateUtilization(
        previousPeriodProcesses,
        projectDurationSettings,
      ) ?? null
    : null;
  return {
    value,
    previous_period_value: previousPeriodValue,
  };
};

export default calculateUtilizationMetric;
