<template>
  <div :class="['flex flex-col gap-2', isMobileMenu && 'pt-2']">
    <div v-for="(category, idx) in categories" :key="idx" class="flex flex-col gap-2">
      <div
        :class="[
          'pr-3 font-semibold cursor-default flex items-center justify-between',
          isMobileMenu ? 'text-gray-400' : 'text-white',
        ]"
      >
        {{ t(`dashboard.menu_categories.${category.type}`) }}
        <PlusIcon
          class="w-4 h-4 cursor-pointer shrink-0 outline-none hover:text-yellow-400"
          @click="emit('addNewDashboard', category.type)"
        />
      </div>

      <ul>
        <li
          v-for="dashboard in category.dashboards"
          :key="dashboard._id"
          :class="[
            currentDashboard?._id === dashboard._id ? 'text-yellow-400' : 'hover:text-yellow-400',
            'gap-x-3 flex group hover:border-white cursor-pointer text-sm leading-6',
            isMobileMenu ? 'text-gray-400' : 'border-l border-gray-400 ',
          ]"
          @click="handleMenuItemClick(dashboard)"
        >
          <span
            :class="[
              'w-full flex items-center gap-1 py-1 px-2 rounded-md hover:bg-oaiGray-300',
              !isMobileMenu && 'mx-2',
            ]"
          >
            {{ dashboard.name }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { PlusIcon } from "@heroicons/vue/24/outline";
import isMobile from "is-mobile";
import { computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useLastSelectedDashboardId } from "@/composables/dashboard";
import { Dashboard } from "@/types/Dashboard";
import {
  useDashboardsWithDefault,
  useHasPermissionToEditDashboard,
  useLayoutContext,
} from "@/views/dashboard/composables";
import { createDashboardMenuCategories } from "@/views/dashboard/services/projectOverviewV2Layout";

defineProps({
  isMobileMenu: {
    type: Boolean,
  },
});

const { t } = useI18n();

const router = useRouter();
const currentDashboardId = useLastSelectedDashboardId();

const { layoutContext, isLoading: isLayoutContextLoading } = useLayoutContext();
const { dashboardsWithDefault, isLoading: areDashboardsLoading } =
  useDashboardsWithDefault(layoutContext);
const isLoading = computed(() => areDashboardsLoading.value || isLayoutContextLoading.value);

const currentDashboard = computed<Dashboard>(
  () =>
    dashboardsWithDefault.value.find((dashboard) => dashboard._id === currentDashboardId.value) ||
    dashboardsWithDefault.value.find((dashboard) => dashboard.default) ||
    dashboardsWithDefault.value[0],
);

const canEdit = computed(
  () =>
    !!currentDashboard.value &&
    !!layoutContext.value &&
    !isLoading.value &&
    !isMobile({ tablet: true, featureDetect: true }),
);
const hasPermissionToEditDashboard = useHasPermissionToEditDashboard();

const categories = computed(() =>
  createDashboardMenuCategories(dashboardsWithDefault.value).map((category) => ({
    ...category,
    canAdd: canEdit.value && hasPermissionToEditDashboard(category),
  })),
);

const handleMenuItemClick = (dashboard: Dashboard) => {
  if (dashboard._id !== "default") {
    router.push({
      name: "ProjectOverview",
      query: { id: dashboard._id },
    });
  } else {
    router.push({ name: "ProjectOverview" });
  }
};

const emit = defineEmits(["addNewDashboard", "currentDashboardChanged"]);

watch(currentDashboard, () => {
  emit("currentDashboardChanged", currentDashboard.value._id);
});
</script>
