<template>
  <div
    class="relative rounded-md bg-white py-1.5 pr-1.5 pl-3 shadow-sm ring-1 ring-inset ring-gray-300 w-full group/filter-box"
  >
    <div class="flex items-center justify-between">
      <p class="font-normal mb-1">
        {{ t("analytics.reports.unit_value") }}
      </p>
    </div>

    <div class="text-sm flex items-center">
      <span class="mr-3 text-gray-400">
        {{ t("analytics.reports.is") }}
      </span>

      <Listbox as="div" style="min-width: 180px" :modelValue="filter" class="flex-1">
        <div class="relative w-full">
          <ListboxButton
            :class="[
              'flex py-0.5 px-4 border rounded-full max-w-60 cursor-pointer w-full truncate',
              filter ? 'border-gray-300' : 'border-yellow-400',
            ]"
          >
            <p
              v-if="filter"
              class="text-gray-500 max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis mr-1"
            >
              {{ t(`unit_values.types.${filter}.label`) }}
            </p>
            <p v-else>...{{ t("unit_values.select_type") }}</p>
          </ListboxButton>
          <transition
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <ListboxOptions
              class="absolute z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-yellow-500/5 focus:outline-none sm:text-sm w-full"
            >
              <ListboxOption
                as="template"
                v-for="type in unitValueTypes"
                :key="type"
                :value="type"
                v-slot="{ active, selected }"
              >
                <li
                  :class="[
                    (active || selected) && 'bg-yellow-100',
                    'relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900',
                  ]"
                  @click="handleFilterChange(type)"
                >
                  <span class="block truncate font-normal"
                    >{{ t(`unit_values.types.${type}.label`) }} [{{
                      t(`unit_values.types.${type}.unit`)
                    }}]</span
                  >
                </li></ListboxOption
              >
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useUnitValues } from "@/composables/unitValues";
import { UnitValueType } from "@/types/UnitValue";
import { ReportUnitValueTypeFilter } from "@/types/reports/filters";

defineProps<{ filter: ReportUnitValueTypeFilter }>();
const emit = defineEmits<{ (eventName: "change", payload: ReportUnitValueTypeFilter): void }>();

const { t } = useI18n();
const { unitValues } = useUnitValues();

const unitValueTypes = computed(() => {
  const typeOrders = (unitValues.value || []).reduce((acc, unitValue) => {
    acc[unitValue.type] = unitValue.type_order;
    return acc;
  }, {} as Record<UnitValueType, number>);

  const result = [
    ...new Set(
      (unitValues.value || [])
        .filter((unitValue) => unitValue.duration && unitValue.workforce)
        .map((unitValue) => unitValue.type),
    ),
  ];
  result.sort((a, b) => typeOrders[a] - typeOrders[b]);
  return result;
});

const handleFilterChange = (value: UnitValueType) => {
  emit("change", value);
};
</script>
