<template>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1493.31 1493.68">
    <path
      class="cls-1"
      d="M335.12,1019.71l302.29-301.6c35.36-35.28,76.69-64.85,122.31-87.51l436.93-217.05"
    />
    <path class="cls-1" d="M157.98,178.2v1067.03c0,49.86,40.61,90.28,90.71,90.28h1072.02" />
    <polyline class="cls-1" points="739.35 1144.25 856 845.55 1320.71 716.63" />
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped>
.cls-1 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 150px;
}
</style>
