import { ref, Ref, watch } from "vue";

export const useWatchKey = (...args: Ref<unknown>[]) => {
  const key = ref(0);

  watch(args, () => {
    key.value += 1;
  });

  return key;
};
