import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import {
  useCurrentCustomerName,
  useCurrentSiteId,
  useHasPermission,
} from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import logger from "shared/services/logger";
import { useHasProjectFeature } from "@/composables/project";
import ReportRepository from "@/repositories/ReportRepository";
import {
  Report,
  ReportAggregation,
  ReportConfig,
  ReportFilters,
  ReportPlotType,
  ReportToCreateOrUpdate,
} from "@/types/Report";
import { QueryValueReportConfig } from "@/types/reports/PlotQueryValue";
import { defaultFilters as queryValueDefaultFilters } from "@/views/reports/plots/query_value/queryValue";

export const useIsPlotTypeEnabled = () => {
  const workingHoursFeatureEnabled = useHasProjectFeature("working_hours");
  const workingHoursFeaturePlotTypes: ReportPlotType[] = ["working_hour_curve", "unit_values"];

  const hasPlannerPermission = useHasPermission("planner_user");
  const plannerPlotTypes: ReportPlotType[] = ["milestone"];

  return (plotType: ReportPlotType) => {
    if (workingHoursFeaturePlotTypes.includes(plotType) && !workingHoursFeatureEnabled) {
      return false;
    }
    if (plannerPlotTypes.includes(plotType) && !hasPlannerPermission) {
      return false;
    }
    return true;
  };
};

export const useReports = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const hasPermission = useHasPermission("processes_user");
  const isPlotTypeEnabled = useIsPlotTypeEnabled();

  const { data, isLoading, error } = useQuery<Report[]>({
    queryKey: ["reports", currentCustomerName, currentSiteId],
    queryFn: () =>
      hasPermission
        ? ReportRepository.loadReports(currentCustomerName, currentSiteId)
        : ([] as Report[]),
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const reports = computed(() =>
    (data.value || []).filter((report) => isPlotTypeEnabled(report.type)),
  );

  return { reports, isLoading, error };
};

export const useCreateReport = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: createReport, isLoading } = useMutation<
    Report,
    Error,
    ReportToCreateOrUpdate
  >({
    mutationFn: (payload) =>
      ReportRepository.createReport(currentCustomerName, currentSiteId, payload),
    onSuccess: (createdReport) => {
      queryClient.setQueryData(
        ["reports", currentCustomerName, currentSiteId],
        (reports: Report[] | undefined) => [...(reports || []), createdReport],
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.reports.update_report_error"));
      return false;
    },
  });

  return { createReport, isLoading };
};

export const defaultReportId = "default";

const defaultPlotConfigs: Record<
  ReportPlotType,
  (workingHoursFeatureEnabled: boolean) => ReportConfig
> = {
  cycle_times: (workingHoursFeatureEnabled) => ({
    show_planned: true,
    show_utilization: workingHoursFeatureEnabled,
    show_outages: true,
    show_active_days: true,
    show_inactive_days: true,
  }),
  working_hour_curve: () => ({
    end_date: undefined,
    mode: "default",
    show_mean: true,
  }),
  unit_values: () => ({}),
  milestone: () => ({
    mode: "sequence",
    variant: "milestone",
    show_delta: true,
  }),
  query_value: () => ({
    metric: "project_progress",
    show_previous_period: false,
    formatting_rules: [],
  }),
};

export const defaultPlotFilters: Record<ReportPlotType, () => ReportFilters> = {
  cycle_times: () => ({
    location: [],
    processes: {
      mode: "component",
      components: [],
      single_processes: [],
    },
  }),
  working_hour_curve: () => ({
    location: [],
    processes: {
      mode: "component",
      components: [],
      single_processes: [],
    },
    daterange: {
      type: "total",
      start_date: null,
      end_date: null,
    },
  }),
  unit_values: () => ({
    location: [],
    unit_value_type: null,
  }),
  milestone: () => ({
    location: [],
  }),
  query_value: () => structuredClone(queryValueDefaultFilters),
};

const defaultPlotAggregations: Record<ReportPlotType, () => ReportAggregation> = {
  cycle_times: () => "section",
  working_hour_curve: () => "week",
  unit_values: () => "section",
  milestone: () => null,
  query_value: () => "sum",
};

export const useCreateDefaultReport = () => {
  const { t } = useI18n();
  const { reports, isLoading } = useReports();
  const workingHoursFeatureEnabled = useHasProjectFeature("working_hours");
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const getReportName = (
    plotType: ReportPlotType,
    config: ReportConfig,
    existingReport?: Report,
  ) => {
    if (plotType === "query_value") {
      const queryValueConfig = config as QueryValueReportConfig;
      const existingQueryValueConfig = existingReport?.config as QueryValueReportConfig | undefined;
      const finalMetric = existingQueryValueConfig?.metric || queryValueConfig.metric;
      return t(`analytics.reports.query_value_metrics.${finalMetric}`);
    }
    const reportName = t(`analytics.reports.${plotType}_plot`);

    const lastNumber = reports.value
      .filter((group) => group.name.startsWith(reportName))
      .map((group) => {
        const number = group.name.replace(reportName, "").trim();
        return number.length > 0 ? parseInt(number, 10) : 0;
      })
      .sort((a, b) => b - a)[0];

    if (lastNumber || lastNumber === 0) {
      return `${reportName} ${lastNumber + 1}`;
    }
    return reportName;
  };

  const createDefaultReport = (plotType: ReportPlotType, existingReport?: Report) => {
    const config = defaultPlotConfigs[plotType](workingHoursFeatureEnabled);
    const report: Report = {
      _id: defaultReportId,
      customer_name: currentCustomerName,
      site_id: currentSiteId,
      name: getReportName(plotType, config, existingReport),
      filters: defaultPlotFilters[plotType](),
      type: plotType,
      config,
      aggregation: defaultPlotAggregations[plotType](),
    };
    return report;
  };

  return { createDefaultReport, isLoading };
};

export const useUpdateReport = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: updateReport, isLoading } = useMutation<
    Report,
    Error,
    ReportToCreateOrUpdate
  >({
    mutationFn: (payload) =>
      ReportRepository.updateReport(currentCustomerName, currentSiteId, payload),
    onSuccess: (updatedReport) => {
      queryClient.setQueryData(
        ["reports", currentCustomerName, currentSiteId],
        (reports: Report[] | undefined) =>
          reports?.map((report) => (report._id === updatedReport._id ? updatedReport : report)),
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.reports.update_report_error"));
      return false;
    },
  });

  return { updateReport, isLoading };
};

export const useDeleteReport = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: deleteReport, isLoading } = useMutation<void, Error, string>({
    mutationFn: (reportId) =>
      ReportRepository.deleteReport(currentCustomerName, currentSiteId, reportId),
    onSuccess: (result, reportId) => {
      queryClient.setQueryData(
        ["reports", currentCustomerName, currentSiteId],
        (reports: Report[] | undefined) =>
          reports && reports.filter((report) => report._id !== reportId),
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.reports.delete_report_error"));
      return false;
    },
  });

  return { deleteReport, isLoading };
};
