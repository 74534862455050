<template>
  <div class="border text-gray-900 bg-white shadow flex flex-col relative">
    <div class="flex-1 flex flex-col gap-8 overflow-auto px-4 pt-8 pb-3">
      <div class="flex-1 flex flex-col gap-8">
        <div v-for="category in categories" :key="category.name" class="flex flex-col gap-2">
          <div
            :class="[
              'font-semibold text-lg gap-2 items-center',
              (category.name !== 'report' || category.widgets.length) && 'flex justify-between',
            ]"
          >
            <div class="flex gap-2 items-center justify-between w-full">
              <Component :is="category.icon" class="w-5 h-5 text-yellow-600" />
              <div class="flex-1 truncate">
                {{ $t(`dashboard.categories.${category.name}`) }}
              </div>
              <div v-if="category.name === 'report' && category?.widgets.length > 0">
                <AddReportMenu
                  @createReport="addReportModalPlotType = $event"
                  position="top-4 -right-2"
                  width="w-[280px]"
                  :menuPosition="category.widgets.length ? 'left' : 'center'"
                  class="w-full"
                >
                  <template #icon>
                    <div
                      class="text-sm flex items-center font-normal gap-1 text-gray-900 hover:underline cursor-pointer"
                    >
                      + {{ t("analytics.reports.dashboard_new_report") }}
                    </div>
                  </template>
                </AddReportMenu>
              </div>
            </div>
          </div>

          <div
            class="relative flex flex-col gap-2"
            v-for="(widget, idx) in category.widgets"
            :key="`${widget?.type}_${widget?.arg}`"
          >
            <div
              v-if="
                category.name === 'report' &&
                widget?.arg &&
                getReportTypeById(widget?.arg) !==
                  getReportTypeById(category?.widgets[idx - 1]?.arg || '')
              "
              class="flex items-center my-1"
            >
              <div class="border-t border-gray-300 grow"></div>
              <span class="px-3 text-gray-500 text-sm">
                <div class="flex items-center gap-1">
                  <Component
                    :is="getReportIcon(widget?.arg)"
                    :class="['stroke-gray-500 h-4 w-4 flex-shrink-0']"
                  />
                  <div>{{ t(`analytics.reports.${getReportTypeById(widget?.arg)}_plot`) }}</div>
                </div>
              </span>
              <div class="border-t border-gray-300 grow"></div>
            </div>
            <div
              class="cursor-grab active:cursor-grabbing bg-gray-50 px-2 py-3 border text-sm rounded gap-1 select-none truncate"
              @dblclick="
                emit('addWidget', widget);
                trackEvent('dashboard_widget_add', { type: widget.type });
              "
              draggable="true"
              @dragstart="handleWidgetDragStart($event, widget)"
              @drag="emit('widgetDrag', $event)"
              @dragend="
                emit('widgetDragEnd', $event);
                trackEvent('dashboard_widget_add', { type: widget.type });
              "
            >
              {{ widget.name }}
            </div>
          </div>
          <div class="flex justify-center" v-if="category?.widgets.length === 0">
            <div class="font-normal" v-if="category.name === 'report'">
              <AddReportMenu
                @createReport="addReportModalPlotType = $event"
                position="top-8 -left-[119px]"
                width="w-[280px]"
                :menuPosition="category.widgets.length ? 'left' : 'center'"
                class="w-full"
              >
                <template #icon>
                  <PlusCircleIcon
                    class="text-yellow-400 cursor-pointer hover:text-yellow-600 transition-colors w-10"
                  />
                </template>
              </AddReportMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="text-gray-600 text-sm absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center"
      v-if="widgetsToUse.length === 0"
    >
      {{ $t("dashboard.no_widgets_to_add") }}
    </div>
    <XMarkIcon
      class="h-5 w-5 absolute cursor-pointer top-3 right-4 text-gray-600"
      @click="emit('close')"
    />
    <AddReportModal
      v-if="addReportModalPlotType"
      :plotType="addReportModalPlotType"
      @close="addReportModalPlotType = null"
      @done="handleReportDone"
    />
    <div
      ref="widgetPreviewRef"
      class="fixed"
      :style="{
        top: '-10000px',
        left: '-10000px',
      }"
    >
      <BaseWidget class="px-4 py-3">
        <div class="flex flex-col gap-2 h-full">
          <div id="oaiWidgetPreviewTitle" class="truncate font-semibold text-gray-900 text-lg" />
          <div class="bg-gray-300 flex-1 rounded" />
        </div>
      </BaseWidget>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PlusCircleIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { ChartBarIcon, CameraIcon, FolderIcon } from "@heroicons/vue/24/solid";
import { Component as VueComponent, ComputedRef } from "vue";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useHasPermission } from "shared/composables/project";
import { useTrackEvent } from "shared/composables/tracking";
import { useLogoUrl } from "@/composables/project";
import { useReports } from "@/composables/report";
import { DashboardGridSize, OculaiLayoutItem } from "@/types/Dashboard";
import { Report, ReportPlotType } from "@/types/Report";
import BaseWidget from "@/views/dashboard/componentsV2/BaseWidget.vue";
import {
  calculateRealWidgetHeight,
  calculateRealWidgetWidth,
} from "@/views/dashboard/services/projectOverviewV2Layout";
import AddReportMenu from "@/views/reports/components/AddReportMenu.vue";
import AddReportModal from "@/views/reports/components/AddReportModal.vue";
import reportIconsConfig from "@/views/reports/components/reportIconsConfig";

const props = defineProps<{
  widgets: OculaiLayoutItem[];
  dashboardGridSize: DashboardGridSize;
}>();
const emit = defineEmits<{
  (eventName: "addWidget", widget: OculaiLayoutItem): void;
  (eventName: "close"): void;
  (eventName: "widgetDragStart", event: DragEvent): void;
  (eventName: "widgetDrag", event: DragEvent): void;
  (eventName: "widgetDragEnd", event: DragEvent): void;
}>();

const { t } = useI18n();

const addReportModalPlotType = ref<ReportPlotType | null>(null);
const widgetPreviewRef = ref<HTMLDivElement | null>(null);

const hasProcessesPermission = useHasPermission("processes_user");

// the logo is loaded only when the add widgets panel is opened,
// so it doesn't "block" loading of the entire dashboard
const { logoUrl } = useLogoUrl();

const trackEvent = useTrackEvent();

const { reports } = useReports();

const widgetsToUse = computed(() =>
  props.widgets.filter((widget) => widget.type !== "CustomerLogoWidget" || logoUrl.value),
);

type Category = {
  name: string;
  widgets: OculaiLayoutItem[];
  icon: VueComponent;
};

const sortedReportWidgets = computed(() => {
  const reportWidgets = widgetsToUse.value.filter((widget) => widget.type === "ReportWidget");

  reportWidgets.sort((a, b) => a.name.localeCompare(b.name));

  const groupedReportWidget: Record<string, OculaiLayoutItem[]> = {};
  reportWidgets.forEach((widget) => {
    const type = getReportTypeById(widget?.arg ?? "");
    if (type) {
      if (!groupedReportWidget[type]) {
        groupedReportWidget[type] = [];
      }
      groupedReportWidget[type].push(widget);
    }
  });

  const entries = Object.entries(groupedReportWidget).sort((a, b) => a[0].localeCompare(b[0]));
  return entries.flatMap((entry) => entry[1]);
});

const categories = computed(() => {
  return [
    {
      name: "camera",
      widgets: widgetsToUse.value.filter((widget) => widget.type === "StreamWidget"),
      icon: CameraIcon,
    },
    {
      name: "report",
      widgets: sortedReportWidgets.value,
      icon: ChartBarIcon,
    },
    {
      name: "other",
      widgets: widgetsToUse.value.filter(
        (widget) => widget.type !== "StreamWidget" && widget.type !== "ReportWidget",
      ),
      icon: FolderIcon,
    },
  ].filter((category) => {
    if (category.name === "report") {
      return hasProcessesPermission;
    }
    return category.widgets.length > 0;
  });
}) as ComputedRef<Category[]>;

const handleReportDone = (report: Report) => {
  addReportModalPlotType.value = null;
  const widgetToAdd = props.widgets.find(
    (widget) => widget.type === "ReportWidget" && widget.arg === report._id,
  );
  if (widgetToAdd) {
    emit("addWidget", widgetToAdd);
  }
};

const prepareWidgetPreview = (widget: OculaiLayoutItem) => {
  if (!widgetPreviewRef.value) {
    return;
  }
  const width = calculateRealWidgetWidth(props.dashboardGridSize, widget.w);
  const height = calculateRealWidgetHeight(props.dashboardGridSize, widget.h);
  widgetPreviewRef.value.style.width = `${width}px`;
  widgetPreviewRef.value.style.height = `${height}px`;
  const titleElement = widgetPreviewRef.value.querySelector("#oaiWidgetPreviewTitle");
  if (titleElement) {
    titleElement.innerHTML = widget.name;
  }
};

const handleWidgetDragStart = (event: DragEvent, widget: OculaiLayoutItem) => {
  if (!event.dataTransfer) {
    return;
  }

  event.dataTransfer.effectAllowed = "move";

  event.dataTransfer.clearData();
  event.dataTransfer.setData("application/json", JSON.stringify(widget));

  if (widgetPreviewRef.value) {
    prepareWidgetPreview(widget);
    event.dataTransfer.setDragImage(widgetPreviewRef.value, 0, 0);
  }

  emit("widgetDragStart", event);
};
const getReportTypeById = (id: string) => {
  const report = reports.value.find((report) => report._id === id);
  return report?.type;
};
const getReportIcon = (id: string) => {
  const type = getReportTypeById(id);
  return type ? reportIconsConfig[type]?.icon : null;
};
</script>
