<template>
  <slot />
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, watch } from "vue";
import { useDashboards } from "@/composables/dashboard";
import { useReports } from "@/composables/report";
import { useStreams } from "@/composables/stream";

const emit = defineEmits<{ (eventName: "ready"): void }>();

let timeoutTimer: ReturnType<typeof setTimeout> | null = null;
let isReady = false;

const { isLoading: areDashboardsLoading } = useDashboards();
const { isLoading: areReportsLoading } = useReports();
const { isLoading: areStreamsLoading } = useStreams();

const isLoading = computed(
  () => areDashboardsLoading.value || areReportsLoading.value || areStreamsLoading.value,
);

const emitReady = () => {
  if (!isReady) {
    isReady = true;
    emit("ready");
  }
  if (timeoutTimer) {
    clearTimeout(timeoutTimer);
    timeoutTimer = null;
  }
};

const emitReadyIfFinishedLoading = () => {
  if (!isLoading.value) {
    emitReady();
  }
};

watch(isLoading, () => {
  emitReadyIfFinishedLoading();
});

onMounted(() => {
  emitReadyIfFinishedLoading();
  if (!isReady) {
    timeoutTimer = setTimeout(() => {
      emitReady();
    }, 200);
  }
});

onBeforeUnmount(() => {
  if (timeoutTimer) {
    clearTimeout(timeoutTimer);
    timeoutTimer = null;
  }
});
</script>
