<template>
  <div
    :style="{ height: `${playerDimensions.height}px` }"
    class="bg-oaiGray-900 flex flex-col items-center"
  >
    <div
      :style="{
        width: `${playerDimensions.width}px`,
      }"
      class="overflow-hidden"
    >
      <ProcessVideo v-bind="playerProps" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, Ref } from "vue";
import ProcessVideo from "@/components/other/ProcessVideo.vue";
import { OculaiVideoPlayerProps } from "@/types/VideoPlayer";
import { useVideoPlayerDimensions } from "@/views/dashboard/composables";

const props = defineProps<
  {
    getContainerRef: () => Ref<HTMLDivElement | null>;
    processName: string;
    sectionMaskId: string;
  } & OculaiVideoPlayerProps
>();

const playerProps = computed(() => {
  const { getContainerRef, ...rest } = props;

  return rest;
});

const playerDimensions = useVideoPlayerDimensions(props.getContainerRef());
</script>
