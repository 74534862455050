<template>
  <!-- Mobile / Small Screen -->
  <div class="pr-2 sm:hidden">
    <label for="selected-tab" class="sr-only">Select a tab</label>
    <select
      id="selected-tab"
      name="selected-tab"
      class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 focus:border-yellow-500 focus:ring-yellow-500 text-sm"
      v-model="activeTab"
      @change="onSelectChange"
    >
      <option v-for="tab in collapsedTabs" :key="tab?.name" :value="tab" class="capitalize">
        {{ $t(`app_features.${tab.name}`) }}
      </option>
    </select>
  </div>

  <!-- Desktop / Large Screen -->
  <div class="hidden sm:block">
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex space-x-8">
        <div
          v-for="tab in tabs"
          :key="tab?.name"
          :class="[
            isCurrentTab(tab) || (tab.name === 'timelapse' && route?.name && isTimelapseCurrent())
              ? 'border-yellow-500 text-yellow-600'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
          ]"
        >
          <a v-if="tab.name === 'timelapse'">
            <Menu as="div" class="relative z-20 inline-block text-left">
              <div>
                <MenuButton class="inline-flex">
                  <div class="font-medium capitalize">
                    {{ $t(`app_features.${tab.name}`) }}
                  </div>
                  <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </MenuButton>
              </div>

              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="left-mt-2 focus:outline-none absolute w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-2 ring-gray/5"
                >
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'DailyTimelapse' }"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                      >
                        {{ $t("app_features.daily_timelapse") }}
                      </router-link>
                    </MenuItem>
                  </div>
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'CustomizableTimelapse' }"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                      >
                        {{ $t("app_features.customizable_timelapse") }}
                      </router-link>
                    </MenuItem>
                  </div>
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                      <router-link
                        :to="{ name: 'ProjectTimelapse' }"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                      >
                        {{ $t("app_features.project_timelapse") }}
                      </router-link>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </a>
          <div v-else class="relative">
            <router-link :to="tab.route"> {{ $t(`app_features.${tab.name}`) }}</router-link>
            <div
              v-if="tab.name === 'compare' && hasProcessesPermission"
              style="background: linear-gradient(90deg, #ff9d00 0%, #ff4000 100%); font-size: 8px"
              class="absolute px-1.5 -mt-0.5 rounded-full text-white font-medium -top-4 -right-8"
            >
              {{ $t("camera.compare.sections") }}
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/24/solid";
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  useRoute,
  useRouter,
  RouteLocationAsRelativeGeneric,
  RouteLocationAsPathGeneric,
} from "vue-router";
import { useHasPermission } from "shared/composables/project";
import "@/assets/styles/skeleton.css";
import { useCurrentProject } from "@/composables/project";

const router = useRouter(); // Initialize `useRouter`

// Define props
const props = defineProps({
  initialActiveTag: String,
  stream: Object,
});

type CameraTab = {
  name: string;
  route: RouteLocationAsRelativeGeneric | RouteLocationAsPathGeneric;
};
const tabs = ref<CameraTab[]>();
const collapsedTabs = ref<CameraTab[]>();

const { t } = useI18n();
const currentProjectV2 = useCurrentProject();
const hasAllCameraPermission = useHasPermission("all_camera_features_user");
const hasProcessesPermission = useHasPermission("processes_user");

onMounted(() => {
  const projectStatus = currentProjectV2.status;
  let newTabs = [];
  let newCollapsedTabs = [];

  if (hasAllCameraPermission) {
    newTabs = [
      { name: "live", route: { name: "Live" } },
      { name: "period", route: { name: "Period" } },
      { name: "gallery", route: { name: "Gallery" } },
      { name: "compare", route: { name: "Compare" } },
      { name: "timelapse", route: "#" },
    ];

    newCollapsedTabs = [
      { name: "live", route: { name: "Live" } },
      { name: "period", route: { name: "Period" } },
      { name: "gallery", route: { name: "Gallery" } },
      { name: "compare", route: { name: "Compare" } },
      { name: "daily_timelapse", route: { name: "DailyTimelapse" } },
      { name: "customizable_timelapse", route: { name: "CustomizableTimelapse" } },
      { name: "project_timelapse", route: { name: "ProjectTimelapse" } },
    ];

    if (!props.stream?.aws_stream_id) {
      newTabs.splice(0, 2);
      newCollapsedTabs.splice(0, 2);
    }
  } else if (projectStatus === "active") {
    newTabs = [{ name: t("app_features.live"), route: { name: "Live" } }];
    newCollapsedTabs = [{ name: t("app_features.live"), route: { name: "Live" } }];
  } else {
    newTabs = [
      {
        name: t("app_features.project_timelapse"),
        route: { name: "ProjectTimelapse" },
      },
    ];
    newCollapsedTabs = [
      { name: t("app_features.project_timelapse"), route: { name: "ProjectTimelapse" } },
    ];
  }

  // Update `tabs` and `collapsedTabs`
  tabs.value = newTabs.map((tab) => ({
    ...tab,
    route: typeof tab.route === "string" ? { name: tab.route } : tab.route,
  })) as CameraTab[];
  collapsedTabs.value = newCollapsedTabs;
});

// Set activeTab as a ref of the selected tab
const activeTab = ref<CameraTab>();
const route = useRoute();

onMounted(() => {
  activeTab.value = collapsedTabs.value?.find((item) => {
    return item.route && "name" in item.route && item.route.name === route.name;
  });
});

const onSelectChange = () => {
  if (activeTab?.value?.route) {
    router.push(activeTab.value.route);
  }
};

const isCurrentTab = (tab: CameraTab) => {
  if ("name" in tab.route) {
    return route.name === tab?.route?.name;
  }
  return false;
};

const isTimelapseCurrent = () => {
  if (route && typeof route.name === "string") {
    return ["DailyTimelapse", "CustomizableTimelapse", "ProjectTimelapse"].includes(route.name);
  }
  return false; // Return false if route is not valid or name is not a string
};
</script>
