<template>
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1498.48 1498.48">
    <line class="cls-1" x1="569.84" y1="184.07" x2="397.75" y2="1312.81" />
    <line class="cls-1" x1="1129.39" y1="185.67" x2="957.3" y2="1314.41" />
    <line class="cls-1" x1="1320.09" y1="492.63" x2="178.39" y2="478.35" />
    <line class="cls-1" x1="1310.24" y1="1059.45" x2="203.75" y2="1045.18" />
  </svg>
</template>

<script lang="ts" setup></script>

<style scoped>
.cls-1 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 150px;
}
</style>
