<template>
  <div
    class="relative rounded-md bg-white py-1.5 pl-3 pr-1.5 shadow-sm ring-1 ring-inset ring-gray-300 w-full group/filter-box"
  >
    <div class="flex items-center justify-between">
      <div class="font-normal mb-1">
        {{ t("analytics.reports.location") }}
      </div>
      <div
        class="cursor-pointer p-1 invisible group-hover/filter-box:visible rounded-md hover:bg-red-200 active:bg-red-300 transition-colors duration-100"
        @click="handleLocationFilterRemoveFilter()"
      >
        <XMarkIcon class="h-4 w-4 select-none" aria-hidden="true" />
      </div>
    </div>
    <div class="text-sm flex items-center">
      <span class="mr-3 text-gray-400">
        {{ t("analytics.reports.is") }}
      </span>
      <LocationFilter
        class="mt-2 lg:mt-0 flex-1"
        @change="onChangeHandler"
        :selected="filter"
        :hierarchyTags="hierarchyTagsForLocationFilter"
        popoverClass="translate-x-0 lg:translate-x-0 xl:-translate-x-8 md:right-auto mt-3"
      >
        <div
          class="flex py-0.5 px-4 border rounded-full max-w-60 cursor-pointer truncate border-gray-300"
        >
          <div
            v-if="Object.values(filter).some((mask) => mask.length)"
            class="flex justify-between"
          >
            <p
              class="text-gray-500 max-w-[160px] overflow-hidden whitespace-nowrap text-ellipsis mr-1"
            >
              {{ filter.map((tagId) => populateSectionMask(tagId)?.name).join(", ") }}
            </p>
            <span class="text-gray-500"> ({{ filter.length }}) </span>
          </div>
          <p v-else>...{{ t("analytics.reports.select_values") }}</p>
        </div>
      </LocationFilter>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { XMarkIcon } from "@heroicons/vue/24/outline";
import debounce from "lodash.debounce";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import LocationFilter from "@/components/process_filters/LocationFilter.vue";
import { ReportLocationFilter } from "@/types/reports/filters";

const props = defineProps<{
  filter: ReportLocationFilter;
  hierarchyTags: HierarchyTagStore[];
}>();
const emit = defineEmits<{ (eventName: "change", report: ReportLocationFilter): void }>();

const { t } = useI18n();

const populateSectionMask = (id: string) => props.hierarchyTags.find((tag) => tag._id === id);

const hierarchyTagsForLocationFilter = computed(() =>
  props.hierarchyTags.filter((tag) => tag.type !== "component"),
);

const handleLocationFilterRemoveFilter = () => {
  onChangeHandler([]);
};

const debouncedEmitChange = debounce((newFilter: ReportLocationFilter) => {
  emit("change", newFilter);
}, 300);

const onChangeHandler = (filters: ReportLocationFilter) => {
  debouncedEmitChange(filters);
};
</script>
