<template>
  <div class="flex flex-col gap-3 text-left p-3 border border-gray-400 rounded">
    <div class="flex">
      <div class="flex-1 truncate font-semibold">
        {{ t("analytics.critical_path.similar_components") }}
      </div>
      <XMarkIcon class="h-6 w-6 cursor-pointer shrink-0" @click="emit('close')" />
    </div>
    <div class="flex flex-col gap-2">
      <div v-for="tag in tagsToShow" :key="tag._id" class="flex items-center gap-5">
        <div class="flex-1 truncate" v-if="editedTag?._id !== tag._id">
          <span class="bg-gray-400 text-white px-1 py-0.5 rounded-md font-mono">
            {{ criticalPathTagLetterById[tag._id] }}
          </span>
          {{ tag.name }}
        </div>
        <input
          type="text"
          class="oaiCriticalPathTagNameInput flex-1 rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-yellow-600 text-sm leading-6"
          v-if="editedTag?._id === tag._id"
          :value="editedTag.name"
          @input="handleNameInput"
          @keydown.enter="handleEditConfirm"
          @keydown.esc.stop="editedTag = null"
        />
        <div class="shrink-0 flex items-center gap-2">
          <CheckIcon
            class="w-5 h-5 cursor-pointer text-gray-600 hover:text-yellow-600 shrink-0"
            v-if="editedTag?._id === tag._id"
            @click="handleEditConfirm"
          />
          <XMarkIcon
            class="w-5 h-5 cursor-pointer text-gray-600 hover:text-yellow-600 shrink-0"
            v-if="editedTag?._id === tag._id"
            @click="editedTag = null"
          />
          <PencilIcon
            class="w-4 h-4 shrink-0"
            :class="
              editedTag ? 'text-gray-200' : 'text-gray-600 hover:text-yellow-600 cursor-pointer'
            "
            @click="handleEdit(tag)"
            v-if="editedTag?._id !== tag._id"
          />
          <TrashIcon
            class="h-4 w-4 shrink-0"
            :class="
              editedTag ? 'text-gray-200' : 'text-gray-600 hover:text-yellow-600 cursor-pointer'
            "
            @click="handleDelete(tag)"
            v-if="editedTag?._id !== tag._id"
          />
        </div>
      </div>
    </div>
    <div>
      <button
        class="py-0.5 px-3 border rounded cursor-pointer border-gray-300 flex items-center gap-1 disabled:text-gray-300 disabled:cursor-default"
        @click="handleAddClick"
        :disabled="!!editedTag"
      >
        <PlusIcon class="h-5 w-5" />{{ t("buttons.add") }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CheckIcon } from "@heroicons/vue/24/outline";
import { PencilIcon, PlusIcon, TrashIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import { v4 as uuidv4 } from "uuid";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useConfirmationModal } from "shared/composables/toast";
import { CriticalPathEx, CriticalPathContext, CriticalPathTag } from "shared/types/CriticalPath";
import { useCriticalPathTagLetterById } from "shared/views/critical_path/composables";

const addedTagId = "added";

const props = defineProps<{
  criticalPath: CriticalPathEx;
  context: CriticalPathContext;
  selectedNodeIds: string[];
}>();
const emit = defineEmits<{
  (eventName: "change", payload: CriticalPathEx): void;
  (eventName: "close"): void;
}>();

const { t } = useI18n();

const editedTag = ref<CriticalPathTag | null>(null);

const showConfirmationModal = useConfirmationModal();

const criticalPathTagLetterById = useCriticalPathTagLetterById(computed(() => props.criticalPath));

const tagsToShow = computed<CriticalPathTag[]>(() => {
  if (!editedTag.value || editedTag.value._id !== addedTagId) {
    return props.criticalPath.tags;
  }
  return [...props.criticalPath.tags, editedTag.value];
});

const focusInputDelayed = () => {
  setTimeout(() => {
    const inputElement = document.querySelector(".oaiCriticalPathTagNameInput") as HTMLInputElement;
    if (inputElement) {
      inputElement.focus();
      inputElement.select();
    }
  }, 100);
};

const handleNameInput = (event: Event) => {
  if (!editedTag.value) {
    return;
  }
  const inputElement = event.currentTarget as HTMLInputElement;
  editedTag.value.name = inputElement.value;
};

const handleEdit = (tag: CriticalPathTag) => {
  if (editedTag.value) {
    return;
  }
  editedTag.value = { ...tag };
  focusInputDelayed();
};

const defaultNewTagName = computed(() => {
  if (props.selectedNodeIds[0]) {
    const node = props.context.criticalPathNodesById[props.selectedNodeIds[0]];
    if (node) {
      const name = props.context.tagsById[node.section_id]?.name;
      if (name) {
        return name;
      }
    }
  }
  return t("analytics.critical_path.new_tag");
});

const getFallbackTagName = () => {
  if (!editedTag.value || editedTag.value._id === addedTagId) {
    return defaultNewTagName.value;
  }
  const oldTag = props.criticalPath.tags.find((tag) => tag._id === editedTag.value?._id);
  return oldTag?.name || defaultNewTagName.value;
};

const handleEditConfirm = () => {
  if (!editedTag.value) {
    return;
  }
  const isAdding = editedTag.value._id === addedTagId;
  const finalTag = {
    ...editedTag.value,
    _id: isAdding ? uuidv4() : editedTag.value._id,
    name: editedTag.value.name || getFallbackTagName(),
  };
  const newTags = isAdding
    ? [...props.criticalPath.tags, finalTag]
    : props.criticalPath.tags.map((tag) => (tag._id === finalTag?._id ? finalTag : tag));
  emit("change", {
    ...props.criticalPath,
    tags: newTags,
  });
  editedTag.value = null;
};

const handleAddClick = () => {
  if (editedTag.value) {
    return;
  }
  editedTag.value = {
    _id: addedTagId,
    name: defaultNewTagName.value,
    type: "component_similarity",
  };
  focusInputDelayed();
};

const handleDelete = (tag: CriticalPathTag) => {
  if (editedTag.value) {
    return;
  }
  showConfirmationModal({
    header: "Confirmation",
    message: `Tag "${tag.name}" will be deleted`,
    confirmAction: "Delete tag",
    cancelAction: "Cancel",
  }).then((confirmed) => {
    if (confirmed) {
      emit("change", {
        ...props.criticalPath,
        tags: props.criticalPath.tags.filter(({ _id }) => _id !== tag._id),
        nodes: props.criticalPath.nodes.map((node) => ({
          ...node,
          tags: node.tags.filter((tagId) => tagId !== tag._id),
        })),
      });
    }
  });
};
</script>
