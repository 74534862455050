<template>
  <Component
    :is="widgets[report.type]"
    v-bind="propsToBind"
    :reportId="report._id"
    :filters="report.filters"
    :config="report.config"
    :aggregation="report.aggregation"
    :title="report.name"
    @updateUseWhiteIcons="emit('updateUseWhiteIcons', $event)"
    @widgetRouteClicked="emit('widgetRouteClicked')"
    @reportInfoClick="trackEvent('reports_info_click', { type: report.type })"
  />
</template>

<script lang="ts" setup>
import { Component as VueComponent, computed } from "vue";
import { useTrackEvent } from "shared/composables/tracking";
import { DashboardGridSize } from "@/types/Dashboard";
import { Report, ReportPlotType } from "@/types/Report";
import CycleTimesPlot from "@/views/reports/plots/cycle_times/CycleTimesPlot.vue";
import MilestonePlot from "@/views/reports/plots/milestone/MilestonePlot.vue";
import QueryValuePlot from "@/views/reports/plots/query_value/QueryValuePlot.vue";
import UnitValuesPlot from "@/views/reports/plots/unit_values/UnitValuesPlot.vue";
import WorkingHourCurvePlot from "@/views/reports/plots/working_hour_curve/WorkingHourCurvePlot.vue";

const widgets: Record<ReportPlotType, VueComponent> = {
  cycle_times: CycleTimesPlot,
  working_hour_curve: WorkingHourCurvePlot,
  unit_values: UnitValuesPlot,
  milestone: MilestonePlot,
  query_value: QueryValuePlot,
};

const props = defineProps<{
  report: Report;
  title?: string;
  hidePopover?: boolean;
  width?: number;
  height?: number;
  hideLegend?: boolean;
  spacingX?: number;
  spacingY?: number;
  isOnTheDashboard?: boolean;
  dashboardGridSize?: DashboardGridSize;
}>();

const emit = defineEmits(["updateUseWhiteIcons", "widgetRouteClicked"]);

const trackEvent = useTrackEvent();
const propsToBind = computed(() => {
  const { report, ...rest } = props;
  return rest;
});
</script>
