<template>
  <div>
    <div v-for="group in criticalPathGroups.filter((group) => group.delta)" :key="group.lastNodeId">
      <CriticalPathDelay
        v-if="group.notMatchingNodeDeltas.length === 0"
        :deltaForNode="group.delta as CriticalPathDelta"
        :noBackgroundHighlight="true"
        cls="absolute right-0"
        :style="{
          top: `${criticalPathDelayPositionById[group.lastNodeId] || 0}px`,
        }"
        @click="openCriticalPathModal(group.lastNodeId)"
      />
      <OaiTooltip
        v-else
        cls="absolute right-[15px]"
        :style="{
          top: `${criticalPathDelayPositionById[group.lastNodeId] || 0}px`,
        }"
      >
        <InformationCircleIcon class="w-6 h-6" />
        <template #tooltip>
          <div>
            <div>{{ t("analytics.reports.incomplete_path_no_delta") }}</div>
            <div>
              <div
                v-for="difference in group.notMatchingNodeDeltas.slice().reverse()"
                :key="difference.node._id"
              >
                {{ getNodeNameByTags(difference.node, context) }}
              </div>
            </div>
          </div>
        </template>
      </OaiTooltip>
    </div>
    <CriticalPathModal
      v-if="isCriticalPathModalOpen"
      @close="closeCriticalPathModal"
      origin="query_value_widget"
      :initialSelectedNodeId="criticalPathModalInitialSelectedNodeId"
    />
  </div>
</template>

<script setup lang="ts">
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { CriticalPathContext, CriticalPathDelta } from "shared/types/CriticalPath";
import CriticalPathDelay from "shared/views/critical_path/components/CriticalPathDelay.vue";
import CriticalPathModal from "shared/views/critical_path/components/CriticalPathModal.vue";
import { getNodeNameByTags } from "@/views/reports/plots/milestone/milestoneCriticalPath";
import { MilestonePlotGroup } from "@/views/reports/plots/milestone/types";

const { t } = useI18n();

const props = defineProps<{
  criticalPathGroups: MilestonePlotGroup[];
  context: CriticalPathContext;
  chartHeight: number;
}>();

const isCriticalPathModalOpen = ref(false);
const criticalPathModalInitialSelectedNodeId = ref<string | undefined>(undefined);

const criticalPathDelayPositionById = computed(() => {
  const totalItemsCount = props.criticalPathGroups.reduce(
    (acc, group) => acc + group.nodeDeltas.length,
    0,
  );
  const topSpacing = 35;
  const xAxisHeight = 45;
  const labelHeight = 20;
  const map: Record<string, number> = {};
  let y = topSpacing;
  const reversedCriticalPathGroups = props.criticalPathGroups.slice().reverse();
  for (const group of reversedCriticalPathGroups) {
    const height =
      (props.chartHeight - topSpacing - xAxisHeight) * (group.nodeDeltas.length / totalItemsCount);
    map[group.lastNodeId] = y + height / 2 - labelHeight / 2;
    y += height;
  }
  return map;
});

const openCriticalPathModal = (nodeId?: string) => {
  criticalPathModalInitialSelectedNodeId.value = nodeId;
  isCriticalPathModalOpen.value = true;
};

const closeCriticalPathModal = () => {
  criticalPathModalInitialSelectedNodeId.value = undefined;
  isCriticalPathModalOpen.value = false;
};
</script>
