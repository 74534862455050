<template>
  <Sidebar>
    <div class="max-h-[100vh] h-screen flex flex-col px-4 lg:pl-6">
      <PageHeader>
        <div class="flex items-center gap-2 leading-none py-2">
          {{ $t("analytics.processes.process_data_table") }}

          <span
            class="underline hover:text-yellow font-normal text-sm text-gray-600 cursor-pointer whitespace-nowrap ml-2"
            @click="processTable?.resetAllFilters"
            v-if="processTable?.activeFilters"
          >
            {{ t("analytics.processes.clear_filters") }}
          </span>
        </div>
        <template #content>
          <div class="flex items-center gap-4">
            <button
              v-if="processes.length > 0"
              class="flex items-center gap-1 hover:underline"
              @click="
                openWorkingDaysCalendar = true;
                trackEvent('activity-calendar_view', { origin: 'activity_log' });
              "
            >
              <CalendarDaysIcon class="w-4 text-yellow-600" />
              <span class="text-sm">{{ $t("analytics.processes.show_activity_calendar") }}</span>
            </button>
            <div class="flex items-center flex-row gap-2" v-if="handleExportToExcel">
              <Menu as="div" class="text-left leading-none font-normal">
                <MenuButton
                  class="flex items-center rounded-full text-gray-500 hover:text-gray-600"
                >
                  <EllipsisVerticalIcon class="h-8 w-8" aria-hidden="true" />
                </MenuButton>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-5 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray ring-opacity-5 focus:outline-none"
                  >
                    <div class="py-1 divide-y">
                      <MenuItem as="template" v-slot="{ active }">
                        <Menu
                          as="div"
                          :class="[
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'extraMenuItem',
                          ]"
                        >
                          <MenuButton
                            class="flex items-center w-full rounded-full gap-2"
                            @click="handleExportToExcel"
                          >
                            <FontAwesomeIcon icon="fa-solid fa-file-excel" class="h-4 text-green" />
                            <span>{{ t("buttons.export_plain") }} Excel</span>
                          </MenuButton>
                        </Menu>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </template>
      </PageHeader>

      <NoDataYet v-if="noData" desc="analytics.processes.no_data" />
      <div
        v-if="isLoading"
        class="absolute inset-0 flex items-center justify-center"
        style="z-index: 9999"
      >
        <LoadingSection :loading="true" />
      </div>
      <div class="overflow-hidden flex flex-1 relative border-box" v-else ref="processTableRef">
        <div
          class="flex-1 flex overflow-hidden flex-col"
          @contextmenu="collectContextMenuStatistics"
        >
          <Table
            :columns="columns"
            :data="data"
            @update:data="handleDataUpdate"
            :onDateFilter="handleFilterDate"
            :onRowDbClick="(d: Data) => handleRowClick(d, true)"
            :onRowClick="handleRowClick"
            :exportToExcel="handleExportToExcel"
            :sortedBy="sortedBy"
            :summary="summary"
            :hierarchyTags="hierarchyTags"
            :width="contentWidth - sideBarWidth"
            ref="processTable"
          />
        </div>

        <div
          class="max-w-[50%] min-w-[450px] px-4 relative overflow-y-auto"
          ref="processSideBarRef"
          :style="{
            width: sideBarWidth > 0 ? sideBarWidth + 'px' : 450 + 'px',
          }"
        >
          <TableResizeSlider
            :width="sliderWidth"
            :containerRef="getContainerRef"
            :currentX="0"
            @moved="handleFullSliderMoved"
            @started="draggingSliderName = 'full'"
            @ended="draggingSliderName = null"
          />
          <ProcessSideBarContent
            :processes="remapProcessesToSidebar(selectedProcesses)"
            :open="true"
            @processUpdate="handleProcessChange"
          />
        </div>
      </div>
      <Modal
        :open="openWorkingDaysCalendar"
        @close="openWorkingDaysCalendar = false"
        custom-cls="overflow- z-[9999]"
        v-if="openWorkingDaysCalendar"
      >
        <template #title
          ><div class="text-left overflow-visible text-ellipsis w-5/6">
            {{ $t("analytics.processes.process_data_table") }}
          </div></template
        >
        <template #content v-if="processes">
          <WorkingDaysCalendar :processes="extractedProcesses" />
        </template>
      </Modal>
    </div>
  </Sidebar>
</template>

<script lang="ts" setup>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { EllipsisVerticalIcon } from "@heroicons/vue/24/outline";
import { CalendarDaysIcon } from "@heroicons/vue/24/solid";
import { format, isWithinInterval, parse } from "date-fns";
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import * as XLSX from "xlsx";
import LoadingSection from "shared/components/loading_state/LoadingSection.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useShortenedProcessesWithTags } from "shared/composables/process";
import { useTrackEvent } from "shared/composables/tracking";
import { ShortenedProcessWithTags } from "shared/types/Process";
import NoDataYet from "@/components/layout/NoDataYet.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import TableResizeSlider from "@/components/other/TableResizeSlider.vue";
import WorkingDaysCalendar from "@/components/working_days/WorkingDaysCalendar.vue";
import { useHasProjectFeature } from "@/composables/project";
import textService from "@/services/textService";
import { TableResizeSliderEvent } from "@/types/Tables";
import ProcessSideBarContent from "@/views/planner/components/ProcessSideBarContent.vue";
import Table from "@/views/process_table/components/Table.vue";
import { usePlainPresets } from "@/views/process_table/components/plainView";
import { Column, Data } from "@/views/process_table/types";

const { t } = useI18n();
const { query } = useRoute();
const processTable = ref<typeof Table>();
const data = ref<Data[]>([]);
const filteredDataLength = ref(0);
const selectedProcess = ref<Data | null>(null);
const selectedProcesses = ref<ShortenedProcessWithTags[]>([]);
const selectedResourceName = ref("");
const columns = ref<Column[]>([]);
const summary = ref<Record<string, string>>();
const sortedBy = ref<"end" | "date">("date");
const workingHoursFeatureEnabled = useHasProjectFeature("working_hours");
const { columns: pColumns, dataMapper: pDataMapper, getSummary: pGetSummary } = usePlainPresets();
const route = useRoute();

const sliderWidth = 20;
const draggingSliderName = ref<SliderName | null>(null);
type SliderName = "full";
const processSideBarRef = ref<HTMLDivElement | null>(null);
const getContainerRef = () => processTableRef;

const openWorkingDaysCalendar = ref(false);

const trackEvent = useTrackEvent();

const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();

const {
  shortenedProcessesWithTags,
  isLoading: areShortenedProcessesWithTagsLoading,
  refetchShortenedProcessesWithTags,
} = useShortenedProcessesWithTags();

const processes = computed(() => shortenedProcessesWithTags.value || []);

const noData = computed(() => !isLoading.value && processes.value.length === 0);

const handleResize = () => {
  if (processTableRef.value) {
    contentWidth.value = processTableRef.value.getBoundingClientRect().width;
  }
};

const getQueryStringsData = () => {
  Object.keys(route.query).forEach((key) => {
    if (key === "processes" || key === "processTypes") {
      columns.value.forEach((item) => {
        if (item.field === "process") {
          item.filters?.forEach((filter) => {
            if (
              filter.filterValue &&
              typeof filter.filterValue === "object" &&
              !Array.isArray(filter.filterValue)
            ) {
              filter.filterValue[key] = route.query[key]
                ? (route.query[key] as string).split(",")
                : [];
            }
          });
        }
      });
    }
    if (key === "location") {
      columns.value.forEach((item) => {
        if (item.field === "location") {
          item.filters?.forEach((filter) => {
            if (filter.filterValue) {
              filter.filterValue = route.query[key] ? (route.query[key] as string).split(",") : [];
            }
          });
        }
      });
    }
    if (key === "date") {
      columns.value.forEach((item) => {
        if (item.field === "date") {
          item.filters?.forEach((filter) => {
            if (filter.filterValue && Array.isArray(filter.filterValue)) {
              filter.filterValue = route.query[key]
                ? (route.query[key] as string)
                    .split(",")
                    .map((dateStr) => parse(dateStr, "yyyy-MM-dd", new Date()))
                : [];
            }
          });
        }
      });
    }
    if (key === "process_ids") {
      columns.value.forEach((item) => {
        if (item.field === "process_ids") {
          item.filters?.forEach((filter) => {
            filter.filterValue = (route.query[key] as string).split(",");
          });
        }
      });
    }
  });
};

const isLoading = computed(
  () => areShortenedProcessesWithTagsLoading.value || areHierarchyTagsLoading.value,
);

const extractedProcesses = ref();

const update = () => {
  extractedProcesses.value = processes.value.slice();
  const mappedData = pDataMapper(processes.value);
  summary.value = pGetSummary(mappedData);

  nextTick(() => {
    data.value = mappedData;
    updateSelectedProcess(data.value);
    if (processTableRef.value) {
      contentWidth.value = processTableRef.value.getBoundingClientRect().width;
    }
  });
};

watch([processes, hierarchyTags], () => {
  update();
});

onMounted(() => {
  columns.value = pColumns;
  getQueryStringsData();
  update();
  trackEvent("activity-log_view");
  window.addEventListener("resize", handleResize);
});

onUnmounted(() => {
  window.removeEventListener("resize", handleResize);
});

const collectContextMenuStatistics = () => {
  trackEvent("process_table_context_menu-open");
};

const handleFilterDate = (value: [Date, Date], data: Data[]) => {
  const [start, end] = value;
  return data.filter((item) => isWithinInterval(item.date as Date, { start, end }));
};

const handleDataUpdate = (updatedData: Data[]) => {
  updateSelectedProcess(updatedData);

  extractedProcesses.value = updatedData.map((item) => ({
    ...item,
    date: format(new Date(item.date as string | number | Date), "yyyy-MM-dd"),
  }));
  summary.value = pGetSummary(updatedData);
  filteredDataLength.value = updatedData.length;
};

const updateSelectedProcess = (data: Data[]) => {
  if (data.length) {
    setTimeout(() => {
      const initialProcess = data.find((item) => item?._id === query.processId) || data[0];
      if (initialProcess) {
        if (
          !selectedProcess.value ||
          !data.some((item) => item._id === selectedProcess.value?._id) ||
          initialProcess !== selectedProcess.value
        ) {
          if (selectedProcess.value) {
            selectedProcess.value.class = "";
          }
          initialProcess.class = "bg-yellow-200";
          selectedProcess.value = initialProcess;
          selectedProcesses.value = [initialProcess as ShortenedProcessWithTags];
        }
      }

      if (selectedProcess.value && query.processId) {
        document
          .getElementById(selectedProcess.value._id as string)
          ?.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }, 200);
  }
};

const remapProcessesToSidebar = (processes: ShortenedProcessWithTags<Date>[]) => {
  return processes.map((item) => {
    const { location, name } = item as ShortenedProcessWithTags & {
      location: Record<string, string>;
      name: string;
    };
    return { ...item, tags: location, name: name };
  }) as unknown as (ShortenedProcessWithTags & { name: string })[];
};

const handleProcessChange = async (process: ShortenedProcessWithTags & { old_id: string }) => {
  query.processId = process._id;

  columns.value = columns.value.map((column) => {
    if (column.field === sortedBy.value) {
      return {
        ...column,
        sortDirection: column.sortDirection === "asc" ? "desc" : "asc",
      };
    }

    return column;
  });

  refetchShortenedProcessesWithTags();
};

const handleRowClick = (row: Data, dbClick = false) => {
  trackEvent("activity-log_process_click");

  if (dbClick) {
    return;
  }

  selectedProcesses.value = [row as ShortenedProcessWithTags];
  selectedResourceName.value = [row.building, row.section, row.level, row.component]
    .filter(Boolean)
    .join(" > ");

  if (selectedProcess.value) {
    selectedProcess.value.class = "";
  }

  row.class = "bg-yellow-200";
  selectedProcess.value = row;
};

const handleExportToExcel = () => {
  trackEvent("activity-log_export-excel_click");
  const header = pColumns
    .filter((col) => col.field !== "working_hours" || workingHoursFeatureEnabled)
    .flatMap((col) => {
      if (col.field === "location") {
        return Object.keys(data.value[0]?.[col.field] || {}).map((tag) =>
          t(`analytics.processes.${tag}`),
        );
      }
      return col.label;
    });
  const mappedData: unknown[][] = [];

  mappedData.push(header);

  data.value.forEach((item) => {
    const row: unknown[] = [];
    pColumns.forEach((col) => {
      if (col.field === "location") {
        Object.values((item?.location as Record<string, string>) || {}).forEach((location) =>
          row.push(location),
        );
        return;
      }
      if (col.field !== "working_hours" || workingHoursFeatureEnabled) {
        row.push(col.render ? col.render(item[col.field]) : item[col.field]);
      }
    });
    mappedData.push(row);
  });
  const workbook = XLSX.utils.book_new();
  const worksheetProcessExport = XLSX.utils.aoa_to_sheet(mappedData);
  worksheetProcessExport["!cols"] = mappedData[0].map(() => ({ width: 30 }));
  XLSX.utils.book_append_sheet(workbook, worksheetProcessExport, t("app_features.processes"));
  XLSX.writeFile(
    workbook,
    `oculai_${textService.toNormalizedFileName(t("app_features.processes"))}.xlsx`,
  );
};

const sideBarWidth = ref(450);

const handleFullSliderMoved = (event: TableResizeSliderEvent) => {
  contentWidth.value = event.containerRect.width;

  const newSidebarWidth = event.containerRect.width - event.x;
  if (newSidebarWidth > 450 && newSidebarWidth < event.containerRect.width / 2) {
    sideBarWidth.value = newSidebarWidth;
  }
};

const processTableRef = ref();

const contentWidth = ref(0);
</script>
