import {
  addDays,
  eachDayOfInterval,
  format,
  getDay,
  intervalToDuration,
  isSameDay,
  parse,
} from "date-fns";
import {
  DurationSettings,
  TimeInterval,
  DateInterval,
  WeekDayTimeIntervals,
  WorkingDaysAndHoursMap,
} from "../types/ProjectDurationSettings";

const calculateWorkingHours = (timeInterval: TimeInterval) => {
  if (!timeInterval.start_time || !timeInterval.end_time) {
    return 0;
  }
  const referenceDate = new Date();
  const interval = intervalToDuration({
    start: parse(timeInterval.start_time, "HH:mm", referenceDate),
    end: parse(timeInterval.end_time, "HH:mm", referenceDate),
  });

  const hours = interval?.hours || 0;
  const minutes = interval?.minutes || 0;

  return hours + minutes / 60 - (timeInterval.breaks || 0);
};

const calculateWorkingDayFromWorkingHours = (workingHours: number) => {
  if (workingHours === 0) {
    return 0;
  }
  return workingHours < 8 ? 0.5 : 1;
};

const calculateWorkingDay = (timeInterval: TimeInterval) => {
  const workingHours = calculateWorkingHours(timeInterval);
  return calculateWorkingDayFromWorkingHours(workingHours);
};

const calculateWorkingDayAndHour = (day: TimeInterval) => {
  const workingHours = calculateWorkingHours(day);
  const workingDays = calculateWorkingDayFromWorkingHours(workingHours);
  return { workingHours, workingDays, startTime: day.start_time, endTime: day.end_time };
};

const calculateWorkingDaysAndHoursMap = (workingHours: WeekDayTimeIntervals) =>
  [
    calculateWorkingDayAndHour(workingHours.Sun),
    calculateWorkingDayAndHour(workingHours.Mon),
    calculateWorkingDayAndHour(workingHours.Tue),
    calculateWorkingDayAndHour(workingHours.Wed),
    calculateWorkingDayAndHour(workingHours.Thu),
    calculateWorkingDayAndHour(workingHours.Fri),
    calculateWorkingDayAndHour(workingHours.Sat),
  ] as WorkingDaysAndHoursMap;

const mergeDateIntervals = (dateIntervals: DateInterval[]) => {
  if (dateIntervals.length <= 1) {
    return dateIntervals;
  }

  const mergedDateIntervals: DateInterval[] = [
    {
      start_date: dateIntervals[0].start_date,
      end_date: dateIntervals[0].end_date,
    },
  ];

  for (let i = 1; i < dateIntervals.length; i++) {
    const currentDateInterval = dateIntervals[i];
    const lastMergedDateInterval = mergedDateIntervals[mergedDateIntervals.length - 1];

    if (currentDateInterval.start_date <= lastMergedDateInterval.end_date) {
      lastMergedDateInterval.end_date =
        lastMergedDateInterval.end_date > currentDateInterval.end_date
          ? lastMergedDateInterval.end_date
          : currentDateInterval.end_date;
    } else {
      mergedDateIntervals.push({
        start_date: currentDateInterval.start_date,
        end_date: currentDateInterval.end_date,
      });
    }
  }

  return mergedDateIntervals;
};

const nonWorkingDaysSettingsDateFormat = "yyyy-MM-dd";

const calculateSettings = (
  workingHours: WeekDayTimeIntervals,
  dateIntervals: DateInterval[],
): DurationSettings => {
  const workingDaysAndHoursMap = calculateWorkingDaysAndHoursMap(workingHours);
  const mergedNonWorkingDays = mergeDateIntervals(dateIntervals);
  const nonWorkingDays = new Set(
    mergedNonWorkingDays
      .map((dateInterval) =>
        eachDayOfInterval({ start: dateInterval.start_date, end: dateInterval.end_date }),
      )
      .flatMap((dates) => dates.map((date) => format(date, nonWorkingDaysSettingsDateFormat))),
  );
  return {
    workingDaysAndHoursMap,
    nonWorkingDays,
  };
};

// Same day (start/end) is counted as 1 working day
// Two consecutive working days are counted as 2 and so on
// Unless excludeStartDay is set to true, in which case same day is 0 and two consecutive days is 1 (first day skipped)
// Non-working days are skipped
const calculateDuration = (
  durationSettings: DurationSettings,
  start: Date,
  end: Date,
  options?: { excludeStartDay?: boolean },
) => {
  const excludeStartDay = options?.excludeStartDay !== undefined ? options.excludeStartDay : false;

  if (excludeStartDay && isSameDay(start, end)) {
    return { workingDays: 0, workingHours: 0 };
  }

  const realStart = start <= end ? start : end;
  const realEnd = start <= end ? end : start;

  const finalStart = excludeStartDay ? addDays(realStart, 1) : realStart;

  const result = eachDayOfInterval({ start: finalStart, end: realEnd }).reduce(
    (acc, day) => {
      if (durationSettings.nonWorkingDays.has(format(day, nonWorkingDaysSettingsDateFormat))) {
        return acc;
      }
      const duration = durationSettings.workingDaysAndHoursMap[getDay(day)];
      acc.workingDays += duration.workingDays;
      acc.workingHours += duration.workingHours;
      return acc;
    },
    { workingDays: 0, workingHours: 0 },
  );

  return {
    workingDays: start <= end ? result.workingDays : -result.workingDays,
    workingHours: start <= end ? result.workingHours : -result.workingHours,
  };
};

const calculateEndDate = (
  durationSettings: DurationSettings,
  startDate: Date,
  workingDaysToAdd: number,
  options?: { excludeStartDay?: boolean },
) => {
  if (workingDaysToAdd === 0) {
    return startDate;
  }

  const maxIterations = 10000;
  const excludeStartDay = options?.excludeStartDay !== undefined ? options.excludeStartDay : false;

  const sign = Math.sign(workingDaysToAdd);
  let date = startDate;
  let workingDays = Math.max(1, Math.abs(workingDaysToAdd));

  if (!excludeStartDay) {
    workingDays -= 1;
  }

  for (let i = maxIterations; i > 0; i--) {
    if (workingDays <= 0) {
      return date;
    }

    date = addDays(date, sign);

    if (!durationSettings.nonWorkingDays.has(format(date, nonWorkingDaysSettingsDateFormat))) {
      const duration = durationSettings.workingDaysAndHoursMap[getDay(date)];
      workingDays -= duration.workingDays;
    }
  }
};

export default {
  calculateWorkingDay,
  calculateWorkingHours,
  calculateDuration,
  calculateSettings,
  calculateEndDate,
};
