import { useQuery } from "@tanstack/vue-query";
import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import OpsProcessesRepository from "shared/repositories/OpsProcessesRepository";
import { ShortenedProcessWithTags } from "shared/types/Process";
import logger from "../services/logger";
import { useCurrentCustomerName, useCurrentSiteId, useHasPermission } from "./project";
import { useCustomToast } from "./toast";

export const useShortenedProcessesWithTags = (options?: Ref<{ enabled?: boolean }>) => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const { t } = useI18n();
  const hasPermission = useHasPermission("processes_user");

  const { data, isFetching, error, refetch } = useQuery<ShortenedProcessWithTags[] | undefined>({
    queryKey: ["shortened-processes-with-tags", customerName, siteId],
    queryFn: () => {
      if (hasPermission) {
        return OpsProcessesRepository.loadShortenedProcessesWithTags(customerName, siteId);
      }
      return [];
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("analytics.processes.unable_to_load"));
      return false;
    },
    enabled: computed(() => (options?.value.enabled !== undefined ? options.value.enabled : true)),
  });

  const shortenedProcessesWithTags = computed(() => data.value);

  return {
    shortenedProcessesWithTags,
    isLoading: isFetching,
    error,
    refetchShortenedProcessesWithTags: refetch,
  };
};
