import { parseISO } from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import { apiClient } from "shared/repositories/clients";
import { Report, ReportFilters, ReportToCreateOrUpdate } from "@/types/Report";

const parseUtcDate = (dateText: string): Date => toZonedTime(parseISO(dateText), "UTC");

const formatUtcDate = (date: Date): string =>
  fromZonedTime(date, "UTC").toISOString().replace("Z", "+00:00");

const formatFilters = (filters: ReportFilters): ReportFilters<string> => {
  if ("daterange" in filters) {
    return {
      ...filters,
      daterange: {
        ...filters.daterange,
        start_date: filters.daterange.start_date && formatUtcDate(filters.daterange.start_date),
        end_date: filters.daterange.end_date && formatUtcDate(filters.daterange.end_date),
      },
    };
  }
  return filters;
};

const mapFilters = (filters: ReportFilters<string>): ReportFilters => {
  if ("daterange" in filters) {
    return {
      ...filters,
      daterange: {
        ...filters.daterange,
        start_date: filters.daterange.start_date
          ? parseUtcDate(filters.daterange.start_date)
          : null,
        end_date: filters.daterange.end_date ? parseUtcDate(filters.daterange.end_date) : null,
      },
    };
  }
  return filters;
};

const mapReport = (report: Report<string>): Report => ({
  ...report,
  filters: mapFilters(report.filters),
});

const formatReport = (report: ReportToCreateOrUpdate): ReportToCreateOrUpdate<string> => ({
  ...report,
  filters: formatFilters(report.filters),
});

const loadReports = (customerName: string, siteId: string): Promise<Report[]> =>
  apiClient
    .get<Report<string>[]>(`/report/${customerName}/${siteId}`)
    .then((response) => response.data.map((report) => mapReport(report)));

const createReport = async (
  customerName: string,
  siteId: string,
  payload: ReportToCreateOrUpdate,
) => {
  const { _id, type, ...rest } = formatReport(payload);
  return apiClient
    .post<Report<string>>(`/report/${customerName}/${siteId}/${type}`, rest)
    .then((response) => mapReport(response.data));
};

const updateReport = async (
  customerName: string,
  siteId: string,
  payload: ReportToCreateOrUpdate,
) => {
  const { _id, type, ...rest } = formatReport(payload);
  return apiClient
    .put<Report>(`/report/${customerName}/${siteId}/${type}/${_id}`, rest)
    .then((response) => mapReport(response.data));
};

const deleteReport = (customerName: string, siteId: string, id: string) =>
  apiClient
    .delete<void>(`/report/${customerName}/${siteId}/${id}`)
    .then((response) => response.data);

export default {
  loadReports,
  createReport,
  updateReport,
  deleteReport,
};
