import { ShortenedProcessWithTags } from "shared/types/Process";
import processDurationService from "@/services/processDurationService";
import { QueryValueContext, QueryValueSingleValue } from "@/types/reports/PlotQueryValue";

const calculateWorkingHoursForProcesses = (processes: ShortenedProcessWithTags[] | undefined) => {
  if (!processes) {
    return null;
  }
  return processDurationService.calculateWorkHoursSum(processes, true);
};

const calculateWorkingHoursMetric = (context: QueryValueContext): QueryValueSingleValue | null => {
  const { processes, previousPeriodProcesses } = context;

  if (!processes) {
    return null;
  }

  const value = calculateWorkingHoursForProcesses(processes);
  const previousPeriodValue = calculateWorkingHoursForProcesses(previousPeriodProcesses);
  return {
    value,
    previous_period_value: previousPeriodValue,
  };
};

export default calculateWorkingHoursMetric;
