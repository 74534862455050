<template>
  <OaiListbox
    :options="modes"
    name="mode"
    :defaultSelected="mode"
    :label="t('analytics.reports.plot_modes')"
    :onUpdate:selected="(value: string) => (mode = value, handleConfigChange())"
  />

  <label
    :class="['flex cursor-pointer items-center w-fit mt-4', mode === 'default' && 'cursor-pointer']"
  >
    <div
      v-if="mode !== 'default'"
      class="h-4 w-4 inline-block rounded border border-gray-300 bg-gray-100 cursor-not-allowed"
    />

    <input
      v-else
      type="checkbox"
      name="show_mean"
      class="h-4 w-4 rounded border-gray-300 text-yellow-500 focus:ring-yellow-500"
      v-model="showMean"
      @change="handleConfigChange"
    />
    <span class="ml-2">{{ t("analytics.reports.show_mean_working_hours") }}</span>
  </label>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { watch } from "vue";
import { useI18n } from "vue-i18n";
import OaiListbox from "shared/components/other/OaiListbox.vue";
import {
  WorkingHourCurveReportConfig,
  WorkingHourCurveReportFilters,
} from "@/types/reports/PlotWorkingHourCurve";

const props = defineProps<{
  config: WorkingHourCurveReportConfig;
  filters: WorkingHourCurveReportFilters;
}>();
const emit = defineEmits<{
  (eventName: "configChange", config: WorkingHourCurveReportConfig): void;
}>();

const { t } = useI18n();
const { defineField } = useForm();

const [mode] = defineField("mode");
const [showMean] = defineField("show_mean");

const modes = [
  { value: "default", name: t("analytics.reports.default_mode") },
  { value: "cumulative", name: t("analytics.reports.cumulative_mode") },
];

watch(
  () => props.config,
  () => {
    mode.value = props.config.mode;
    showMean.value = props.config.show_mean;
  },
  { immediate: true },
);

const handleConfigChange = () => {
  const config: WorkingHourCurveReportConfig = {
    mode: mode.value,
    show_mean: showMean.value,
  };
  emit("configChange", config);
};
</script>
