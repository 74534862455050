<template>
  <div class="aspect-h-3 w-full">
    <div class="container relative" ref="container">
      <div class="image-container">
        <div class="slider-image overflow-hidden image-before z-10">
          <canvas
            class="absolute top-0 left-0 z-10"
            :id="`section-mask-before-canvas-${sectionMaskCanvasId}`"
          />
          <img
            ref="beforeImage"
            alt="Before image"
            class="h-full w-full absolute left-0 top-0 max-w-none"
            @load="initSectionMaskCanvas"
            :src="beforeImg.url ? beforeImg.url : default_img"
          />
        </div>
        <canvas
          class="absolute top-0 left-0"
          :id="`section-mask-after-canvas-${sectionMaskCanvasId}`"
        />

        <img
          alt="After image"
          class="image-after slider-image"
          :src="afterImg.url ? afterImg.url : default_img"
        />
      </div>
      <input
        type="range"
        min="0"
        max="100"
        value="50"
        aria-label="Percentage of before photo shown"
        class="slider z-20"
        :class="['slider' + camera]"
      />
      <div class="slider-line z-10" aria-hidden="true"></div>
      <div class="slider-button z-20" aria-hidden="true">
        <LoadingSpinner v-if="loadingImage" size=" h-6 w-6" />
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none"></rect>
          <line
            x1="128"
            y1="40"
            x2="128"
            y2="216"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <line
            x1="96"
            y1="128"
            x2="16"
            y2="128"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <polyline
            points="48 160 16 128 48 96"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></polyline>
          <line
            x1="160"
            y1="128"
            x2="240"
            y2="128"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></line>
          <polyline
            points="208 96 240 128 208 160"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="16"
          ></polyline>
        </svg>
      </div>
    </div>
    <div class="flex justify-between pt-2 sm:text-sm text-xs">
      <div class="justify-self-center">
        <div class="flex justify-center items-center pt-2">
          <button
            :disabled="!beforeImg.previous"
            type="button"
            :class="['nav-arrows', !beforeImg.previous ? 'opacity-30' : '']"
            @click="prevBeforeImg()"
          >
            <ChevronLeftIcon
              :class="['h-3.5 w-3.5 text-gray-500', { 'text-yellow-500': beforeDateError }]"
              aria-hidden="true"
            />
          </button>
          <input
            type="date"
            :max="maxDate"
            class="p-0.5 border-transparent focus:border-yellow focus:shadow-none focus:ring-0 text-xs sm:text-sm bg-transparent"
            v-model="beforeTime"
            @change="changeBeforeTime(($event.target as HTMLInputElement).value)"
          />
          <button
            :disabled="!beforeImg.next"
            type="button"
            :class="['nav-arrows', !beforeImg.next ? 'opacity-30' : '']"
            @click="nextBeforeImg()"
          >
            <ChevronRightIcon
              :class="[{ 'text-yellow-500': beforeDateError }, 'h-3.5 w-3.5 text-gray-500']"
              aria-hidden="true"
            />
          </button>
        </div>
        <small class="text-red text-center" v-if="beforeDateError">{{
          $t("camera.compare.no_image")
        }}</small>
      </div>
      <div class="justify-self-center">
        <div class="flex justify-center items-center pt-2">
          <button
            :disabled="!afterImg.previous"
            @click="prevAfterImg()"
            type="button"
            :class="['nav-arrows', !afterImg.previous ? 'opacity-30' : '']"
          >
            <ChevronLeftIcon class="h-3.5 w-3.5 text-gray-500" aria-hidden="true" />
          </button>
          <input
            type="date"
            :max="maxDate"
            class="p-0.5 border-transparent focus:border-yellow focus:shadow-none focus:ring-0 sm:text-sm text-xs bg-transparent"
            v-model="afterTime"
            @change="changeAfterTime(($event.target as HTMLInputElement).value)"
          />
          <button
            :disabled="!afterImg.next"
            @click="nextAfterImg()"
            type="button"
            :class="['nav-arrows', !afterImg.next ? 'opacity-30' : '']"
          >
            <ChevronRightIcon class="h-3.5 w-3.5 text-gray-500" aria-hidden="true" />
          </button>
        </div>
        <small class="text-red text-center" v-if="afterDateError">{{
          $t("camera.compare.no_image")
        }}</small>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/solid";
import axios, { CanceledError } from "axios";
import { format, parseISO, subDays, parse } from "date-fns";
import * as fabric from "fabric";
import { v4 as uuid } from "uuid";
import { defineComponent, PropType } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import logger from "shared/services/logger";
import { SectionMask } from "shared/types/SectionMask";
import defaultProjectThumbnailUrl from "@/assets/imgs/default-project-thumbnail.jpg";
import CameraRepository from "@/repositories/CameraRepository";
import { GalleryPaginatedImg } from "@/types/Camera";

type ModifiedSectionMask = SectionMask & { name: string };

export default defineComponent({
  name: "ImagesComparison",
  props: {
    camera: {
      type: String as PropType<string>,
      required: true,
    },
    initialBeforeDate: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    initialAfterDate: {
      type: String as PropType<string>,
      required: false,
      default: "",
    },
    hideNoImageToast: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    sectionMasks: {
      type: Array as PropType<ModifiedSectionMask[]>,
      required: false,
    },
  },
  components: { LoadingSpinner, ChevronLeftIcon, ChevronRightIcon },
  data() {
    return {
      showOverlay: true as boolean,
      default_img: defaultProjectThumbnailUrl,
      beforeImg: {} as Record<string, string | null | Date> as GalleryPaginatedImg,
      afterImg: {} as Record<string, string | null | Date> as GalleryPaginatedImg,
      beforeTime: this.initialBeforeDate as string,
      afterTime: this.initialAfterDate as string,
      loadingImage: false as boolean,
      beforeDateError: false as boolean,
      afterDateError: false as boolean,
      isFirstLoad: true as boolean,
      noData: false as boolean,
      beforeDateAbortController: null as AbortController | null,
      afterDateAbortController: null as AbortController | null,
      sectionMaskCanvasId: uuid(),
      beforeSectionMaskCanvas: null as unknown as fabric.StaticCanvas,
      afterSectionMaskCanvas: null as unknown as fabric.StaticCanvas,
    };
  },
  mounted() {
    this.initImg();
  },
  unmounted() {
    if (this.beforeDateAbortController) {
      this.beforeDateAbortController.abort();
    }
    if (this.afterDateAbortController) {
      this.afterDateAbortController.abort();
    }
  },
  emits: ["beforeChanged", "afterChanged", "noData"],
  watch: {
    beforeImg() {
      this.$emit("beforeChanged", this.beforeTime);
      this.updateSectionMasks();
    },
    afterImg() {
      this.$emit("afterChanged", this.afterTime);
      this.updateSectionMasks();
    },
    initialBeforeDate: {
      handler: function (value: string) {
        this.beforeTime = value;
        this.getCameraImages("after_sunrise", value);
        this.updateSectionMasks();
      },
      immediate: true,
    },
    initialAfterDate: {
      handler: function (value: string) {
        this.afterTime = value;
        this.getCameraImages("before_sunset", value);
        this.updateSectionMasks();
      },
      immediate: true,
    },
  },
  computed: {
    maxDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
  },
  methods: {
    format,
    async getCameraImages(timeFilter: string, date: string) {
      const { customer_name, site_id } = this.$route.params;
      this.loadingImage = true;
      let signal: AbortSignal;
      if (timeFilter === "after_sunrise") {
        if (this.beforeDateAbortController) {
          this.beforeDateAbortController.abort();
        }
        this.beforeDateAbortController = new AbortController();
        signal = this.beforeDateAbortController.signal;
      } else {
        if (this.afterDateAbortController) {
          this.afterDateAbortController.abort();
        }
        this.afterDateAbortController = new AbortController();
        signal = this.afterDateAbortController.signal;
      }

      try {
        const img: GalleryPaginatedImg = await CameraRepository.loadGalleryPaginatedImg(
          customer_name as string,
          site_id as string,
          this.camera as string,
          date as string,
          timeFilter as string,
          { signal: signal },
        );

        if (timeFilter === "after_sunrise") {
          if (!img.url) {
            this.beforeDateError = true;
            if (!this.isFirstLoad && !this.hideNoImageToast) {
              this.showToast(
                "error",
                this.$t("camera.compare.no_image") + " " + format(parseISO(date), "dd.MM.yyyy"),
              );
            }
          }
          this.beforeImg = img ? img : this.default_img;
        } else {
          if (!img.url) {
            this.afterDateError = true;
            if (!this.isFirstLoad && !this.hideNoImageToast) {
              this.showToast(
                "error",
                this.$t("camera.compare.no_image") + " " + format(parseISO(date), "dd.MM.yyyy"),
              );
            }
          }
          if (img) {
            this.afterImg = img;
            if (!this.initialAfterDate && !this.afterTime) {
              this.afterTime = format(parseISO(this.afterImg.timestamp), "yyyy-MM-dd"); // Use "MM" for months and "dd" for days
              const date = parseISO(this.afterImg.timestamp);
              const newDate = subDays(date, 7);
              this.beforeTime = format(newDate, "yyyy-MM-dd"); // Use "MM" for months and "dd" for days
              await this.getCameraImages("after_sunrise", this.beforeTime);
            }
          } else {
            this.afterImg = this.default_img;
          }
        }
      } catch (error) {
        if (error instanceof CanceledError) {
          return;
        }

        if (!axios.isAxiosError(error) || error.response?.status !== 404) {
          logger.error(error);
        }

        if (axios.isAxiosError(error)) {
          if (error?.response?.data?.code === "NO_DATA_YET") {
            this.$emit("noData");
          } else if (!this.hideNoImageToast) {
            this.showToast("warning", this.$t("err.no_data_found"));
          }
        }
        this.loadingImage = false;
      } finally {
        this.loadingImage = false;
        this.isFirstLoad = false;
      }
    },
    initImg() {
      const container = this.$refs.container as HTMLElement | null;
      const slider = document.querySelector(`.slider${this.camera}`) as HTMLInputElement | null;
      const beforeImage = this.$refs.beforeImage as HTMLImageElement;

      beforeImage.style.width = `${container?.clientWidth}px`;

      if (container && slider) {
        slider.addEventListener(
          "input",
          (e) => {
            const target = e.target as HTMLInputElement;
            container.style.setProperty("--position", `${target.value}%`);
            this.showOverlay = false;
          },
          { passive: true },
        ); // Mark the event listener as passive
      }
    },
    initSectionMaskCanvas() {
      const container = this.$refs.container as HTMLElement | null;

      if (!this.beforeSectionMaskCanvas) {
        this.beforeSectionMaskCanvas = new fabric.StaticCanvas(
          `section-mask-before-canvas-${this.sectionMaskCanvasId}`,
          {
            width: container?.clientWidth,
            height: container?.clientHeight,
          },
        );
      }
      if (!this.afterSectionMaskCanvas) {
        this.afterSectionMaskCanvas = new fabric.StaticCanvas(
          `section-mask-after-canvas-${this.sectionMaskCanvasId}`,
          {
            width: container?.clientWidth,
            height: container?.clientHeight,
          },
        );
      }
    },
    prevBeforeImg() {
      this.beforeDateError = false;
      if (this.beforeImg.previous) {
        try {
          this.getCameraImages("after_sunrise", this.beforeImg.previous);
        } finally {
          this.beforeTime = this.beforeImg.previous;
        }
      }
    },
    nextBeforeImg() {
      this.beforeDateError = false;
      if (this.beforeImg.next) {
        try {
          this.getCameraImages("after_sunrise", this.beforeImg.next);
        } finally {
          this.beforeTime = this.beforeImg.next;
        }
      }
    },
    prevAfterImg() {
      this.afterDateError = false;
      if (this.afterImg.previous) {
        try {
          this.getCameraImages("before_sunset", this.afterImg.previous);
        } finally {
          this.afterTime = this.afterImg.previous;
        }
      }
    },
    nextAfterImg() {
      this.afterDateError = false;
      if (this.afterImg.next) {
        try {
          this.getCameraImages("before_sunset", this.afterImg.next);
        } finally {
          this.afterTime = this.afterImg.next;
        }
      }
    },
    changeBeforeTime(value: string) {
      this.beforeDateError = false;
      this.getCameraImages("after_sunrise", value);
    },
    changeAfterTime(value: string) {
      this.afterDateError = false;
      this.getCameraImages("before_sunset", value);
    },

    updateSectionMasks() {
      if (!this.beforeSectionMaskCanvas || !this.afterSectionMaskCanvas || !this.sectionMasks) {
        return;
      }

      this.beforeSectionMaskCanvas.clear();
      this.afterSectionMaskCanvas.clear();
      this.sectionMasks?.forEach((mask) => {
        this.addSectionMaskToCanvas(mask);
      });

      this.beforeSectionMaskCanvas.renderAll();
      this.afterSectionMaskCanvas.renderAll();
    },
    isSectionMaskValidForDate(sectionMask: ModifiedSectionMask, date: string) {
      const maskDate = parse(date, "yyyy-MM-dd", new Date());
      const isAfterValidityStart = sectionMask.validity_start_local
        ? maskDate >= sectionMask.validity_start_local
        : true;
      const isBeforeValidityEnd = sectionMask.validity_end_local
        ? maskDate <= sectionMask.validity_end_local
        : true;

      return isAfterValidityStart && isBeforeValidityEnd;
    },
    addSectionMaskToCanvas(sectionMask: ModifiedSectionMask) {
      const container = this.$refs.container as HTMLElement;
      const width = container.clientWidth;
      const height = container.clientHeight;

      sectionMask.mask.forEach((mask) => {
        const maskPath = new fabric.Path(
          mask
            .map(([x, y], index) => `${index === 0 ? "M" : "L"} ${x * width} ${y * height}`)
            .join(" ") + " Z",
        );

        const opacity = 0.2;
        const fillColor = `${sectionMask.color.slice(0, -4)}${opacity})`;

        maskPath.set({
          fill: fillColor,
          stroke: sectionMask.color,
          strokeWidth: 2,
        });

        const coords = maskPath.getCenterPoint();
        const text = new fabric.Text(sectionMask.name, {
          fontSize: 14,
          strokeWidth: 4,
          stroke: "#000",
          fill: "#fff",
          fontFamily: "arial",
          fontWeight: "bold",
          paintFirst: "stroke",
        });
        text.set({
          left: coords.x - text.width / 2,
          top: coords.y - text.height / 2,
        });

        if (this.isSectionMaskValidForDate(sectionMask, this.afterTime)) {
          maskPath.clone().then((clone) => this.afterSectionMaskCanvas.add(clone));
          text.clone().then((clone) => this.afterSectionMaskCanvas.add(clone));
        }

        if (this.isSectionMaskValidForDate(sectionMask, this.beforeTime)) {
          this.beforeSectionMaskCanvas.add(maskPath);
          this.beforeSectionMaskCanvas.add(text);
        }
      });
    },
  },
  setup() {
    const customerName = useCurrentCustomerName();
    const siteId = useCurrentSiteId();

    return { customerName, siteId };
  },
});
</script>
<style scoped>
.container {
  display: grid;
  place-content: center;
  position: relative;
  overflow: hidden;
  --position: 50%;
}

.image-container {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 48rem) {
}

.slider-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
  background-color: grey;
}

.image-before {
  position: absolute;
  inset: 0;
  width: var(--position);
  /*filter: grayscale(100%)*/
}

.slider {
  position: absolute;
  inset: 0;
  cursor: pointer;
  opacity: 0;
  /* for Firefox */
  width: 100%;
  height: 100%;
}

.slider:focus-visible ~ .slider-button {
  outline: 5px solid black;
  outline-offset: 3px;
}

.slider-line {
  position: absolute;
  inset: 0;
  width: 0.2rem;
  height: 100%;
  background-color: #fff;
  left: var(--position);
  transform: translateX(-50%);
  pointer-events: none;
}

.slider-button {
  position: absolute;
  background-color: #fff;
  color: black;
  padding: 0.5rem;
  border-radius: 100vw;
  display: grid;
  place-items: center;
  top: 50%;
  left: var(--position);
  transform: translate(-50%, -50%);
  pointer-events: none;
  box-shadow: 1px 1px 1px hsl(0, 50%, 2%, 0.5);
}

.nav-arrows {
  @apply inline-flex items-center border-transparent px-0.5 py-2 text-sm font-medium sm:px-1;
}
</style>
