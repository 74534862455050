<template>
  <div
    class="oaiCriticalPathGraphNodeHoverItem"
    :class="isEntireRowHighlighted ? 'bg-gray-100 font-semibold' : ''"
  >
    <div class="truncate py-0.5 font-normal">
      {{ context.tagsByTypeAndSourceId[`component_${sourceId}`]?.name || "unknown" }}
    </div>
    <div
      class="py-0.5"
      :class="
        isPlannedEventUsedForNodeDifference &&
        !isActualEventUsedForNodeDifference &&
        !isEntireRowHighlighted
          ? 'bg-gray-200 w-min px-0.5 rounded font-semibold'
          : ''
      "
    >
      {{ formattedPlannedEvent }}
    </div>
    <div
      class="py-0.5"
      :class="
        isActualEventUsedForNodeDifference &&
        !isPlannedEventUsedForNodeDifference &&
        !isEntireRowHighlighted
          ? 'bg-gray-200 w-min px-0.5 rounded font-semibold'
          : ''
      "
    >
      {{ formattedActualEvent }}
    </div>
    <div :class="differenceClass">
      <span
        class="py-0.5"
        :class="
          isEntireRowHighlighted
            ? ['w-min rounded-full px-2 whitespace-nowrap', differenceBackgroundClass]
            : ''
        "
        >{{ deltaForPlannedAndActualEvent ?? "-" }}</span
      >
      <span class="text-xs font-normal py-0.5" v-if="isInDelayMessageVisible">
        &nbsp;({{ t("analytics.critical_path.in_delay") }})</span
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import {
  CriticalPathContext,
  CriticalPathDelta,
  CriticalPathNodeEx,
} from "shared/types/CriticalPath";
import { calculateDeltaForPlannedAndActualEvent } from "shared/views/critical_path/criticalPathDelta";
import { useDeltaTextClass, useDeltaTextWithBackgroundClass } from "../composables";
import "./criticalPath.css";

const dateFormat = "dd.MM.yyyy";

const props = defineProps<{
  criticalPathNode: CriticalPathNodeEx;
  context: CriticalPathContext;
  deltaForNode: CriticalPathDelta;
  sourceId: string;
}>();

const { t } = useI18n();

const plannedEvent = computed(() => props.context.plannedEventsBySourceId[props.sourceId] ?? null);

const actualEvent = computed(() => props.context.actualEventsBySourceId[props.sourceId] ?? null);

const formattedPlannedEvent = computed(() =>
  plannedEvent.value ? `${format(plannedEvent.value.end, dateFormat)}` : null,
);

const formattedActualEvent = computed(() => {
  if (!actualEvent.value) {
    return t("analytics.critical_path.not_started");
  }
  return `${
    actualEvent.value.end
      ? format(actualEvent.value.end, dateFormat)
      : t("analytics.critical_path.active")
  }`;
});

const deltaForPlannedAndActualEvent = computed(() =>
  calculateDeltaForPlannedAndActualEvent(
    plannedEvent.value,
    actualEvent.value,
    props.context.projectDurationSettings,
  ),
);

const isPlannedEventUsedForNodeDifference = computed(
  () =>
    props.deltaForNode.plannedEvent &&
    props.deltaForNode.plannedEvent._id ===
      props.context.plannedEventsBySourceId[props.sourceId]?._id,
);

const isActualEventUsedForNodeDifference = computed(
  () =>
    props.deltaForNode.actualEvent &&
    props.deltaForNode.actualEvent._id ===
      props.context.actualEventsBySourceId[props.sourceId]?._id,
);

const isDifferenceUsedForNodeDifference = computed(
  () =>
    deltaForPlannedAndActualEvent.value !== null &&
    deltaForPlannedAndActualEvent.value === props.deltaForNode.workingDays &&
    (!props.deltaForNode.actualEvent ||
      props.deltaForNode.actualEvent.source_id === props.sourceId),
);

const isEntireRowHighlighted = computed(
  () =>
    isPlannedEventUsedForNodeDifference.value &&
    (isActualEventUsedForNodeDifference.value || isDifferenceUsedForNodeDifference.value),
);

const isInDelayMessageVisible = computed(
  () =>
    !actualEvent.value?.end &&
    deltaForPlannedAndActualEvent.value !== null &&
    deltaForPlannedAndActualEvent.value !== 0,
);

const differenceClass = useDeltaTextClass(deltaForPlannedAndActualEvent);
const differenceBackgroundClass = useDeltaTextWithBackgroundClass(deltaForPlannedAndActualEvent);
</script>
