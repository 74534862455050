<template>
  <Sidebar>
    <CameraItemLayout :noData="noData">
      <template #contentLeft>
        <div class="loadingCard is-loading" v-if="!noData && loading">
          <div
            class="image group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-50 overflow-hidden"
          />
        </div>
        <div class="col-span-6" v-if="!noData && !loading">
          <ImagesComparison
            @noData="noData = true"
            :camera="cameraId"
            @beforeChanged="beforeDate = $event"
            @afterChanged="afterDate = $event"
            :sectionMasks="selectedSectionMasks"
            :initialBeforeDate="initialStartEndDates[0]"
            :initialAfterDate="initialStartEndDates[1]"
          />
        </div>
      </template>
      <template #contentRight v-if="levelTagOptions.length">
        <div class="col-span-4 lg:col-span-2" v-if="hasProcessesPermission">
          <div class="space-y-4 divide-y divide-gray-200">
            <div class="flex items-center">
              <h3 class="text-md 2xl:text-lg leading-6 font-medium text-gray-900">
                {{ t("camera.compare.construction_sections") }}
              </h3>
              <span
                style="
                  background: linear-gradient(90deg, #ff9d00 0%, #ff4000 100%);
                  font-size: 10px;
                "
                class="ml-2 px-1.5 rounded-full text-white font-medium"
              >
                {{ t("app_features.new_feature_badge") }}
              </span>
            </div>

            <div class="mt-1 pt-4 flex items-center">
              <OaiListbox
                :options="levelTagOptions"
                :placeholder="t('camera.compare.level')"
                v-model="selectedLevelTag"
                :minWidth="200"
              />
              <div class="ml-2">
                <XMarkIcon
                  @click="selectedLevelTag = undefined"
                  class="h-5 w-5 text-oaiGray-300 cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </CameraItemLayout>
  </Sidebar>
</template>

<script lang="ts" setup>
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { HierarchyTag } from "pct/src/types/HierarchyTag";
import { onMounted, ref, computed } from "vue";
import { markRaw } from "vue-demi";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import OaiListbox from "shared/components/other/OaiListbox.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useShortenedProcessesWithTags } from "shared/composables/process";
import {
  useCurrentCustomerName,
  useCurrentSiteId,
  useHasPermission,
} from "shared/composables/project";
import { useSectionMasks } from "shared/composables/sectionMasks";
import { useTrackEvent } from "shared/composables/tracking";
import Sidebar from "@/components/layout/Sidebar.vue";
import ImagesComparison from "@/components/other/ImagesComparison.vue";
import CameraItemLayout from "@/views/camera/components/CameraItemLayout.vue";

type HierarchyTagWithSplit = HierarchyTag & { level_split_id?: string };

const { params } = useRoute();
const cameraId = params?.camera_id as string;
const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();
const hasProcessesPermission = useHasPermission("processes_user");

const { t } = useI18n();

const beforeDate = ref("");
const afterDate = ref("");
const loading = ref(false);
const noData = ref(false);
const selectedLevelTag = ref<HierarchyTagWithSplit>();

const trackEvent = useTrackEvent();
const { sectionMasks } = useSectionMasks();
const { shortenedProcessesWithTags } = useShortenedProcessesWithTags();
const { hierarchyTags } = useHierarchyTags();

const levelTagOptions = computed(() => {
  if (!shortenedProcessesWithTags.value) {
    return [];
  }

  const cameraProcesses = shortenedProcessesWithTags.value.filter((process) => {
    return process.camera_id === cameraId;
  });
  const levelIds = new Set(cameraProcesses.map((process) => process.section_mask_mapping.level_id));

  return hierarchyTags.value
    .filter((tag) => tag.type === "level" && levelIds.has(tag._id))
    .flatMap((tag) => {
      if (tag.splits) {
        return tag.splits.map((split) => {
          return {
            value: markRaw({ ...tag, level_split_id: split.id }),
            name: `${tag.name} - ${split.name}`,
          };
        });
      }

      return {
        value: markRaw(tag),
        name: tag.name,
      };
    });
});

const initialStartEndDates = computed(() => {
  const filteredProcesses = shortenedProcessesWithTags.value?.filter((process) => {
    const isSameCamera = process.camera_id === cameraId;
    const isSameLevel = selectedLevelTag.value?._id === process.section_mask_mapping.level_id;

    return isSameCamera && isSameLevel;
  });

  if (!filteredProcesses || !filteredProcesses.length) {
    return [];
  }

  const sortedProcesses = filteredProcesses.sort((a, b) => a.date.localeCompare(b.date));

  return [sortedProcesses[0]?.date, sortedProcesses[sortedProcesses.length - 1]?.date];
});

const selectedSectionMasks = computed(() => {
  if (selectedLevelTag.value && sectionMasks.value) {
    trackEvent("camera_compare_level_selected");

    const masks = sectionMasks.value
      .filter((mask) => {
        const isSameCamera = mask.camera_id === cameraId;
        const isSameLevel = mask.level_split_id
          ? mask.level_split_id === selectedLevelTag.value?.level_split_id
          : mask.level_id === selectedLevelTag.value?._id;
        const isOverlayMask = !mask.section_id;

        return isSameCamera && isSameLevel && !isOverlayMask;
      })
      .map((mask) => {
        const section = hierarchyTags.value.find((tag) => tag._id === mask.section_id);
        const name = [selectedLevelTag.value?.name, section?.name].filter(Boolean).join(" - ");
        return { ...mask, name };
      });

    if (customerName === "flc" && siteId === "003") {
      return masks.slice(0, 1);
    }

    return masks;
  }

  return [];
});

onMounted(() => {
  trackEvent("camera_compare_view");
});
</script>
