<template>
  <div class="relative" :style="sizeStyles">
    <div class="text-center pl-6 pr-8 truncate" :style="{ height: `${headerHeight}px` }">
      <RouterLink
        :to="{
          name: 'Reports',
          query: {
            report: reportId,
          },
        }"
        class="text-lg font-semibold text-gray-900 hover:text-yellow-800"
        @click.prevent="emit('widgetRouteClicked')"
      >
        {{ title }}
      </RouterLink>
    </div>
    <ReportFilterBadges
      type="working_hour_curve"
      :filters="filters"
      :reportSummaryContext="reportSummaryContext"
      :height="filterBadgesHeight"
      class="ml-2"
    />
    <WorkingHourCurvePopover
      class="absolute top-1 right-4"
      v-if="!hidePopover"
      @reportInfoClick="emit('reportInfoClick')"
    />
    <div :style="{ height: `${chartHeight}px` }" class="overflow-hidden">
      <Chart :options="chartOptions" :key="props.config" />
    </div>
    <LoadingStateEmitter :isLoading="isLoading" />
  </div>
</template>

<script setup lang="ts">
import { isValid, format } from "date-fns";
import { Chart } from "highcharts-vue";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import LoadingStateEmitter from "shared/components/loading_state/LoadingStateEmitter.vue";
import { useIsWorkingDay, useNonWorkingDaysByDaySet } from "shared/composables/durations";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import { useShortenedProcessesWithTags } from "shared/composables/process";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useOutagesByRange } from "@/composables/outages";
import { useStreams } from "@/composables/stream";
import processDurationService from "@/services/processDurationService";
import { DashboardGridSize } from "@/types/Dashboard";
import {
  WorkingHourCurveReportConfig,
  WorkingHourCurveReportFilters,
  WorkingHourCurveSchedule,
  WorkingHourCurveAggregation,
} from "@/types/reports/PlotWorkingHourCurve";
import { useChartSize } from "@/views/reports/composables";
import ReportFilterBadges from "@/views/reports/filters/ReportFilterBadges.vue";
import { getStartEndDate, getStartEndDateFromProcesses } from "@/views/reports/filters/filters";
import WorkingHourCurvePopover from "@/views/reports/plots/working_hour_curve/WorkingHourCurvePopover.vue";
import {
  getHolidaysPlotBands,
  getWorkingHourCurveData,
  generateHolidayTooltip,
  generateOutagesTooltip,
  getWeekendPlotBands,
  generateDateTooltip,
  generatePercentageScoreTooltip,
} from "@/views/reports/plots/working_hour_curve/workingHourCurveService";

const defaultFullHeight = 400;

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    reportId: string;
    filters: WorkingHourCurveReportFilters;
    config: WorkingHourCurveReportConfig;
    aggregation: WorkingHourCurveAggregation;
    title: string;
    hidePopover?: boolean;
    width?: number;
    height?: number;
    spacingX?: number;
    spacingY?: number;
    dashboardGridSize?: DashboardGridSize;
  }>(),
  { height: defaultFullHeight, spacingX: 0, spacingY: 0 },
);

const emit = defineEmits(["widgetRouteClicked", "reportInfoClick"]);

const { headerHeight, chartHeight, filterBadgesHeight, sizeStyles } = useChartSize(
  computed(() => ({ ...props })),
  {
    minGridColumns: 2,
    minGridRows: 1,
  },
);

const reportSummaryContext = computed(() => {
  const interval = getStartEndDate(props.filters.daterange);
  const fallbackInterval = getStartEndDateFromProcesses(shortenedProcessesWithTags.value);
  return {
    start_date: interval.startDate || fallbackInterval.startDate,
    end_date: interval.endDate || fallbackInterval.endDate,
    previous_period_start_date: null,
    previous_period_end_date: null,
  };
});

const defaultChartOptions = computed(() => ({
  chart: {
    zoomType: "x",
    height: chartHeight.value,
  },
  title: {
    text: undefined,
  },
  legend: {
    enabled: false,
  },
  xAxis: {
    type: "datetime",
    dateTimeLabelFormats: {
      millisecond: "%B '%y",
      week: "%e. %B",
      month: "%B '%y",
    },
  },
  yAxis: {
    title: {
      text: t("analytics.reports.working_hours"),
    },
    min: 0,
  },
  tooltip: {
    shared: true,
    useHTML: true,
    outside: true,
    crosshairs: {
      color: "gray",
      dashStyle: "dash",
    },
    formatter: function () {
      const context = this as unknown as {
        x: number;
        point: {
          x: number;
          y: number;
          durations: Record<string, string>[];
        } & Record<string, Record<string, unknown>>;
        series: {
          points: { x: number; y: number }[];
        };
      };

      const point = context.point;
      const points = context.series.points;

      const tooltipContent = `
        <p class='text-sm mb-2 pr-1 border-b border-gray-200'>
          ${generateDateTooltip(point, points, props.aggregation, t)}
        </p>

        <p class="mb-2">
          <span style="color: ${point.color}">●</span>
          ${point?.series?.name}: 
          <span class="font-bold">${point.y}</span>
        </p>

        ${
          props.config.mode === "default"
            ? generatePercentageScoreTooltip(point, points, props.aggregation, t)
            : ""
        }

        <p>
          ${point.durations
            .map((duration) => {
              return `
              <p class="text-xs">
                <span>${duration.name}</span>
                <span class="text-gray-600 ml-2">
                  (${duration.duration} ${t("time.hour", duration.duration)})
                </span>
              </p>`;
            })
            .join("")}
        </p>


        ${generateHolidayTooltip(
          point,
          points,
          props.aggregation,
          projectDurationSettings.value?.non_working_days,
        )}
        ${generateOutagesTooltip(point, props.aggregation, outages, t)}
      `;

      return `<div class="p-1">${tooltipContent}</div>`;
    },
  },
}));

const { projectDurationSettings, isProjectDurationSettingsLoading } = useProjectDurationSettings();
const { streams, isLoading: areStreamsLoading } = useStreams();
const nonWorkingDaysByDaySet = useNonWorkingDaysByDaySet();
const isWorkingDay = useIsWorkingDay();
const { shortenedProcessesWithTags, isLoading: areShortenedProcessesLoading } =
  useShortenedProcessesWithTags();
const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();

const isLoading = computed(
  () =>
    isProjectDurationSettingsLoading.value ||
    areStreamsLoading.value ||
    areShortenedProcessesLoading.value ||
    areHierarchyTagsLoading.value ||
    areOutagesByRangeLoading.value,
);

const workingHourCurveData = computed(() =>
  getWorkingHourCurveData(
    shortenedProcessesWithTags.value,
    hierarchyTags.value,
    props.filters,
    props.aggregation,
    t,
  ),
);

const outagesInterval = computed(() => {
  if (!workingHourCurveData.value) {
    return null;
  }

  const days = Object.keys(workingHourCurveData.value).sort((a, b) => a.localeCompare(b));
  if (!days.length) {
    return null;
  }

  const start = new Date(Number(days[0]));
  const end = new Date(Number(days[days.length - 1]));

  if (!isValid(start) || !isValid(end)) {
    return null;
  }

  return { start, end };
});

const { outagesByRange, isLoading: areOutagesByRangeLoading } = useOutagesByRange(outagesInterval);

const outages = computed(() => {
  if (!nonWorkingDaysByDaySet.value) {
    return outagesByRange.value;
  }

  return processDurationService.useCorrectOutages(
    outagesByRange.value,
    streams.value,
    nonWorkingDaysByDaySet.value,
    isWorkingDay.value,
  );
});

const chartOptions = computed(() => {
  if (!workingHourCurveData.value) {
    return defaultChartOptions.value;
  }

  const isDefaultMode = props.config.mode === "default";
  const isMeanMode = isDefaultMode && props.config.show_mean;
  const isCumulativeMode = props.config.mode === "cumulative";

  const numbers = Object.values(workingHourCurveData.value)
    .map((value) => value.sum_working_hours)
    .filter(Boolean);
  const mean = Math.floor(numbers.reduce((acc, val) => acc + val, 0) / numbers.length);
  const scheduleEntries = Object.entries(workingHourCurveData.value);

  const values = scheduleEntries.reduce((acc, [time, value]) => {
    const date = format(Number(time), "yyyy-MM-dd");
    const isOutageDay =
      props.aggregation === "day" && !value.sum_working_hours && outages.value[date]?.length > 0;
    const prevHours = acc[acc.length - 1]?.y || 0;
    const cumulativeWorkingHours = Math.round(value.sum_working_hours + prevHours);

    acc.push({
      x: Number(time),
      y: isCumulativeMode ? cumulativeWorkingHours : value.sum_working_hours,
      durations: isDefaultMode ? value.durations : [],
      color: isOutageDay ? "#FCA5A5" : undefined,
    });

    return acc;
  }, [] as { x: number; y: number; durations: WorkingHourCurveSchedule[number]["durations"]; color?: string }[]);

  const plotBands = [
    ...getHolidaysPlotBands(projectDurationSettings),
    ...getWeekendPlotBands(projectDurationSettings, outages),
  ];

  return {
    ...defaultChartOptions.value,
    series: [
      {
        name: t("analytics.reports.working_hours"),
        data: values,
        color: "#3b82f6",
        tooltip: {
          headerFormat: "<span class='text-md font-bold'>{point.key}</span><br />",
        },
        ...(isMeanMode
          ? {
              negativeColor: "#6B7280",
              threshold: mean,
              type: "area",
            }
          : {}),
      },
    ],
    xAxis: {
      ...(defaultChartOptions.value.xAxis || {}),
      plotBands,
    },
    yAxis: {
      ...(defaultChartOptions.value.yAxis || {}),
      ...(isMeanMode
        ? {
            plotLines: [
              {
                value: mean,
                color: "#6B7280",
                width: 1,
                label: {
                  text: t("analytics.reports.mean") + mean,
                  x: -1,
                  style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                  },
                },
                zIndex: 2,
              },
            ],
          }
        : {}),
    },

    ...(isMeanMode
      ? {
          plotOptions: {
            area: {
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1,
                },
                stops: [
                  [0, "#60a5fa"],
                  [1, "#eff6ff"],
                ],
              },
              negativeFillColor: {
                linearGradient: { x1: 0, y1: 1, x2: 0, y2: 0 },
                stops: [
                  [0, "#9CA3AF90"],
                  [1, "#FFFFFF00"],
                ],
              },
            },
          },
        }
      : {}),
  };
});
</script>
