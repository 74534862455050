<template>
  <div class="flex-1 flex flex-col overflow-auto px-2 pt-6 pb-3">
    <div class="mb-4">
      <p class="text-md font-medium mb-2">
        {{ processes.length }}
        {{ t("analytics.processes.sidebar.processes_selected", processes.length) }}
      </p>

      <p
        v-if="processes.length"
        class="text-right plannedBg rounded text-gray-600 w-min px-1.5 py-0.5 text-sm whitespace-nowrap"
      >
        {{ startDate }} - {{ endDate }}
      </p>
    </div>
    <div>
      <div
        :class="[
          !isAnalysisAccordionOpen && 'border-b pb-3',
          'pt-3 flex justify-between cursor-pointer',
        ]"
        @click="toggleAnalysisAccordion"
      >
        <span class="indent-0.5">{{ t("analytics.processes.sidebar.analysis") }}</span>

        <ChevronLeftIcon
          :class="[
            'h-5 w-5 transition-[transform] duration-200',
            isAnalysisAccordionOpen ? 'transform -rotate-90' : '',
          ]"
        />
      </div>
      <div
        ref="analysisAccordionContent"
        class="overflow-hidden transition-[height] duration-200"
        v-if="toggleMediaAccordion"
      >
        <div class="pt-4 overflow-x-auto">
          <div v-if="processes.length">
            <div
              :class="[
                'bg-gray-50 text-xs font-medium text-gray-500 border-b border-gray-200 text-left grid  items-center gap-2 py-2 cursor-default',
                postAnalyzedData.at(-1)?.outageDays
                  ? 'grid-cols-[repeat(11,1fr),auto]'
                  : 'grid-cols-[repeat(9,1fr),auto]',
              ]"
            >
              <div class="col-span-2"></div>
              <div
                class="col-span-2 truncate"
                :title="t('analytics.processes.sidebar.active_total_days')"
              >
                {{ t("analytics.processes.sidebar.active_total_days") }}
              </div>
              <div
                class="col-span-2 truncate"
                :title="t('analytics.processes.sidebar.productive_days')"
              >
                {{ t("analytics.processes.sidebar.productive_days") }}
              </div>
              <div
                class="col-span-2 truncate"
                v-if="postAnalyzedData.at(-1)?.outageDays"
                :title="t('analytics.processes.sidebar.outage_days')"
              >
                {{ t("analytics.processes.sidebar.outage_days") }}
              </div>
              <div
                class="truncate col-span-3"
                :title="t('analytics.processes.sidebar.working_hours')"
              >
                {{ t("analytics.processes.sidebar.working_hours") }}
              </div>
            </div>

            <div class="text-xs">
              <template v-for="group in postAnalyzedData" :key="group.name">
                <div
                  :class="[
                    'border-b border-gray-200 cursor-pointer grid items-center gap-2 pr-1 hover:bg-gray-50',
                    postAnalyzedData.at(-1)?.outageDays
                      ? 'grid-cols-[repeat(11,1fr),auto]'
                      : 'grid-cols-[repeat(9,1fr),auto]',
                  ]"
                  @click="handleToggleProcessGroup(group.name)"
                >
                  <div class="relative flex col-span-2">
                    <div
                      class="w-1"
                      :style="`background: ${processElementColor[group.name]}`"
                    ></div>

                    <div
                      class="py-2 whitespace-nowrap truncate ml-2"
                      :title="group.name === ('total' as string)
                      ? t('analytics.processes.sidebar.total')
                      : t(`process_classes.${group.name}`)"
                    >
                      {{
                        group.name === ("total" as string)
                          ? t("analytics.processes.sidebar.total")
                          : t(`process_classes.${group.name}`)
                      }}
                    </div>
                  </div>
                  <div
                    class="py-2 whitespace-nowrap col-span-2 truncate"
                    :title="`${group.activeDays}/${group.totalDays} ${t('time.abbr_day')}`"
                  >
                    {{ group.activeDays }}/{{ group.totalDays }} {{ t("time.abbr_day") }}
                  </div>
                  <div
                    class="py-2 whitespace-nowrap col-span-2 truncate"
                    :title="`${group.productiveDays} ${t('time.abbr_day')}`"
                  >
                    {{ group.productiveDays }} {{ t("time.abbr_day") }}
                  </div>
                  <div
                    class="py-2 whitespace-nowrap col-span-2 truncate"
                    :title="
                      group.name === 'total' ? '' : `${group.outageDays} ${t('time.abbr_day')}`
                    "
                    v-if="postAnalyzedData.at(-1)?.outageDays"
                  >
                    {{
                      (group.name as string) === "total"
                        ? ""
                        : `${group.outageDays} ${t("time.abbr_day")}`
                    }}
                  </div>

                  <div
                    :class="[
                      'py-2 whitespace-nowrap col-span-2 truncate',
                      !hasWorkingHoursFeature && 'blur-sm',
                    ]"
                  >
                    {{ group.workingHours }} {{ t("time.abbr_hour") }}
                  </div>
                  <div class="py-2 justify-self-end nr-1 col-span-1">
                    <ChevronLeftIcon
                      v-if="group.name !== 'total'"
                      :class="[
                        'size-4 transition-[transform] duration-200 ',
                        toggledProcessGroup === group.name && group?.processesByGroup
                          ? 'transform -rotate-90'
                          : '',
                      ]"
                    />
                  </div>
                </div>
                <div
                  v-show="
                    toggledProcessGroup === group.name &&
                    group?.processesByGroup &&
                    group.name !== 'total'
                  "
                  ref="processGroupContent"
                  class="whitespace-nowrap bg-white z-50 max-h-96 overflow-y-auto border-b"
                >
                  <SelectProcesses
                    :processes="group?.processesByGroup"
                    @updateProcessList="handleSelectProcesses"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="{ 'border-b pb-3': isMediaAccordionOpen }">
      <div
        :class="[
          !isMediaAccordionOpen && 'border-b pb-3',
          'pt-3 flex justify-between cursor-pointer',
        ]"
        @click="toggleMediaAccordion"
      >
        <span class="indent-0.5">{{ t("analytics.processes.sidebar.before_after") }}</span>

        <ChevronLeftIcon
          :class="[
            'h-5 w-5 transition-[transform] duration-200',
            isMediaAccordionOpen ? 'transform -rotate-90' : '',
          ]"
        />
      </div>
      <div
        v-if="isMediaAccordionOpen"
        ref="mediaAccordionContent"
        class="overflow-hidden transition-[height] duration-200 h-0'"
      >
        <div class="pt-4">
          <div v-for="media in beforeAfterMedia" :key="media.cameraId" class="mb-4">
            <p class="text-sm indent-0.5">{{ media.cameraName }}</p>
            <ImagesComparison
              :initialBeforeDate="media.start"
              :initialAfterDate="media.end"
              :camera="media.cameraId"
            />
          </div>
        </div>
      </div>
    </div>
    <button
      class="flex items-center gap-1 mt-4 hover:underline"
      @click="
        openWorkingDaysCalendar = true;
        trackEvent('activity-calendar_view', { origin: 'process_selector' });
      "
      v-if="processes.length"
    >
      <CalendarDaysIcon class="w-4 text-yellow-600" />
      <span class="text-sm">{{ $t("analytics.processes.show_activity_calendar") }}</span>
    </button>
  </div>

  <XMarkIcon
    class="h-5 w-5 absolute cursor-pointer top-3 right-4 text-gray-600"
    @click="emit('close')"
  />
  <Modal
    :open="openWorkingDaysCalendar"
    @close="openWorkingDaysCalendar = false"
    custom-cls="overflow-hidden"
    v-if="openWorkingDaysCalendar"
    ><template #title>
      <div class="text-left border-b pb-3 text-ellipsis">
        {{ processes.length }}
        {{ t("analytics.processes.sidebar.processes_selected", processes.length) }}
      </div></template
    >
    <template #content v-if="processes">
      <WorkingDaysCalendar :processes="processes" />
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { XMarkIcon, ChevronLeftIcon } from "@heroicons/vue/24/outline";
import { CalendarDaysIcon } from "@heroicons/vue/24/solid";
import { format } from "date-fns";
import { PropType, computed, ref, watch } from "vue";
import { nextTick } from "vue";
import { useI18n } from "vue-i18n";
import Modal from "shared/components/modals/Modal.vue";
import { useIsWorkingDay, useNonWorkingDaysByDaySet } from "shared/composables/durations";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useTrackEvent } from "shared/composables/tracking";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ProcessElement } from "shared/types/ProcessClass";
import ImagesComparison from "@/components/other/ImagesComparison.vue";
import WorkingDaysCalendar from "@/components/working_days/WorkingDaysCalendar.vue";
import { useOutagesByRange } from "@/composables/outages";
import { useProcessClasses } from "@/composables/process";
import { useHasProjectFeature } from "@/composables/project";
import { useStreams } from "@/composables/stream";
import { processElementColor } from "@/constants/processClasses";
import processDurationService from "@/services/processDurationService";
import SelectProcesses from "@/views/process_gantt/components/SelectProcesses.vue";

type AnalyzedGroup = {
  name: ProcessElement | "total";
  activeDays: number;
  totalDays: number;
  productiveDays: number;
  outageDays: number;
  workingHours: number;
  processesByGroup: ShortenedProcessWithTags[];
};

type BeforeAfterMedia = {
  cameraId: string;
  cameraName: string;
  start: string;
  end: string;
};

const emit = defineEmits(["close", "selectedProcessUpdate"]);

const props = defineProps({
  processes: {
    type: Array as PropType<ShortenedProcessWithTags[]>,
    required: true,
  },
  start: {
    type: Date,
    required: true,
  },
  end: {
    type: Date,
    required: true,
  },
});

const { streams } = useStreams();
const { projectDurationSettings } = useProjectDurationSettings();
const nonWorkingDaysByDaySet = useNonWorkingDaysByDaySet();
const isWorkingDay = useIsWorkingDay();
const { outagesByRange } = useOutagesByRange(
  computed(() => ({
    start: props.start,
    end: props.end,
  })),
);
const hasWorkingHoursFeature = useHasProjectFeature("working_hours");
const { t } = useI18n();
const processClasses = useProcessClasses();
const trackEvent = useTrackEvent();

const openWorkingDaysCalendar = ref(false);

const outages = computed(() => {
  if (!nonWorkingDaysByDaySet.value) {
    return outagesByRange.value;
  }

  return processDurationService.useCorrectOutages(
    outagesByRange.value,
    streams.value,
    nonWorkingDaysByDaySet.value,
    isWorkingDay.value,
  );
});

const startDate = ref("");
const endDate = ref("");
const postAnalyzedData = ref<AnalyzedGroup[]>([]);
const beforeAfterMedia = ref<BeforeAfterMedia[]>([]);
const isAnalysisAccordionOpen = ref(true);
const isMediaAccordionOpen = ref(false);
const analysisAccordionContent = ref<HTMLDivElement | null>(null);
const mediaAccordionContent = ref<HTMLDivElement | null>(null);

const toggleAnalysisAccordion = () => {
  isAnalysisAccordionOpen.value = !isAnalysisAccordionOpen.value;

  if (isAnalysisAccordionOpen.value) {
    const height = analysisAccordionContent.value?.children?.[0]?.scrollHeight;
    analysisAccordionContent.value?.style.setProperty("height", `${height}px`);
  } else {
    analysisAccordionContent.value?.style.setProperty("height", "0px");
  }
};

const toggleMediaAccordion = () => {
  isMediaAccordionOpen.value = !isMediaAccordionOpen.value;

  if (isMediaAccordionOpen.value) {
    const height = mediaAccordionContent.value?.children?.[0]?.scrollHeight;
    mediaAccordionContent.value?.style.setProperty("height", `${height}px`);
  } else {
    mediaAccordionContent.value?.style.setProperty("height", "0px");
  }
};

const setAccordionHeight = () => {
  if (isAnalysisAccordionOpen.value) {
    const height = analysisAccordionContent.value?.children?.[0]?.scrollHeight;
    analysisAccordionContent.value?.style.setProperty("height", `${height}px`);
  }

  if (isMediaAccordionOpen.value) {
    const height = mediaAccordionContent.value?.children?.[0]?.scrollHeight;
    mediaAccordionContent.value?.style.setProperty("height", `${height}px`);
  }
};

const calculateProcessesAnalysis = (processes: ShortenedProcessWithTags[]) => {
  nextTick(setAccordionHeight);

  if (!processes.length || !projectDurationSettings.value) {
    return;
  }
  const sortedProcesses = processes
    .slice()
    .sort((a: ShortenedProcessWithTags, b: ShortenedProcessWithTags) => {
      const processElementA = t(`process_classes.${a.encoded_label}`);
      const processElementB = t(`process_classes.${b.encoded_label}`);

      const labelComparison = processElementA.localeCompare(processElementB);
      if (labelComparison !== 0) {
        return labelComparison;
      }
      return new Date(a.end_time).getTime() - new Date(b.end_time).getTime();
    });

  postAnalyzedData.value =
    processDurationService.calculateProcessesAnalysis<ShortenedProcessWithTags>(
      sortedProcesses,
      processClasses.value,
      projectDurationSettings.value,
      outages.value,
      { hasWorkingHoursFeature },
    );

  const start = sortedProcesses[0].start_time;
  const end = sortedProcesses.at(-1)?.end_time as Date;

  startDate.value = format(start, "dd.MM.yyyy");
  endDate.value = format(end, "dd.MM.yyyy");

  const firstAndLastProcessHaveSameCamera = processes[0].camera_id === processes.at(-1)?.camera_id;

  const firstCameraId = processes[0].camera_id;
  const firstCameraName = streams.value.find((stream) => stream.camera_id === firstCameraId)?.name;
  if (firstAndLastProcessHaveSameCamera) {
    beforeAfterMedia.value = [
      {
        cameraId: firstCameraId,
        cameraName: firstCameraName || "",
        start: format(start, "yyyy-MM-dd"),
        end: format(end, "yyyy-MM-dd"),
      },
    ];
  } else {
    const lastCameraId = processes.at(-1)?.camera_id;
    const lastCameraName = streams.value.find((stream) => stream.camera_id === lastCameraId)?.name;
    const lastProcessWithFirstCamera = processes
      .slice()
      .reverse()
      .find((process) => process.camera_id === firstCameraId);
    const firstProcessWithLastCamera = processes.find(
      (process) => process.camera_id === lastCameraId,
    );

    beforeAfterMedia.value = [
      {
        cameraId: firstCameraId,
        cameraName: firstCameraName || "",
        start: format(start, "yyyy-MM-dd"),
        end: format(lastProcessWithFirstCamera?.end_time || start, "yyyy-MM-dd"),
      },
      {
        cameraId: lastCameraId as string,
        cameraName: lastCameraName || "",
        start: format(firstProcessWithLastCamera?.start_time || end, "yyyy-MM-dd"),
        end: format(end, "yyyy-MM-dd"),
      },
    ];
  }
};

const toggledProcessGroup = ref("");
const groupContentHeight = ref<number>(0);
const processGroupContent = ref<HTMLElement | null>(null);

const handleToggleProcessGroup = (groupName: string) => {
  if (toggledProcessGroup.value === groupName) {
    toggledProcessGroup.value = "";
    groupContentHeight.value = 0;
  } else {
    toggledProcessGroup.value = groupName;
    nextTick(() => {
      if (processGroupContent.value) {
        groupContentHeight.value = processGroupContent.value.clientHeight || 0;
      }
    });
  }
  nextTick(() => {
    const height = analysisAccordionContent.value?.children?.[0]?.scrollHeight;
    analysisAccordionContent.value?.style.setProperty("height", `${height}px`);
  });
};

const handleSelectProcesses = (processId: string) => {
  emit("selectedProcessUpdate", processId);
};

watch(
  () => props.processes,
  () => calculateProcessesAnalysis(props.processes),
  { immediate: true },
);
</script>
