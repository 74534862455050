import {
  QueryObserverResult,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/vue-query";
import { computed, Ref } from "vue";
import { useStore } from "vuex";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import {
  Project,
  ProjectFeature,
  ProjectThumbnailUrl,
  ProjectExtendedData,
  ProjectWeather,
  ProjectThumbnailAll,
  ProjectStatus,
} from "shared/types/Project";
import ProjectRepository, { ProjectToUpdateAsProjectAdmin } from "@/repositories/ProjectRepository";

export const useCurrentProject = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const store = useStore();

  const projects = store.state.projects as Project[] | undefined;
  return (projects &&
    projects.find(
      (project) => project.customer_name === customerName && project.site_id === siteId,
    )) as Project;
};

export const useHasProjectFeature = (feature: ProjectFeature) => {
  const project = useCurrentProject();
  if (!project) {
    return false;
  }
  return project.features.some((item) => item === feature);
};

export const useLoadCurrentProject = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const { data: project, isLoading: isProjectLoading } = useQuery<Project | null>({
    queryKey: ["project", currentCustomerName, currentSiteId],
    queryFn: () => ProjectRepository.loadProject(currentCustomerName, currentSiteId),
  });

  return { project, isProjectLoading };
};

export const useUpdateCurrentProject = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();

  const {
    mutateAsync: updateProject,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useMutation<Project, Error, ProjectToUpdateAsProjectAdmin>({
    mutationFn: (projectToUpdate) =>
      ProjectRepository.updateProjectAsProjectAdmin(
        currentCustomerName,
        currentSiteId,
        projectToUpdate,
      ),
    onSuccess: (project) => {
      queryClient.setQueryData(["project", currentCustomerName, currentSiteId], project);
    },
  });

  return { updateProject, isUpdateLoading, isUpdateError };
};

export const useLogoUrl = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const {
    data,
    isLoading: isLogoUrlLoading,
    isFetching: isLogoUrlFetching,
  } = useQuery<string | null>({
    queryKey: ["project-logo-url", currentCustomerName, currentSiteId],
    queryFn: () => ProjectRepository.loadLogoUrl(currentCustomerName, currentSiteId),
    staleTime: 2 * 60 * 60 * 1000,
    refetchOnMount: true,
  });

  const logoUrl = computed(() => data.value ?? null);

  return { logoUrl, isLogoUrlLoading, isLogoUrlFetching };
};

export const useDeleteLogo = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const {
    mutate: deleteLogo,
    isLoading: isLogoDeleting,
    isError: isDeleteError,
  } = useMutation<void, Error>({
    mutationFn: () => ProjectRepository.deleteLogo(currentCustomerName, currentSiteId),
    onSuccess: () => {
      queryClient.setQueryData(["project-logo-url", currentCustomerName, currentSiteId], null);
    },
  });

  return { deleteLogo, isLogoDeleting, isDeleteError };
};

export const useUploadLogo = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const {
    mutate: uploadLogo,
    isLoading: isLogoUploading,
    isError: isLogoUploadError,
  } = useMutation<string, Error, string>({
    mutationFn: (fileString) =>
      ProjectRepository.uploadLogo(currentCustomerName, currentSiteId, fileString),
    onSuccess: (logoUrl) => {
      queryClient.setQueryData(["project-logo-url", currentCustomerName, currentSiteId], logoUrl);
    },
  });
  return { uploadLogo, isLogoUploading, isLogoUploadError };
};

export const useThumbnailUrls = (
  page: Ref<number>,
  pageSize: Ref<number>,
  status: Ref<ProjectStatus | null>,
) => {
  const queryResults = useQueries<ProjectThumbnailUrl[]>({
    queries: computed(() =>
      Array.from({ length: page.value }, (_, i) => i + 1).map((pageItem) => ({
        queryKey: ["project-thumbnail-urls-for-page", pageItem, pageSize, status],
        queryFn: () => ProjectRepository.loadThumbnailUrls(pageItem, pageSize.value, status.value),
      })),
    ),
  }) as QueryObserverResult<ProjectThumbnailUrl[]>[];

  const projectThumbnailUrls = computed(() => queryResults.flatMap((result) => result.data || []));

  return { projectThumbnailUrls };
};

export const useThumbnailUrlsAll = (
  page: Ref<number>,
  pageSize: Ref<number>,
  status: Ref<ProjectStatus | null>,
) => {
  const queryResults = useQueries<ProjectThumbnailAll[]>({
    queries: computed(() =>
      Array.from({ length: page.value }, (_, i) => i + 1).map((pageItem) => ({
        queryKey: ["project-thumbnail-urls-all-for-page", pageItem, pageSize, status],
        queryFn: () =>
          ProjectRepository.loadThumbnailUrlsAll(pageItem, pageSize.value, status.value),
      })),
    ),
  }) as QueryObserverResult<ProjectThumbnailAll[]>[];

  const projectThumbnailUrlsAll = computed(() =>
    queryResults.flatMap((result) => result.data || []),
  );

  return { projectThumbnailUrlsAll };
};

export const useWeatherForProjects = () => {
  const { data, isLoading } = useQuery<ProjectWeather[]>({
    queryKey: ["weather-for-projects"],
    queryFn: () => ProjectRepository.loadWeatherForProjects(),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
    staleTime: 5 * 60 * 1000,
  });

  const weatherForProjects = computed(() => data.value || []);

  return { weatherForProjects, isLoading };
};

export const useExtendedDataForProjects = () => {
  const { data, isLoading } = useQuery<ProjectExtendedData[]>({
    queryKey: ["extended-data-for-projects"],
    queryFn: () => ProjectRepository.loadExtendedDataForProjects(),
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    refetchOnMount: true,
    staleTime: 5 * 60 * 1000,
  });

  const extendedDataForProjects = computed(() => data.value || []);

  return { extendedDataForProjects, isLoading };
};
